export const isPwa = () => {
  return (
    window.navigator.standalone ||
    window.navigator.fullscreen ||
    window.matchMedia('(display-mode: standalone)').matches ||
    window.matchMedia('(display-mode: fullscreen)').matches
  );
};

export const getDevice = () => {
  const userAgent = window.navigator.userAgent;
  return userAgent.indexOf('iPhone') !== -1
    ? 'iphone'
    : userAgent.indexOf('iPad') !== -1
    ? 'ipad'
    : userAgent.indexOf('Mac') !== -1
    ? 'mac'
    : userAgent.indexOf('Android') !== -1
    ? 'android'
    : userAgent.indexOf('Windows') !== -1
    ? 'window'
    : 'unknown';
};
