import { Link } from 'preact-router/match';

export const GLRVC1 = () => ({
  url: '/product/tomato-vit-c',
  title: 'โทเมโท่วิตซี',
  brand: 'GLORY',
  sku: 'GLRVC1',
  totalSale: 110852,
  price: 390,
  offerPrice: 450,
  images: ['/product/GLRVC1.jpg'],
  product: 'โทเมโท่วิตซี 1 ซอง',
  shortDescription: 'วิตามินซีเข้มข้น ลดสิว ผิวใส',
  description: (
    <div class="mb-2 text-baseColor">
      <p>
        Tomato Vit C วิตามินซีเข้มข้นเทียบเท่ากับมะเขือเทศ 34 ลูก ช่วยลดสิว
        บำรุงผิวใส
      </p>
      <p class="mt-2">
        และเพื่อผลลัพธ์ที่ดีขึ้น ควรทานเป็นประจำอย่างต่อเนื่อง
        ควบคู่กับผลิตภัณฑ์{' '}
        <Link
          href="/product/collagen"
          class="font-bold text-primary hover:text-primaryLight"
        >
          Collagen
        </Link>
        {', '}
        <Link
          href="/product/scrub"
          class="font-bold text-primary hover:text-primaryLight"
        >
          Scrub
        </Link>
        {', '}และ{' '}
        <Link
          href="/product/body-serum"
          class="font-bold text-primary hover:text-primaryLight"
        >
          Body Serum
        </Link>
      </p>
      <ul class="list-disc mt-4 ml-10 text-baseColor">
        <li>วิตามินซีเข้มข้น 600 มก / แคปซูล</li>
        <li>ลดสิวอักเสบ สิวอุดตัน</li>
        <li>ลดจุดด่างดำ รอยดำ รอยแดง</li>
        <li>ผิวกระจ่างใส อมชมพู</li>
        <li>ปกป้องผิวแสงแดด</li>
        <li>เสริมภูมิคุ้มกัน</li>
      </ul>
    </div>
  ),
  options: [
    {
      sku: 'GLRVC1',
      title: (
        <div>
          1 ซอง <del>฿450</del>
        </div>
      ),
      price: 390,
    },
    {
      sku: 'GLRVC2',
      title: (
        <div>
          2 ซอง <del>฿900</del>
        </div>
      ),
      price: 760,
    },
    {
      sku: 'GLRVC3',
      title: (
        <div>
          3 ซอง <del>฿1,350</del>
        </div>
      ),
      price: 1120,
    },
  ],
  guarantee: {
    title: 'คุณภาพจากกลอรี่',
    description: (
      <div>
        เพราะความสวยสร้างได้
        กลอรี่ผลิตภัณฑ์คุณภาพที่ออกแบบมาเพื่อให้คุณมีผิวสวยใส ไร้สิว
        จากภายในสู่ภายนอก
      </div>
    ),
  },
  ingredients: [
    {
      image: '/ingredient/tomato.png',
      title: 'Tomato Extract (สารสกัดมะเขือเทศเข้มข้นจากสวิสเซอร์แลนด์)',
      description: (
        <div>
          ช่วยบํารุงผิวพรรณให้ชุ่มชื้น กระจ่างใสอมชมพู
          ช่วยให้ผิวทนต่อการถูกทําลายของแสงแดด ลดการเกิดสิว ต้านอนุมูลอิสระ
          ชะลอความชรา
        </div>
      ),
    },
    {
      image: '/ingredient/acerola.png',
      title: 'Acerola Cherry Extract (สารสกัดอะเซโรล่าเชอรี่)',
      description: (
        <div>
          อุดมไปด้วยวิตามินซี (Vitamin C) สูงที่สุดเมื่อเทียบกับผลไม้ชนิดอื่น
          ช่วยต้านอนุมูลอิสระ (Antioxidant) สาเหตุสำคัญที่ทำให้ผิวหมองคล้ำ
          ร่วงโรยกว่าวัย ช่วยให้ผิวคแข็งแรง กระจ่างใส เนียนเรียบ อมชมพู
          พร้อมช่วยลดโอกาสการเกิด กระ จุดด่างดำบนใบหน้า
        </div>
      ),
    },
    {
      image: '/ingredient/vc-3.png',
      title: 'Ascorbic Acid Powder (Vitamin C)',
      description: (
        <div>
          ช่วยกระตุ้นการผลิตคอลลาเจน ช่วยต่อต้านอนุมูลอิสระ ช่วยลดสิว ลดรอยแดง
          และบำรุงผิวใส
        </div>
      ),
    },
  ],
  ingredientSummary: (
    <div>
      <ul>
        <li>Acerola Cherry Extract Powder 500 มก.</li>
        <li>Ascorbic Acid Powder (Vitamin C) 35 มก.</li>
        <li>Tomato Extract 30 มก.</li>
        <li>Orange Capsule No.O 96 มก.</li>
      </ul>
    </div>
  ),
  fdaId: '10-1-20960-5-0112',
  weight: '33 กรัม. 60 แคปซูล',
  faqs: [
    {
      question: 'โทเมโท่วิตซี 1 ซองมีกี่แคปซูล',
      answer: <div>1 ซองมี 60 แคปซูล ทานได้ 30 วัน</div>,
    },
    {
      question: 'วิธีทานวิตามินซีให้เห็นผล',
      answer: (
        <div>
          <div>
            ทานติดต่อกันทุกวัน วันละ 2 แคปซูล หลังตื่นนอนหรือก่อนอาหาร 30 นาที
          </div>
          <div class="mt-2">
            และเพื่อผลลัพธ์ที่ดีขึ้น ควรทานโทเมโท่วิตซีคู่กับกลอรี่คอลลาเจน
          </div>
        </div>
      ),
    },
    {
      question: 'กลอรี่ปลอดภัยไหม มีอย.หรือไม่',
      answer: (
        <div>
          <div>
            สินค้าทุกชิ้นของกลอรี่ ผลิตโดยโรงงานมาตรฐาน ปลอดภัย และมีอย.
          </div>
          <div class="mt-2">
            เลขที่อย.: 10-1-20960-5-0093 (คอลลาเจน)
            <br />
            เลขที่อย.: 10-1-20960-5-0112 (โทเมโท่วิตซี)
            <br />
            เลขที่อย.: 10-1-63000-3-2598 (สครับ)
            <br />
            เลขที่อย.: 11-1-64000-0-2539 (บอดี้เซรั่ม)
            <br />
          </div>
        </div>
      ),
    },
    {
      question: 'ช่วยเรื่องอะไรบ้าง',
      answer: (
        <div>
          <div>โทเมโท่วิตซีช่วยเรื่องสิว ลดรอยแดง ช่วยบำรุงผิวให้กระจ่างใส</div>
        </div>
      ),
    },
    {
      question: 'ทำไมต้องทานคอลลาเจนคู่กับวิตามินซี',
      answer: (
        <div>
          เพราะวิตามินซีช่วยให้คอลลาเจนดูดซึมในร่างกายได้ดีขึ้น
          ดังนั้นควรทานกลอรี่คอลลาเจน คู่กับโทเมโท่วิตซี
        </div>
      ),
    },
    {
      question: 'มะเขือเทศวิตซีทานตอนท้องว่างได้ไหม',
      answer: (
        <div>
          ตัววิตามินซีจะมีความเป็นกรดอ่อนๆ เท่านั้น
          หากทานแล้วไม่ระคายเคืองกระเพาะอาหารก็สามารถทานได้
        </div>
      ),
    },
    {
      question: 'ต้องทานติดต่อกันกี่วัน',
      answer: (
        <div>
          แนะนำให้ทานกลอรี่คอลลาเจนคู่กับโทเมโท่วิตซีเป็นประจำทุกวัน
          หรืออย่างน้อยติดต่อกัน 2-3 เดือนขึ้นไป ถึงจะเห็นผลลัพธ์ชัดเจน
        </div>
      ),
    },
    {
      question: 'อายุ 10-13 ปี ทานได้ไหม',
      answer: (
        <div>
          ทานได้ และแนะนำให้ทาน
          เพราะวิตามินซีเป็นวิตามินที่ร่างกายจำเป็นต้องได้รับทุกวัน
          ช่วยให้ผิวขาวใส อมชมพู และลดสิว
        </div>
      ),
    },
    {
      question: 'จุดสีดำที่พบในผงวิตามินซีคืออะไร',
      answer: (
        <div>
          เป็นสาร "ไลโคปีน" จากมะเขือเทศ ที่ผ่านวิธีการสกัดให้มีความเข้มข้น
        </div>
      ),
    },
    {
      question: '1 แคปซูล มีกี่แคลลอรี่',
      answer: <div>Tomato Vit C 1 แคปซูล = 2 แคลลอรี่</div>,
    },
  ],
  videoContent: {
    title: 'รีวิวอาหารผิวกู้ผิวใสเร่งด่วน',
    subtitle:
      'กลอรี่คอลลาเจนและโทเมโท่วิตซี ช่วยเรื่องสิว กู้หน้าพัง ให้กลับมาใสอีกครั้ง',
    url: 'https://www.youtube.com/watch?v=ccpOhC2xwwE',
  },
  disclaimer: (
    <div>
      1. ใช้สำหรับภายนอกเท่านั้น
      <br />
      2. หากเกิดการระคายเคือง ควรหยุดใช้และปรึกษาแพทย์หรือเภสัชกร
    </div>
  ),
  reviews: [
    {
      image: '/glorycollagen/rv-twitter-7.jpg',
      review: (
        <div>
          ใครไม่ชอบกินน้ำมะเขือเทศดอยคำ แต่อยากผิวขาวหน้าใส ลอง Vit C ของ Glory
          ดูค่า เป็นมะเขือเทศอัดเม็ด 1 แคปซูล
          ส่วนตัวจูนทานมาได้สักพักผิวมันใสขึ้น แต่มันยังไม่ถึงกับขาวนะ
          อาจต้องกินต่ออีก แต่หน้าดูขาวใสขึ้นเร็วจริง และสิวหรือรอยแดงจางลงด้วย
        </div>
      ),
      name: 'คุณจูน',
      product: 'GLCVC1',
    },
    {
      image: '/glorycollagen/rv-twitter-8.jpg',
      review: (
        <div>
          ชอบกินวิตามินซีกับน้ำมะเขือเทศคู่กัน 1 เม็ด เหมีอนได้กินมะเขือเทศ 34
          ผลเลย ช่วงที่รักษาสิวอาทิตย์เดียวยิ่งเห็นชัดเลยว่า
          รอยสิวหายไวมากกจนแฟนทัก ผิวหายหมอง แก้มใสๆ ขึ้น
        </div>
      ),
      name: 'คุณโอป',
      product: 'GLCVC1',
    },
    {
      image: '/glorycollagen/rv-twitter-10.jpg',
      review: (
        <div>
          เพิ่งมาสังเกตตอนที่เพื่อนทักว่าผิวดูใส ขาวขึ้น
          นี่ไม่ได้คาดหวังอะไรมากตอนที่กิน Vit C กับคอลลาเจนคู่นี้
          แต่ผลที่ได้หลังกินต่อเนื่องก็คือ ผิวดีขึ้นจริง นี่ชอบ Vit C
          ส่วนผสมจัดเต็ม เหมาะกับคนที่อยากผิวใสแต่กินดอยคำไม่ไหว
          เห็นว่าได้เรื่องสิวต่างๆ ด้วย ครบมาก!
        </div>
      ),
      name: 'คุณไหม',
      product: 'GLCVC1',
    },
    {
      image: '/glorycollagen/rv-twitter-11.jpg',
      review: (
        <div>
          เป็นคนผิวแทนที่กินกลูต้ากับคอลลาเจนตลอด ไม่ได้ซีเรื่องความขาว
          แต่เวลากินพวกนี้แล้วผิวจะใสมาก ถ่ายรูปออกมาก็ดูผิวเงาสุขภาพดีทั้งๆ
          ที่ไม่ได้ทาครีมหรืออะไรเลย ตอนนี้กินคอลลาเจนกับมะเขือเทศอัดเม็ดของ
          Glory คู่กันอยู่ ผิวเนียนใสมาก
        </div>
      ),
      name: 'คุณเมล่อน',
      product: 'GLCVC1',
    },
  ],
  productsIncluded: null,
  relateProducts: [
    'ESTHC1',
    'GLRBO1',
    'GLRSET1',
    'GLRSET6',
    'GLRSR1',
    'GLRCL1',
    'GLRSC1',
    'GLRBS1',
  ],
  upsellProducts: ['GLRSC1', 'GLRBS1'],
});
