import { Link } from 'preact-router/match';

export const GLRSET1 = () => {
  return {
    url: '/product/set-1',
    title: 'ชุดบูสผิว',
    brand: 'GLORY',
    sku: 'GLRSET1',
    totalSale: 78902,
    price: 790,
    offerPrice: 1040,
    images: ['/product/GLRCL1VC1.jpg'],
    product: 'ชุดบูสผิว: Collagen 1 + Vit C 1',
    shortDescription: 'คู่หูผิวใส คอลลาเจน และวิตามินซีจากมะเขือเทศ',
    description: (
      <div class="mb-2 text-baseColor">
        <p>
          ชุดบูสผิว เหมาะสำหรับคนที่ต้องการลดสิว รอยแดง รอยดำ ทานได้ 1 เดือน
        </p>
        <p class="mt-2">ในชุดนี้ประกอบด้วย</p>
        <ul class="list-disc my-2 ml-10 text-baseColor">
          <li>Glory Collagen 1 ซอง</li>
          <li>Glory Tomato Vit C 1 ซอง</li>
        </ul>
      </div>
    ),
    options: null,
    guarantee: {
      title: 'คุณภาพจากกลอรี่',
      description: (
        <div>
          เพราะความสวยสร้างได้
          กลอรี่ผลิตภัณฑ์คุณภาพที่ออกแบบมาเพื่อให้คุณมีผิวสวยใส ไร้สิว
          จากภายในสู่ภายนอก
        </div>
      ),
    },
    ingredients: null,
    fdaId: null,
    weight: null,
    faqs: [
      {
        question: 'วิธีทานให้เห็นผล',
        answer: (
          <div class="grid md:grid-cols-2">
            <div class="mb-4">
              <span class="font-bold">ตอนเช้า</span>
              <ul class="list-decimal mt-2 ml-6">
                <li>ทานคอลลาเจน 1 แคปซูลหลังตื่นนอน</li>
                <li>ทานโทเมโท่วิตซี 1 แคปหลังอาหารเช้า</li>
              </ul>
            </div>
            <div>
              <span class="font-bold">ตอนกลางคืน</span>
              <ul class="list-decimal mt-2 ml-6">
                <li>ทานคอลลาเจน 1 แคปก่อนนอน</li>
                <li>ทานโทเมโท่วิตซี 1 แคปก่อนนอน</li>
              </ul>
            </div>
          </div>
        ),
      },
      {
        question: '1 ซองมีกี่แคปซูล',
        answer: (
          <div>
            Glory Collagen และ Tomato Vit C ใน 1 ซองมี 60 แคปซูล ทานได้ 30 วัน
          </div>
        ),
      },
      {
        question: 'กลอรี่ปลอดภัยไหม มีอย.หรือไม่',
        answer: (
          <div>
            <div>
              สินค้าทุกชิ้นของกลอรี่ ผลิตโดยโรงงานมาตรฐาน ปลอดภัย และมีอย.
            </div>
            <div class="mt-2">
              เลขที่อย.: 10-1-20960-5-0093 (คอลลาเจน)
              <br />
              เลขที่อย.: 10-1-20960-5-0112 (โทเมโท่วิตซี)
              <br />
              เลขที่อย.: 10-1-63000-3-2598 (สครับ)
              <br />
              เลขที่อย.: 11-1-64000-0-2539 (บอดี้เซรั่ม)
              <br />
            </div>
          </div>
        ),
      },
      {
        question: 'คนแพ้อาหารทะเลทานได้ไหม',
        answer: (
          <div>
            <div>ถ้าแพ้อาหารทะเลแต่ไม่ได้แพ้ปลาทะเล สามารถทานได้</div>
          </div>
        ),
      },
      {
        question: 'กลอรี่ช่วยเรื่องอะไรบ้าง',
        answer: (
          <div>
            <div>
              กลอรี่คอลลาเจนช่วยเรื่องสิว รอยแดง ช่วยให้ผิวใส และนุ่มลื่นขึ้น
            </div>
          </div>
        ),
      },
      {
        question: 'ทำไมต้องทานคอลลาเจนคู่กับวิตามินซี',
        answer: (
          <div>
            เพราะวิตามินซีช่วยให้คอลลาเจนดูดซึมในร่างกายได้ดีขึ้น
            ดังนั้นควรทานกลอรี่คอลลาเจน คู่กับโทเมโท่วิตซี
          </div>
        ),
      },
      {
        question: 'ต้องทานติดต่อกันกี่วัน',
        answer: (
          <div>
            แนะนำให้ทานกลอรี่คอลลาเจนคู่กับโทเมโท่วิตซีเป็นประจำทุกวัน
            หรืออย่างน้อยติดต่อกัน 2-3 เดือนขึ้นไป ถึงจะเห็นผลลัพธ์ชัดเจน
          </div>
        ),
      },
      {
        question: 'อายุ 10-13 ปี ทานได้ไหม',
        answer: (
          <div>
            ทานได้ และแนะนำให้ทาน เพราะวิตามินซีใน Tomato Vit
            Cเป็นวิตามินที่ร่างกายจำเป็นต้องได้รับทุกวัน ช่วยให้ผิวขาวใส อมชมพู
            และลดสิว
          </div>
        ),
      },
      {
        question: 'จุดสีดำที่พบในผงวิตามินซีคืออะไร',
        answer: (
          <div>
            เป็นสาร "ไลโคปีน" จากมะเขือเทศ ที่ผ่านวิธีการสกัดให้มีความเข้มข้น
          </div>
        ),
      },
      {
        question: '1 แคปซูล มีกี่แคลลอรี่',
        answer: (
          <div>
            Glory Collagen 1 แคปซูล = 3.179 แคลลอรี่
            <br />
            Tomato Vit C 1 แคปซูล = 2 แคลลอรี่
          </div>
        ),
      },
    ],
    videoContent: {
      title: 'สิวหาย ออร่ากระจาย',
      subtitle:
        'หมดปัญหาสิว ผิวโกลว์สุด เคล็ดลับทำให้หน้าเนียน ผิวดี ด้วย Glory Collagen และ Tomato Vit C',
      url: 'https://www.youtube.com/watch?v=rDbLAJNC4pU',
    },
    disclaimer: null,
    reviews: [
      {
        image: '/glorycollagen/rv-twitter-1.jpg',
        review: (
          <div>
            จัดคอลลาเจนของ Glory มาอีกแบรนด์ ตัวนี้เป็นคอลลาเจน Di-Peptide นะจ๊ะ
            หลังจากที่ลองไปอาทิตย์นึง
            คือรู้สึกได้ว่าผิวมันดูใสและนุ่มขึ้นกว่าเดิมอ่ะ เป็นดี!
            แนะนำว่าให้ทานคู่กับวิตซีด้วย ช่วยให้เห็นผลไวขึ้นนะ
          </div>
        ),
        name: 'คุณออย',
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-twitter-2.jpg',
        review: (
          <div>
            สองสามอาทิตย์ก่อนออกข้างนอกบ่อย ทำให้โดนแดดตลอด
            ผิวคล้ำไปช่วงนึงเลยแหละ แต่เริ่มกิะคอลลาเจนแบรนด์ Glory มาสักพัก
            คนก็เริ่มทักว่าทำไมผิวใส ใสขึ้นจริงๆ
            มุกทานตัวนี้ตอนเช้าเม็ดเดียวเองนะ เป็นแบรนด์ที่กินแล้วเห็นผลไวมาก
            เลิฟเลย
          </div>
        ),
        name: 'คุณมุก',
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-twitter-3.jpg',
        review: (
          <div>
            เมื่อก่อนเริมผิวคล้ำมากแหละเพราะไม่ค่อยดูแลตัวเอง
            อย่างที่บอกช่วงนี้หันมาดูแลตัวเองโดยเฉพาะเรื่องผิว
            ผิวเราไม่ได้ขาวแต่ใสขึ้น ได้เท่านี้ก็คือดีใจสุดแล้ว
            เจอใครก็บอกว่าผิวใสขึ้น ปลื้มมม แนะนำคอลลาเจนตัวนี้เลยงับ "GLORY"
          </div>
        ),
        name: 'คุณจ๊ะ',
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-twitter-4.jpg',
        review: (
          <div>
            อยากผิวสวยแต่ไม่ชอบกินน้ำมะเขือเทศ ต้องลอง Glory Tomato Vit C กินไป
            1 สัปดาห์ วันละ 2 เม็ด ตอนเช้า รู้สึกได้เลยว่าผิวใสขึ้นอ่ะ
            แถมยังช่วยเสริมสร้างภูมิคุ้มกันให้เราในช่วงนี้ด้วย
          </div>
        ),
        name: 'คุณตัง',
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-twitter-5.jpg',
        review: (
          <div>
            ปกติไม่ค่อยกินอาหารเสริม แต่อยากลอง อยากผิวสวยๆ
            แต่กินมะเขือเทศไม่ได้เลย ทั้งแบบลูก แบบน้ำดอยคำ
            อันนี้กินง่ายดีของแบรนด์ Glory เป็นมะเขือเทศอัดแคปซูล
            ได้กินวิตามินซี กินวันละ 1 แคป ผิวกระจ่างใส ขาวขึ้นนะ
          </div>
        ),
        name: 'คุณขนมผิง',
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-twitter-6.jpg',
        review: (
          <div>
            ตอนแรกซื้อมาลอง เห็น packaging สวยดี แต่เห้ย!! เจอตัวนี้อาทิตย์เดียว
            ผิวใสจนเพื่อนทัก ทำกิจกรรมกลางแจ้งก็ไม่กลัวเลย
          </div>
        ),
        name: 'คุณบรัช',
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-twitter-9.jpg',
        review: (
          <div>
            เซ็ทคู่ดูแลผิวค้าบ Tomato Vit C + Collagen Dipeptide ใครอยากผิวสวย
            ขาวใส อยากให้ลอง อย่างละ 2 เม็ดทุกเช้า คือผิวดีมาก เลิฟเลย
          </div>
        ),
        name: 'คุณออนนี',
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-twitter-7.jpg',
        review: (
          <div>
            ใครไม่ชอบกินน้ำมะเขือเทศดอยคำ แต่อยากผิวขาวหน้าใส ลอง Vit C ของ
            Glory ดูค่า เป็นมะเขือเทศอัดเม็ด 1 แคปซูล
            ส่วนตัวจูนทานมาได้สักพักผิวมันใสขึ้น แต่มันยังไม่ถึงกับขาวนะ
            อาจต้องกินต่ออีก แต่หน้าดูขาวใสขึ้นเร็วจริง
            และสิวหรือรอยแดงจางลงด้วย
          </div>
        ),
        name: 'คุณจูน',
        product: 'GLCVC1',
      },
      {
        image: '/glorycollagen/rv-twitter-8.jpg',
        review: (
          <div>
            ชอบกินวิตามินซีกับน้ำมะเขือเทศคู่กัน 1 เม็ด เหมีอนได้กินมะเขือเทศ 34
            ผลเลย ช่วงที่รักษาสิวอาทิตย์เดียวยิ่งเห็นชัดเลยว่า
            รอยสิวหายไวมากกจนแฟนทัก ผิวหายหมอง แก้มใสๆ ขึ้น
          </div>
        ),
        name: 'คุณโอป',
        product: 'GLCVC1',
      },
      {
        image: '/glorycollagen/rv-twitter-10.jpg',
        review: (
          <div>
            เพิ่งมาสังเกตตอนที่เพื่อนทักว่าผิวดูใส ขาวขึ้น
            นี่ไม่ได้คาดหวังอะไรมากตอนที่กิน Vit C กับคอลลาเจนคู่นี้
            แต่ผลที่ได้หลังกินต่อเนื่องก็คือ ผิวดีขึ้นจริง นี่ชอบ Vit C
            ส่วนผสมจัดเต็ม เหมาะกับคนที่อยากผิวใสแต่กินดอยคำไม่ไหว
            เห็นว่าได้เรื่องสิวต่างๆ ด้วย ครบมาก!
          </div>
        ),
        name: 'คุณไหม',
        product: 'GLCVC1',
      },
      {
        image: '/glorycollagen/rv-twitter-11.jpg',
        review: (
          <div>
            เป็นคนผิวแทนที่กินกลูต้ากับคอลลาเจนตลอด ไม่ได้ซีเรื่องความขาว
            แต่เวลากินพวกนี้แล้วผิวจะใสมาก ถ่ายรูปออกมาก็ดูผิวเงาสุขภาพดีทั้งๆ
            ที่ไม่ได้ทาครีมหรืออะไรเลย ตอนนี้กินคอลลาเจนกับมะเขือเทศอัดเม็ดของ
            Glory คู่กันอยู่ ผิวเนียนใสมาก
          </div>
        ),
        name: 'คุณเมล่อน',
        product: 'GLCVC1',
      },
    ],
    productsIncluded: ['GLRCL1', 'GLRVC1'],
    relateProducts: [
      'GLRSET6',
      'GLRSR1',
      'GLRSET8',
      'GLRSET11',
      'GLRSET12',
      'GLRSET21',
      'GLRCL1',
      'GLRVC1',
      'GLRSC1',
      'GLRBS1',
    ],
    upsellProducts: ['GLRSC1', 'GLRBS1'],
  };
};
