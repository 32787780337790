export const shop = () => [
  'GLRCL1',
  'GLRVC1',
  'GLRSC1',
  'ESTHC1',
  'GLRBO1',
  'GLRSR1',
  'GLRVG1',
  'GLRSET1',
  'GLRSET6',
  'GLRSET8',
  'GLRSET9',
  'GLRSET11',
  'GLRSET12',
  'GLRSET21',
];

export const checkoutUpsell = () => ['ESTHC1', 'GLRBO1', 'GLRSC1'];

export const cartUpsell = () => ['GLRBO1', 'GLRSC1'];

export const checkoutCrosssell = () => ['ESTHC1', 'GLRBO1', 'GLRSC1'];
