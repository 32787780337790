import { config } from '../config';
import { gtmDataLayer } from '../lib/tag-manager';
import { getBasicInfo } from '../lib/user';

export const logWebsite = () => {
  const data = getBasicInfo();
  gtmDataLayer({
    event: 'pageView',
  });

  return fetch(`${process.env.PREACT_APP_SERVER_API_URL}/log/log-website`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
