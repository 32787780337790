import { LazyImage, Video } from '../../components/lazy-image';

export const content004 = () => {
  return {
    url: '/blog/review-nongploy',
    title: '100,000 บาทแรกของพลอย เกิดขึ้นที่นี่...',
    author: 'คุณพลอย',
    content: (
      <div>
        <div class="mt-2 text-center">
          <b>
            จากอดีตพนักงานทวงหนี้ สู่การเป็นเฮดหลักกลอรี่คอลลาเจน
            ที่พร้อมช่วยคุณให้มีรายได้เสริม โดยไม่จำเป็นต้องมีประสบการณ์มาก่อน
          </b>
        </div>
        <div class="mt-4">
          <i>เรียน: คุณผู้อ่านที่ต้องการผิวใส ควบคู่กับการมีรายได้เสริม</i>
        </div>
        <div>
          <i>จาก: พลอย เพิ่มปภาพร เฮดหลักกลอรี่คอลลาเจน</i>
        </div>
        <div class="mt-4">
          คุณคิดว่าต้องอายุเท่าไร ถึงจะเริ่มธุรกิจของตัวเอง ?
        </div>
        <div class="mt-2">
          ลูกทีมพลอยคนนึง เป็นนักศึกษาจากมหาวิทยาลัยเชียงใหม่ปี 2
          ทำรายได้จากการขายกลอรี่ประมาณ 20,000 บาท ตั้งแต่เดือนแรกที่เริ่มขาย
        </div>
        <div class="mt-4">
          <LazyImage src="/glorycollagen/blog-review-ploy-1.jpg" />
        </div>
        <div class="mt-1 text-center text-sm italic">
          พลอยกำลังตรวจเช็คสินค้า Glory ก่อนจัดส่งให้ลูกค้าวันนี้
        </div>
        <div class="mt-4">
          คุณคิดว่าต้องใช้เวลามากขนาดไหน ถึงจะขายสินค้าได้ ?
        </div>
        <div class="mt-2">
          ลูกทีมพลอยคนนึง โพสต์รีวิวผลลัพธ์ที่ตัวเองกินกลอรี่คอลลาเจนกับวิตซีไป
          7 วันลงในไอจี แค่โพสต์นั้นโพสต์เดียวก็ขายกลอรี่ไปได้ประมาณ 50 ซอง
        </div>
        <div class="mt-4">
          <LazyImage src="/glorycollagen/blog-review-ploy-2.jpg" />
        </div>
        <div class="mt-1 text-center text-sm italic">
          Glory คอลลาเจน และโทเมโทวิตซี ที่ต้องส่งในวันนี้
        </div>
        <div class="mt-4">
          คุณคิดว่าต้องลงทุนมากขนาดไหน ถึงจะเริ่มต้นทำธุรกิจได้ ?
        </div>
        <div class="mt-2">
          ลูกทีมพลอยคนนึง เริ่มต้นจากการเปิดบิลตัวแทน 10 ซอง ภายใน 2 เดือน
          ตอนนี้เบิกของครั้งละ 200 ซอง โดยไม่ต้องลงทุนด้วยเงินตัวเองเลย
        </div>
        <div class="my-4">
          <LazyImage src="/glorycollagen/blog-review-ploy-3.jpg" />
        </div>
        <div class="mt-1 text-center text-sm italic">
          พลอยกำลังแพ็คน้องๆ กลอรี่ลงกล่องจ้าา พร้อมส่งตรงความสวย ผิวใส
          ไปให้คุณลูกค้า
        </div>
        <div class="mt-4">คุณอยากมีเงินเพิ่มเดือนละเท่าไร ?</div>
        <div class="mt-2">
          ถ้าคุณอยากมีเงินเพิ่มเดือนละ 10,000 บาท
          คุณเพียงขายกลอรี่คอลลาเจนเดือนละ 70 ซองเท่านั้น
          คุณก็ได้รายได้เพิ่มเดือนละ 10,000 บาทแล้ว
        </div>
        <div class="mt-2">
          ถ้าคุณแนะนำให้ลูกค้าคุณทานกลอรี่คอลลาเจนควบคู่กับโทเมโท่วิตซี
          คุณแค่ขายให้ลูกค้าเดือนละ 35 คน หรือเฉลี่ยวันละคนเท่านั้น
        </div>
        <div class="mt-2">
          เพียงคุณหาลูกค้าที่ทานกลอรี่วันละ 1 คน คุณก็มีรายได้เพิ่มเดือนละ
          10,000 บาท
        </div>
        <div class="mt-2">
          ถ้าคุณหาลูกค้าที่ทานกลอรี่วันละ 2 คน คุณก็มีรายได้เพิ่มเดือนละ 20,000
          บาท
        </div>
        <div class="mt-2">แต่ถ้าคุณหาลูกค้าได้วันละ 5-10 คนละ ?</div>
        <div class="mt-2">
          รายได้เดือนละ 50,000 - 100,000
          บาทต่อเดือนก็ไม่ใช่เรื่องเหนือความคาดหมาย...
        </div>
        <div class="mt-2">
          มากไปกว่านั้นกลอรี่คืออาหารเสริมที่เราจำเป็นต้องทานทุกวัน โทเมโท่วิตซี
          หรือวิตามินซี คือวิตามินที่จำเป็นต่อร่างกายต้องทานทุกวัน
          คอลลาเจนก็เหมือนกัน
        </div>
        <div class="mt-2">
          ถ้าลูกค้า 60-70% กลับมาซื้อคุณซ้ำทุกเดือนๆ คุณเพียงหาลูกค้าใหม่แค่ 1-3
          เดือนแรกเท่านั้น
          หลังจากนั้นคุณก็จะมีลูกค้าประจำที่ยินดีจะจ่ายเงินให้คุณทุกเดือนๆ
        </div>
        <div class="mt-2">
          มีเพียงอย่างเดียวที่คุณต้องเหนื่อยหลังจากที่คุณมีลูกค้าประจำก็คือ...
          การนั่งเช็คสลิปโอนเงิน ที่ลูกค้าโอนเข้ามาทุกวัน ทุกชั่วโมง
          และการแพ็คของส่งลูกค้าเท่านั้นเอง
        </div>
        <div class="my-4">
          <LazyImage src="/glorycollagen/blog-review-ploy-4.jpg" />
        </div>
        <div class="mt-1 text-center text-sm italic">
          น้องๆ Glory พร้อมเดินทางไปมอบผิวใส มีออร่าให้คุณลูกค้าแล้วค่า
        </div>

        <div class="mt-4">
          มีปัญหาอีกอย่างหลังจากคุณมีลูกค้าประจำอย่างน้อย 100-200 คนคือ...
          คุณจะไม่รู้ว่าจะเอาเงินที่มีในแต่ละเดือนไปทำอะไรดี
          หรือเอาไปลงทุนอะไรเพิ่มดี
          นั่นคือสิ่งที่คุณต้องเตรียมใจหลังจากมีรายได้เพิ่มจากกลอรี่
        </div>
        <div class="mt-2">
          นี่คือกราฟของคนที่ค้นหาคอลลาเจนจาก Google Trends
          คุณจะพบว่ามันเติบโตขึ้นทุกปีๆ และนี่คือโอกาสของคุณ
          โอกาสในการสร้างรายได้เพิ่มและธุรกิจของคุณ
        </div>
        <div class="my-4">
          <LazyImage src="/glorycollagen/blog-review-ploy-5.jpg" />
        </div>
        <div class="mt-1 text-center text-sm italic">
          ปริมาณการค้นหาคำว่า "คอลลาเจน" ที่สูงขึ้นเรื่อยๆ ทูกปี
          นี่คือโอกาสมหาศาลที่คุณสามารถสร้างรายได้
        </div>
        <div class="mt-4">
          ถ้าคุณยังไม่เคยขายของ ไม่รู้จะเริ่มต้นขายสินค้ายังไง ไม่เป็นไร...
          พลอยและทีมงานยินดีสอนให้...
        </div>
        <div class="mt-2">
          ถ้าคุณยังไม่เคยทำ Instagram หรือ Tiktok ไม่เป็นไร…
          พลอยและทีมงานยินดีสอนให้...
        </div>
        <div class="mt-2">
          ถ้าคุณยังไม่เคยลงโฆษณาบน Facebook หรือ Youtube ไม่เป็นไร...
          พลอยและทีมงานยินดีสอนให้...
        </div>
        <div class="mt-2">
          ถ้าคุณยังไม่เคยรู้จักการสร้าง Personal Branding ไม่เป็นไร...
          พลอยและทีมงานยินดีสอนให้...
        </div>
        <div class="mt-2">
          แต่ถ้าคุณไม่อยากมีรายได้เพิ่ม
          ไม่อยากเพิ่มประสบการณ์ในการทำธุรกิจของตัวเอง
          ไม่อยากเรียนรู้และทดลองทำอะไรใหม่ๆ ... คงได้แต่ทำใจ...
          พลอยและทีมงานช่วยอะไรไม่ได้จริงๆ ค่ะ
        </div>
        <div class="my-4">
          <LazyImage src="/glorycollagen/blog-review-ploy-6.jpg" />
        </div>
        <div class="mt-1 text-center text-sm italic">
          พลอยกำลังรอพี่ Flash Express มารับของไปส่งอยู่จ้า
        </div>
        <div class="mt-4">
          นี่เป็นโอกาสเดียว ที่คุณลงทุนขั้นต่ำแค่ 1,700 บาท
          แต่สามารถสร้างรายได้หลักแสนบาท โดยไม่มีความเสี่ยง
        </div>
        <div class="mt-2">
          นี่เป็นโอกาสเดียว ที่คุณจะได้เรียนรู้การทำธุรกิจ จากโค้ชชื่อดัง
          และนักธุรกิจระดับร้อยล้าน ที่จะมาสอนให้คุณฟรีๆ จาก Glory Academy
        </div>
        <div class="mt-2">
          นี่เป็นโอกาสเดียว ที่พลอยและทีมงาน รวมถึง CEO ของ Glory
          พร้อมสนับสนุนให้คุณมีรายได้ตั้งแต่หลักหมื่นถึงหลักแสนต่อเดือน
          ตามที่คุณต้องการ
        </div>
        <div class="mt-2">โอกาสเป็นของคุณแล้ว...</div>
        <div class="mt-2">
          แต่โอกาสนี้ก็มีจำกัด เพราะระหว่างที่คุณกำลังอ่านอยู่นี้
          มีลูกค้ากลอรี่ท่านอื่นๆ อีกหลายพันคนกำลังอ่านอยู่ด้วยพร้อมๆ กัน...
        </div>
        <div class="mt-2">
          พลอยรับตัวแทนกลอรี่สายงานพลอยอีกแค่ 20 คน เท่านั้นค่ะ
          พลอยตั้งใจจะปั้นให้ตัวแทนพลอยทุกคนประสบความสำเร็จจริงๆ ค่ะ
          ถ้ามีเยอะไปพลอยเกรงว่าจะดูแลไม่ทั่วถึง
        </div>
        <div class="mt-2">
          ถ้าคุณตัดสินใจช้าแล้วมีคนอื่นๆ สมัครมาเป็นตัวแทนพลอยจนเต็มโควต้า
          พลอยต้องขออนุญาตให้คนที่สมัครก่อนได้ก่อนนะคะ...
        </div>
        <div class="mt-2">พลอยรับตัวแทนจำนวนจำกัดค่ะ...</div>
        <div class="my-4">
          <LazyImage src="/glorycollagen/blog-review-ploy-7.jpg" />
        </div>
        <div class="mt-1 text-center text-sm italic">
          พลอยพร้อมสนับสนุนให้คุณประสบความสำเร็จ
          และมีรายได้เพิ่มตามที่ต้องการ...
        </div>
        <div class="mt-4">
          <b>ตอนนี้ถึงเวลาที่คุณต้องตัดสินใจแล้วค่ะ !</b>
        </div>
        <div class="mt-2">
          ถ้าคุณอยากจะมีรายได้เพิ่มเดือนละหลักหมื่นถึงหลักแสนต่อเดือน
        </div>
        <div class="mt-2">
          ถ้าคุณอยากจะเรียนรู้วิธีการทำธุรกิจจากคนที่ประสบความสำเร็จ
        </div>
        <div class="mt-2">
          และถ้าคุณอยากให้พลอยและทีมงานช่วยสนับสนุนให้คุณประสบความสำเร็จ
        </div>
        <div class="mt-2">
          กดปุ่มแอดไลน์แล้วทักแชทมาสมัครตัวแทนจำหน่ายกับพลอยได้เลยค่ะ
        </div>
        <div class="mt-2">
          พลอยรอช่วยคุณให้ประสบความสำเร็จอยู่นะคะ
          สมัครตัวแทนจำหน่ายกับพลอยได้เลย
        </div>
        <div class="mt-4">ขอบพระคุณค่า</div>
        <div class="my-4">
          <LazyImage src="/glorycollagen/blog-review-ploy-8.jpg" />
        </div>
        <div class="mt-4">พลอย เพิ่มปภาพร</div>
        <div class="mt-4">เฮดหลักกลอรี่ทีมละมุน</div>
        <div>และคนที่พร้อมจะสนับสนุนให้คนประสบความสำเร็จ</div>
        <div class="mt-4">
          <b>หมายเหตุ:</b>
        </div>
        <div>
          รายได้เฉลี่ยของลูกทีมพลอยตอนนี้อยู่ที่เดือนละ 10,000 - 20,000 บาทนะคะ
          พลอยกำลังจะดันให้ได้คนละ 30,000 - 50,000 บาทต่อเดือน อยู่ค่า
        </div>
      </div>
    ),
    relateProducts: ['GLRSET1', 'GLRCL1', 'GLRVC1'],
  };
};
