import { LazyImage, Video } from '../../components/lazy-image';

export const content005 = () => {
  return {
    url: '/blog/review-jinselect',
    title:
      'พลิกหลังกล่องเจาะลึกสารสกัด : กลอรี่คอลลาเจน และ กลอรี่วิตามินซีมะเขือเทศ',
    author: 'Jinselect',
    content: (
      <div>
        <div class="mt-4">
          <LazyImage src="/glorycollagen/blog-glory-jinse-1.jpg" />
        </div>
        <div class="mt-4">
          บทความนี้จินจะมาเจาะลึกรายละเอียดของผลิตภัณฑ์อาหารเสริมจากกลอรี่
          อีกหนึ่งแบรนด์คนไทยที่ต้องบอกเลยค่ะว่ากระแสบนโลกโซเชียลดีมาก ๆ
          ทั้งรีวิวจากยูทูปเบอร์ ทวิตเตอร์ และไอจี วันนี้ jinselect
          เลยขอหยิบอาหารผิวตัวฮิตทั้ง 2 ตัวจากแบรนด์นี้มารีวิวเจาะลึกกันค่าาา ..
        </div>
        <div class="mt-4 text-center">
          <h3>กลอรี่เป็นอาหารผิว</h3>
        </div>
        <div class="mt-4">
          <LazyImage src="/glorycollagen/blog-glory-jinse-2.jpg" />
        </div>
        <div class="mt-4">
          ที่เลือกใช้คำว่า “อาหารผิว” แทนที่จะเป็น “อาหารเสริม”
          เพราะว่าสารสกัดที่ใส่มาในกลอรี่ เป็นสารสกัดที่เขาบอกว่า
          ร่างกายของเราผลิตเองได้อยู่แล้ว ทั้งคอลลาเจนที่เป็น Type เดียวกับผิว
          รวมถึงวิตามินซีที่เป็นวิตามินที่ร่างกายจำเป็นอยู่แล้วนั่นเอง
        </div>

        <div class="mt-2">
          กลอรี่ออกตัวอาหารผิวมา 2 ตัวคือ กลอรี่คอลลาเจนได-เปบไทด์
          และกลอรี่โทเมโท่วิตซี ซึ่งมาในรูปของผงอัดในแคปซูล 1 ซองมี 60
          แคปซูลสามารถทานได้ประมาณ 1 เดือนค่ะ
        </div>
        <div class="mt-4 text-center">
          <h3>คอลลาเจนที่ดีที่สุดสำหรับผิว</h3>
        </div>

        <div class="mt-4">
          คอลลาเจน (Collagen) เป็นเส้นใยโปรตีนที่มีมากถึง 1 ใน 3
          ส่วนของร่างกายเรา ซึ่งจะประกอบไปด้วยกรดอะมิโนหลายชนิด พบมากในผิวหนัง
          เส้นผม กระดูก เล็บ ข้อต่อ และเส้นเอ็น
          ช่วยให้เกิดความยืดหยุ่นและชุ่มชื้นในส่วนต่าง ๆ
        </div>
        <div class="mt-2">
          หลังอายุ 25 ปีร่างกายจะผลิตคอลลาเจนได้ลดลง 2.5% และน้อยลงทุกปี
          เป็นสาเหตุที่ว่าเมื่ออายุมากผิวพรรณที่เคยเต่งตึงก็จะเริ่มเกิดริ้วรอยนั่นเองค่ะ
        </div>
        <div class="mt-2">
          ในปัจจุบันเราสามารถเพิ่มคอลลาเจนให้กับร่างกายได้โดยการรับประทานอาหารที่มีปริมาณคอลลาเจนสูง
          เช่น ปลาทะเล ไข่ ผักใบเขียว และผลไม้อย่าง อะโวคาโด มะกอก มะเขือเทศ
          ถั่ว เป็นต้น คอลลาเจนแบ่งออกเป็น 3 ชนิด ได้แก่
        </div>
        <ul class="list-disc mt-4 ml-10 text-baseColor">
          <li>คอลลาเจนเปปไทด์ มีโมเลกุลใหญ่กว่า 300,000 ดาลตัน </li>
          <li>
            คอลลาเจนไตรเปปไทด์
            เป็นคอลลาเจนที่ผ่านกระบวนย่อยจนเหลือกรดอะมิโนตัวเรียงกันมีขนาดโมเลกุลเฉลี่ย
            500-1000 ดาลตัน มีความสามารถในการดูดซึมได้ในระดับปานกลาง{' '}
          </li>
          <li>
            คอลลาเจนไดเปปไทด์ เป็นคอลลาเจนที่ผ่านกระบวนการย่อยโดยเอนไซม์
            จนเหลือกรดอะมิโน 2 ตัวเรียงกัน มีโมเลกุลขนาดเล็กเพียง 200 ดาลตัล
            จึงทำให้มีประสิทธิภาพในการดูดซึมได้อย่างรวดเร็วและมากกว่าคอลลาเจนชนิดอื่น
            ๆ{' '}
          </li>
        </ul>
        <div class="mt-2">
          ด้วยคุณสมบัติพิเศษนี้ คอลลาเจนไดเปปไทด์ (Collagen Dipeptide)
          ซึ่งมีโมเลกุลเฉลี่ยเล็กมาก จึงไม่ถูกย่อยสลายในกระเพาะอาหาร
          แต่จะถูกดูดซึมผ่านผนังลำไส้เล็กและเข้าสู่กระแสเลือด
          จึงถือเป็นคอลลาเจนที่ดูดซึมได้เร็วที่สุดในปัจจุบันนั่นเอง
        </div>
        <div class="mt-4 text-center">
          <h3>คอลลาเจนต้องคู่กับวิตามิน C</h3>
        </div>
        <div class="mt-4">
          คอลลาเจนต้องคู่กับวิตามิน C
          โดยปกติแล้วคอลลาเจนจะถูกสังเคราะห์โดยกรดอะมิโนที่ร่างกายได้รับจากรับประทานสารอาหารชนิดต่าง
          ๆ โดยเฉพาะอาหารที่มีวิตามินซี แต่หากร่างกายมีปริมาณวิตามิน C
          ไม่เพียงพอ การสังเคราะห์คอลลาเจนก็อาจจะไม่สมบูรณ์
        </div>
        <div class="mt-2">
          เพราะฉะนั้นแล้วการทานคอลลาเจนให้เห็นผลดียิ่งขึ้นจึงควรทานคู่กันกับวิตามินซีนั่นเองค่ะ
        </div>
        <div class="mt-4 text-center">
          <h3>ทำไมต้องวิตามิน C จากมะเขือเทศ</h3>
        </div>
        <div class="mt-4">
          ร่างกายคนเราต้องการวิตามินซีที่ 1,000 - 3,000 มิลลิกรัมต่อวัน
          เพื่อช่วยในการดูดซึมสารอาหารต่างๆได้อย่างรวดเร็ว
          และเป็นสารต้านอนุมูลอิสระ แต่รู้ไหมคะว่า วิตามินซี
          เป็นวิตามินที่บอบบางมากถูกทำลายได้ง่ายในความร้อน
          และแสงแดดจึงมีการคิดค้นอนุพันธ์วิตามินซีที่ช่วยคงรูปของวิตามินซีไม่ให้ถูกทำลายก่อนที่เราจะบริโภคเข้าไป
        </div>
        <div class="mt-2">
          กลอรี่ใช้วิตามินซีจากหลายแหล่ง
          ไม่ว่าจะเป็นวิตามินซีจากอะเซโรล่าเชอรี่ที่ให้วิตามินซีสูงมาก
          รวมถึงสารสกัดจากมะเขือเทศนำเข้าจากสวิสเซอร์แลนด์
          ซึ่งเป็นแหล่งของวิตามินซี และสารอาหารที่ช่วยบำรุงผิว ซึ่งใน 1
          แคปซูลให้สารอาหารเทียบเท่าการทานมะเขือเทศถึง 34 ลูก !
        </div>
        <div class="mt-4 text-center">
          <b>จุดเด่นของกลอรี่ ในสายตา Jinselect </b>
        </div>
        <div class="mt-4">
          <LazyImage src="/glorycollagen/blog-glory-jinse-3.jpg" />
        </div>
        <div class="mt-4">
          <h5>กินง่าย พกง่าย ไม่ต้องชง ไม่ต้องทนคาว </h5>
        </div>
        <div class="mt-2">
          อย่างที่รู้กันดีว่าคอลลาเจนที่มีโมเลกุลใกล้เคียงกับร่างกายเรานั้นส่วนใหญ่นั้นได้มาจากปลา
          เพราะฉะนั้นแทบจะหลีกเลี่ยงความคาวไม่ได้เลยกลอรี่เลยเอาคอลลาเจนเข้มข้นมาบรรจุในรูปแบบแคปซูล
          ลดขั้นตอน ไม่ต้องชง แค่กลืนผิวก็ดีแล้ว !
        </div>
        <div class="mt-2">
          ส่วนคนที่ไม่ชอบกินยาเม็ดก็ยังสามารถแกะชงกับน้ำผลไม้
          เพื่อลดความคาวได้เช่นกัน
        </div>
        <div class="mt-4">
          <h5>ใส่แค่สารสกัดสำหรับผิวจริงๆ ไม่ต้องกลัวสารตกค้าง </h5>
        </div>
        <div class="mt-2">
          กลอรี่ 1 ซองให้สารอาหารเพียงอย่างเดียว คือคอลลาเจน กับ วิตามินซี
          ชัดเจน และไม่ซับซ้อน ที่สำคัญคือเป็นสารสำคัญที่จำเป็นต่อผิวจริง ๆ
          ให้มาในปริมาณแอคทีฟโดส จึงทำให้มั่นใจได้ว่าเห็นผลจริง
          และในระยะยาวก็ไม่ต้องกังวลกับสารสกัดอื่นๆ ที่อาจจะตกค้างสะสมได้นั่นเอง
        </div>
        <div class="mt-4">
          <h5>สินค้าคุณภาพในราคาประหยัด </h5>
        </div>
        <div class="mt-2">
          ด้วยค่าใช้จ่ายในปัจจุบัน
          การจะแบ่งเงินมากินอาหารเสริมสำหรับผิวหรือดูแลตัวเองเพิ่มก็เป็นอีกหนึ่งอย่างที่ต้องชั่งน้ำหนัก
          ว่าจะกินอาหารเสริม แล้วไม่มีเงินกินข้าวดี หรืออยากสวยดี
        </div>
        <div class="mt-2">
          แต่กลอรี่คิดมาให้แล้ว ว่าคุณไม่จำเป็นต้องเลือก คุณสามารถมีผิวสวย
          โดยไม่ต้องแลกกับอะไรเลย ด้วยปริมาณใน 1 เซทที่สามารถทานได้ทั้งเดือน
          ในราคาเพียงหลักร้อยเท่านั้น
        </div>
        <div class="mt-4">
          <h5>ตอบโจทย์เรื่องสิว </h5>
        </div>
        <div class="mt-2">
          กลอรี่ขึ้นชื่อมากในเรื่องการดูแลปัญหาสิว
          เหล่าไอดอลและยูทูปเบอร์ต่างยืนยันและรีวิวสินค้าไปในทางเดียวกันว่า
          กลอรี่ ช่วยลดปัญหาสิวได้จริงเมื่อทานต่อเนื่องกัน
        </div>

        <div class="mt-1">
          <i>** ผลลัพธ์ที่ได้ขึ้นอยู่กับบุคคล</i>
        </div>
        <div class="mt-4">
          <h5>การันตีคุณภาพ โรงงานมาตรฐานสินค้าส่งออกได้ทั่วโลก </h5>{' '}
        </div>
        <div class="mt-2">
          ผลิตภัณฑ์กลอรี่ได้รับมาตรฐานความปลอดภัยทั้ง อ.ย. , GMP และ Halal
          ที่มั่นใจได้ว่า สินค้าสามารถส่งออกได้ทั่วโลก
          รวมถึงชาวมุสลิมก็สามารถทานได้อย่างมั่นใจ
        </div>
        <div class="mt-1">
          <i>** ผลลัพธ์ที่ได้ขึ้นอยู่กับบุคคล</i>
        </div>
        <div class="mt-4 text-center">
          <b>กลอรี่คอลลาเจน X กลอรี่วิตซี ทานคู่กันช่วยเรื่องใดบ้าง ? </b>
        </div>
        <div class="mt-4">
          <LazyImage src="/glorycollagen/blog-glory-jinse-4.jpg" />
        </div>
        <div class="mt-4">
          กลอรี่คอลลาเจน X กลอรี่วิตซีเหมาะกับคนที่มีปัญหาต่อไปนี้
        </div>

        <ul class="list-disc mt-4 ml-10 text-baseColor">
          <li>
            <b>คนที่มีปัญหาผิวหมองคล้ำจากแดด : </b>
            กลอรี่คอลลาเจนเมื่อทำงานคู่กับกลอรี่วิตซีช่วยให้ผิวกระจ่างใสขึ้นผิวแลดูมีออร่ามากขึ้นหลังทาน
            3-4 สัปดาห์
          </li>
          <li>
            <b>เป็นสิวหน้า สิวหลัง สิวอักเสบที่เดิมซ้ำๆ ไม่หาย : </b>
            วิตามินซีจากกลอรี่มะเขือเทศช่วยฆ่าเชื้อสิว ลดการอักเสบ
            ทำให้สิวแห้งเร็วขึ้นโดยไม่ทิ้งรอย
          </li>
          <li>
            <b>รอยดำ รอยแดงจากสิว : </b>
            ปัญหาใหญ่ของนักแกะเกาคือการทิ้งรอยของสิวกลอรี่เข้าไปฟื้นฟูผิวจากภายใน
            ลดเลือนเม็ดสีจากสิวอย่างเป็นธรรมชาติ
          </li>
          <li>
            <b>ภูมิแพ้ เป็นหวัดบ่อย ร่างกายไม่แข็งแรง : </b>
            วิตามินซีช่วยสร้างภูมิคุ้มกัน ฆ่าเชื้อไวรัส
            สาเหตุของหวัดและโรคภูมิแพ้
          </li>
          <li>
            <b>กระดูกลั่น ปวดข้อเข่า เล็บเปราะ ผมไม่แข็งแรง : </b>
            คอลลาเจนเป็นส่วนประกอบที่สำคัญในข้อต่อ เอ็น และกระดูก เล็บ ผิวและผม
            การทานคอลลาเจนเสริมเป็นประจำจึงทำให้อวัยวะเหล่านั้นแข็งแรงขึ้น
          </li>{' '}
        </ul>
        <div class="mt-4 text-center">
          <b>สารสกัดกลอรี่</b>
        </div>
        <div class="mt-4">
          <LazyImage src="/glorycollagen/blog-glory-jinse-5.jpg" />
        </div>
        <div class="mt-4">
          <b>Glory Collagen di-peptide : </b>Collagen di-peptide 454 มก. ต่อ 1
          แคปซูล
        </div>
        <div class="mt-4">
          <b>Glory Tomato Vitamin C : </b>Acerola Cherry Extract Powder,Ascorbic
          Powder(Vitamin C),Tomato Extract
        </div>
        <div class="mt-4 text-center">
          <b>Glory User Reviews</b>
        </div>
        <div class="mt-4">
          <LazyImage src="/glorycollagen/review-jinse-1.jpg" />
          <LazyImage src="/glorycollagen/review-jinse-2.jpg" />
          <LazyImage src="/glorycollagen/review-jinse-3.jpg" />
          <LazyImage src="/glorycollagen/review-jinse-4.jpg" />
          <LazyImage src="/glorycollagen/review-jinse-5.jpg" />
        </div>
        <div class="mt-4 text-center">
          <b>มีอะไรที่คุณอาจจะไม่ชอบในกลอรี่บ้าง ?</b>
        </div>
        <div class="mt-4">
          <LazyImage src="/glorycollagen/blog-glory-jinse-6.jpg" />
        </div>
        <div class="mt-4">
          ช่วงไหนที่เป็นสิว คุณอาจจะเพิ่มสูตรการกินเป็นการบูสอัพ ซึ่งนั่นแปลว่า
          คุณอาจจะต้องกลืนแคปซูลกลอรี่ 6-7 เม็ดใน 1 มื้อ
          ซึ่งนอกจากจะระวังการติดคอแล้วเรื่องอื่นก็ไม่ต้องกังวลค่ะ
          จินแนะนำให้กินทีละเม็ดเพื่อความปลอดภัยนะคะ อิอิ
        </div>
        <div class="mt-4">
          <h4>วิธีทานกลอรี่ </h4>
        </div>
        <div class="mt-2">
          <b>สูตรบำรุงผิว : </b>
        </div>
        <div class="mt-1">ตอนเช้า คอลลาเจน 2 วิตซี 2</div>
        <div class="mt-2">
          <b>สูตรบูสผิว : </b>
        </div>
        <div class="mt-1">ตอนเช้า คอลลาเจน 2 วิตซี 2</div>
        <div class="mt-1">ก่อนนอน คอลลาเจน 2 วิตซี 2</div>
        <div class="mt-4">
          <h4>ผลข้างเคียง</h4>
          <div class="mt-2">
            ในผลิตภัณฑ์กลอรี่มีคอลลาเจนจากปลาทะเล
            ผู้ที่แพ้อาหารทะเลโปรดปรึกษาแพทย์ก่อนทาน
          </div>
          <div class="mt-2">
            และอย่าลืมว่า “อาหารเสริม” ไม่ใช่ยา
            จึงไม่มีส่วนในการช่วยป้องกันหรือรักษาโรคใดๆ นะคะ
          </div>
        </div>
        <div class="mt-4 text-center">
          <b>คุณจะหาซื้อ กลอรี่ ได้จากที่ไหน? </b>
        </div>
        <div class="mt-4">
          <LazyImage src="/glorycollagen/blog-glory-jinse-7.jpg" />
        </div>
        <div class="mt-4">
          คุณสามารถซื้อผลิตภัณฑ์กลอรี่ ได้โดยตรงจากเว็ปไซต์อย่างเป็นทางการของ
          <a
            class="font-bold text-primary hover:text-primaryLight"
            href="https://gloryth.com/"
          >
            {' '}
            ผลิตภัณฑ์ที่นี่{' '}
          </a>
          การซื้อโดยตรงเป็นวิธีเดียวที่จะทำให้มั่นใจได้ว่าคุณได้ผลิตภัณฑ์ของแท้
        </div>
        <div class="mt-1">
          คุณสามารถชำระเงินผ่านช่องทางโอนเงินผ่าน Mobile Banking ,
          บัครเคดิตและบัตรเดบิต หรือเลือกชำระเงินแบบเก็บเงินปลายทาง (COD)
        </div>
        <div class="mt-4 text-center">
          <b>รีวิวอื่นๆ จากผู้ใช้จริง</b>
        </div>
        <div class="mt-4">
          <LazyImage src="/glorycollagen/blog-glory-jinse-8.jpg" />
        </div>
        <div class="mt-4">
          <i>
            “เป็นแบรนด์ที่มีคุณภาพมากๆ เลยค่ะ เห็นผลเกินคาด ดีใจมากที่เจอ
            ทานตัวอื่นมา 4-5 ปีก็ไม่เคยจะเห็นผล นี่แนะนำเพื่อนหลายคนเลยค่ะ
            ทานวิตซีมา 1 ซองผิวอมชมพูมากๆ เลยจะมาลองเซตคู่ต่อ”
          </i>
        </div>

        <div class="mt-1">— คุณ nooke_ez</div>
        <div class="mt-4">
          <i>
            “จะบอกว่าคอลลาเจนที่สั่งมาลอตแรก ทานแล้วผิวละเอียดขึ้นจิงค่ะ
            ไว้อุดหนุนเรื่อยๆ นะคะ”
          </i>
        </div>

        <div class="mt-1">— คุณ kawinkim</div>
        <div class="mt-4">
          <i>
            “ไม่รู้คิดไปเองหรือป่าวนะคะ
            แต่รู้สึกหน้าดูขาวขึ้นหลังทานคอลลาเจน+วิตซีมา 7
            วันและก็รู้สึกสิวลดลง (ยารักษาสิวที่เคยทานก็ปรับลดลงแล้วด้วย) ”
          </i>
        </div>

        <div class="mt-1">— คุณ J a n n e Y</div>
        <div class="mt-4 text-center">
          <b>FAQs</b>
        </div>
        <div class="mt-4">
          <LazyImage src="/glorycollagen/blog-glory-jinse-9.jpg" />
        </div>
        <div class="mt-4">
          <b>Q: กินคู่กันดียังไง ?</b>
        </div>
        <div class="mt-2">
          A: คอลลาเจนช่วยให้ผิวใส ดูแลผิว ผม เล็บและกระดูก วิตซีมะเขือเทศ
          เพิ่มประสิทธิภาพในการดูดซึมคอลลาเจน เข้าสู่ร่างกายได้ดียิ่งขึ้น
          ตัวเร่งให้ผิวขาวไวขึ้น ลดรอยสิวได้ดียิ่งขึ้น
          ตัวเร่งให้ผิวขาวไวลดรอยสิว กระชับรูขุมขน ผิวไหม้จากแสงแดด
        </div>
        <div class="mt-4">
          <b>Q: อายุ 10-13 ทานได้ไหมคะ ?</b>
        </div>
        <div class="mt-2">
          A: ทานได้ค่ะ เพราะกลอรี่สกัดจากสารสกัดธรรมชาติ 100%
          แต่ถ้าให้แนะนำเลือก 1 อย่าง แนะนำให้ทานตัวมะเขือเทศวิตซีค่ะ
          เพราะในผิววัยนี้ คอลลาเจนสามารถผลิตได้ดีอยู่แล้วค่ะ
          ส่วนวิตซีเป็นวิตามินที่ร่างกายควรได้รับทุกวันเพื่อผิวขาวใสอมชมพูและลดสิวค่ะ
        </div>
        <div class="mt-2">
          <b>Q: แพ้อาหารทะเลทานคอลลาเจนได้ไหม?</b>
        </div>
        <div class="mt-2">
          A: ถ้าไม่ได้แพ้อาหารจำพวก ปลาทะเล สามารถทานได้ไม่มีปัญหาค่ะ
        </div>
        <div class="mt-2">
          <b>Q: มะเขือเทศวิตามินซีทานตอนท้องว่างได้ไหม ?</b>
        </div>
        <div class="mt-2">
          A: ตัววิตามินซีจะมีความเป็นกรดอ่อนๆ เท่านั้น
          หากทานแล้วไม่ระคายเคืองกระเพาะอาหารก็สามารถทานได้เลย
        </div>
        <div class="mt-2">
          <b>Q: 1 capsule มีกี่แคลลอรี่ ?</b>
        </div>
        <div class="mt-2">
          A: Glory Di-Peptide = 3.179 Kcal , Glory Vit C = 2 Kcal
        </div>
        <div class="mt-2">
          <b>Q: จุดสีดำๆ ที่พบในผงวิตามินซีคืออะไร อันตรายไหม?</b>
        </div>
        <div class="mt-2">
          A: เป็นสาร "ไลโคปิน" ที่ผ่านวิธีการสกัดสารให้มีความเข้มข้น
          เราต้องการให้สารสกัดเข้มข้นที่สุดจึงใช้กระบวนการผลิตที่ทำให้เป็นเม็ดสีเพื่อเก็บสารสำคัญจากมะเขือเทศได้
        </div>
        <div class="mt-4">
          <LazyImage src="/glorycollagen/blog-glory-jinse-10.jpg" />
        </div>
        <div class="mt-4">
          <h4>
            <u>สรุป </u>
          </h4>
        </div>
        <div class="mt-4">
          <b>กินง่าย สะดวก : 8/10</b>
        </div>
        <div class="mt-2">
          สำหรับคนที่กินแคปซูลได้ถือว่ากินง่ายมากๆ ค่ะ
          แต่สำหรับจินขอหักในความเป็นแคปซูลและต้องกินหลายเม็ดใน 1
          มื้อมีความติดคอมากแต่ไม่ถึงกับลำบาก
          แต่ถ้าต้องชงกินบอกเลยนะคะว่าแนะนำให้กินกับน้ำผลไม้ค่า
        </div>
        <div class="mt-2">
          <b>เห็นผลเรื่องผิว : 10/10</b>
        </div>
        <div class="mt-2">
          สำหรับจินหลังทานต่อเนื่องกัน 1 เดือน จินรู้สึกได้ถึงความฟู ลื่น นุ่ม
          ออร่าของผิวมาก ๆ เลยค่ะพอกินตัวนี้ ส่วนในเรื่องสิว กลอรี่ก็ทำได้ดี
          สิวแห้งไว ไม่ทิ้งรอยที่สำคัญช่วยลดสิวที่จะเกิดใหม่
          ถือว่าตอบโจทย์เรื่องผิวได้ดีมาก
        </div>
        <div class="mt-2">
          <b>เห็นผลเรื่องอื่น ๆ : 9/10</b>
        </div>
        <div class="mt-2">
          จะบอกว่าผมกับเล็บยาวเร็วมากกกกก จินสามารถทำเล็บเจลต่อเนื่องกันทุกๆ 2
          อาทิตย์โดยที่เล็กไม่เปราะไม่ฉีกเลย ช่างทำเล็บยังทักว่าเล็บแข็งแรงมาก
        </div>
        <div class="mt-2">
          <b>ความคุ้มค่าคุ้มราคา : 10/10</b>
        </div>
        <div class="mt-2">
          การเห็นผลระดับนี้ กับราคาแค่ 790 บาทต่อเดือน
          คือคุ้มกว่านี้ไม่มีอีกแล้วราคาดีตอบโจทย์ทุกเพศทุกวัย นักศึกษาก็ทานได้
          10 10 10 ค่ะ
        </div>
        <div class="mt-4">
          <b>สรุปคะแนนโดยรวม : 9/10</b>
        </div>
        <div class="mt-4">
          จินแนะนำ “กลอรี่” สำหรับคนที่เริ่มหาอาหารผิวเพื่อดูแลตัวเอง
          รวมถึงคนที่มีปัญหาสิว รักษาที่ไหนไม่หายมาตายที่นี่จริงๆ
        </div>
        <div class="mt-2">
          ผลิตภัณฑ์กลอรี่
          มีสารสกัดที่มีชื่อเสียงในเรื่องการดูแลผิวทั้งเสริมสร้างความแข็งแรงให้กับผิว
          และเติมส่วนที่ร่างกายต้องการ
          เพิ่มประสิทธิภาพในการดูแลปัญหาผิวทำให้ผิวและดูสุขภาพดี มีออร่า
        </div>
        <div class="mt-2">
          อย่างไรก็ตามการดูแลตัวเองด้วยการทานอาหารที่ดีมีประโยชน์
          การออกกำลังกายอย่างสม่ำเสมอและเวลาเป็นส่วนสำคัญที่จะช่วยให้คุณมีสุขภาพที่ดี
          และชีวิตที่ยืนยาว และอย่างที่จินบอกไป
          ผลิตภัณฑ์เสริมอาหารช่วยให้คุณได้รับสารอาหารที่ร่างกายต้องการในแต่ละวันเท่านั้น
          ไม่สามารถทดแทนอาหาร หรือมีส่วนช่วยรักษาโรคแต่อย่างใด
        </div>
        <div class="mt-2">
          แต่แน่นอนถ้าคุณไม่สามารถหลีกเลี่ยงแสงแดด แสง UV
          สาเหตุความเสื่อมของคอลลาเจนในผิว ดื่มน้ำวันละ 2 ลิตร
          หรือสามารถกินมะเขือเทศที่ปลอดสารพิษวันละ 34 ลูก
        </div>
        <div class="mt-2">
          กลอรี่คอลลาเจนกับกลอรี่วิตามินซีก็เป็นตัวเลือกที่ช่วยคุณไม่น้อยเลยล่ะค่ะ
          :)
        </div>
      </div>
    ),
    relateProducts: ['GLRSET1', 'GLRCL1', 'GLRVC1'],
  };
};
