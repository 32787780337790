import { v4 as uuidv4 } from 'uuid';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { gtmDataLayer } from '../lib/tag-manager';
import { config } from '../config';
import { isPwa } from '../lib/device';

export const initialAppVersion = () => {
  config().general.appVersion === localStorage.getItem('appVersion') ||
    localStorage.clear();
  localStorage.setItem('appVersion', config().general.appVersion);
};

export const initialUserId = () => {
  const userId =
    localStorage.getItem('userId') == null
      ? uuidv4()
      : localStorage.getItem('userId');

  localStorage.setItem('userId', userId);
  return userId;
};

export const initialUserIp = () => {
  const publicIp = require('public-ip');
  return (async () => {
    try {
      const ip = await publicIp.v4();
      localStorage.setItem('ip', ip);
      return ip;
    } catch (err) {
      const ip = await publicIp.v6();
      localStorage.setItem('ip', ip);
      return ip;
    }
  })();
};

export const initialFingerprint = () => {
  return (async () => {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    localStorage.setItem('fp', result.visitorId);
    return fp;
  })();
};

export const initialSessionStartTime = () => {
  const sessionStartTime = +new Date();
  localStorage.setItem('sessionStartTime', sessionStartTime);
  return sessionStartTime;
};

export const initialSessionTotal = () => {
  const sessionTotal =
    localStorage.getItem('sessionTotal') == null
      ? 1
      : +localStorage.getItem('sessionTotal') + 1;

  localStorage.setItem('sessionTotal', sessionTotal);
  return sessionTotal;
};

export const initialLandingPage = () => {
  const data = {
    url: window.location.href,
    timestamp: +new Date(),
  };

  const landingPage =
    localStorage.getItem('landingPage') == null
      ? []
      : JSON.parse(localStorage.getItem('landingPage'));
  landingPage.push(data);

  localStorage.setItem('landingPage', JSON.stringify(landingPage));
  localStorage.getItem('firstVisitPage') !== null ||
    localStorage.setItem('firstVisitPage', window.location.href);
  return landingPage;
};

export const getBasicInfo = () => {
  const shippingAddress =
    localStorage.getItem('shippingAddress') !== null
      ? JSON.parse(localStorage.getItem('shippingAddress'))
      : { name: '', address: '', tel: '', email: '', company: '', taxId: '' };

  const orderTotal =
    localStorage.getItem('orderTotal') !== null
      ? localStorage.getItem('orderTotal')
      : 0;

  const orderTotalPrice =
    localStorage.getItem('orderTotalPrice') !== null
      ? localStorage.getItem('orderTotalPrice')
      : 0;

  const orderLastOrderId =
    localStorage.getItem('order') !== null
      ? JSON.parse(localStorage.getItem('order')).orderNumber
      : 0;

  const landingPage = JSON.parse(localStorage.getItem('landingPage'));

  const data = {
    userId: localStorage.getItem('userId'),
    fp: localStorage.getItem('fp'),
    ip: localStorage.getItem('ip'),
    domain: config().general.siteUrl,
    subId: localStorage.getItem('subId'),
    userAgent: navigator.userAgent,
    sessionTotal: +localStorage.getItem('sessionTotal'),
    sessionStartTime: +localStorage.getItem('sessionStartTime'),
    firstVisitPage: landingPage[0].url,
    landingPage: landingPage[landingPage.length - 1].url,
    page: window.location.href,
    referrer: document.referrer,
    shippingName: shippingAddress.name,
    shippingEmail: shippingAddress.email,
    shippingAddress: shippingAddress.address,
    shippingTel: shippingAddress.tel,
    orderTotal: +orderTotal,
    orderTotalValue: +orderTotalPrice,
    orderLastOrderId: +orderLastOrderId,
    cookie: document.cookie,
    cart: localStorage.getItem('cart'),
    order: localStorage.getItem('order'),
    orders: localStorage.getItem('orders'),
    agent: localStorage.getItem('agent'),
  };

  return data;
};

export const checkIsInstallPwa = () => {
  const logInstall = () => {
    gtmDataLayer({ event: 'installApp' });
    localStorage.setItem('installApp', true);
  };

  isPwa() && localStorage.getItem('installApp') !== true && logInstall();

  return isPwa();
};

export const getSubId = () => {
  const subIdInUrl = new URL(location.href).searchParams.get('subId');
  const subId =
    subIdInUrl !== null ? subIdInUrl : localStorage.getItem('subId');
  localStorage.setItem('subId', subId);

  return subId;
};

export const getAgentId = () => {
  const agentInUrl = new URL(location.href).searchParams.get('agent');
  const agent =
    agentInUrl !== null ? agentInUrl : localStorage.getItem('agent');
  localStorage.setItem('agent', agent);

  return agent;
};

export const initialUser = () => {
  const appVersion = initialAppVersion();
  const userId = initialUserId();
  const ip = initialUserIp();
  const fp = initialFingerprint();
  const sessionStartTime = initialSessionStartTime();
  const sessionTotal = initialSessionTotal();
  const landingPage = initialLandingPage();
  const pwa = checkIsInstallPwa();
  const subId = getSubId();
  const agent = getAgentId();

  gtmDataLayer({
    data: {
      appVersion,
      userId,
      ip: localStorage.getItem('ip'),
      fp: localStorage.getItem('fp'),
      sessionStartTime,
      sessionTotal,
      userAgent: navigator.userAgent,
      cookie: document.cookie,
      domain: config().general.siteUrl,
      landingPage: window.location.href,
      pwa,
      subId,
      agent,
    },
  });
};
