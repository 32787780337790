import { Link } from 'preact-router/match';

export const faq = () => {
  return {
    product: [
      {
        question: <div>สินค้ามีอย. หรือไม่</div>,
        answer: (
          <div>
            <p>เลขที่ใบรับจดแจ้ง อย.</p>
            <p class="mt-2">
              กลอรี่คอลลาเจน: 10-1-20960-5-0093
              <br />
              กลอรี่โทเมโท่วิตซี: 10-1-20960-5-0112
              <br />
              กลอรี่สครับ: 10-1-63000-3-2598
              <br />
              กลอรี่คอลลาเจนบอดี้เซรั่ม: 11-1-64000-0-2539
            </p>
          </div>
        ),
      },
      {
        question: <div>หยุดทานแล้วจะมีผลอะไรไหม</div>,
        answer: (
          <p>ไม่มีผลเสีย ไม่กลับมาดำคล้ำในทันที แต่ต้องดูแลตัวเองร่วมด้วย</p>
        ),
      },
      {
        question: <div>ทานนานแค่ไหนถึงจะเริ่มเห็นผล</div>,
        answer: (
          <p>
            ส่วนใหญ่ลูกค้าทานไม่ถึง 2 สัปดาห์ก็เริ่มเห็นผลการเปลี่ยนแปลง
            ต้องการเห็นผลเร็ว แนะนำบูสคอลลาเจน เช้า 2 แคปซูล + ก่อนนอน 2 แคปซูล
          </p>
        ),
      },
      {
        question: <div>ทานคู่กันดียังไง</div>,
        answer: (
          <p>
            คอลลาเจนช่วยให้ผิวใส ดูแลผิว ผม เล็บและกระดูก วิตซีมะเขือเทศ
            เพิ่มประสิทธิภาพในการดูดซึมคอลลาเจนเข้าสู่ร่างกายได้ดียิ่งขึ้น
            ตัวเร่งให้ผิวขาวไวขึ้น
          </p>
        ),
      },
      {
        question: <div>คอลลาเจนและมะเขือเทศวิตซี 1 ซองมีกี่แคปซูล</div>,
        answer: <p>1 ซอง มี 60 แคปซูล ทานได้ 1 เดือน</p>,
      },
      {
        question: <div>ใครที่ไม่แนะนำให้ทาน</div>,
        answer: (
          <p>
            คุณแม่ตั้งครรภ์ ผู้ป่วยที่เป็นโรคเกี่ยวกับไต
            ต้องปรึกษาแพทย์ก่อนรับประทาน
          </p>
        ),
      },
    ],
    shipping: [
      {
        question: <div>ส่งต่างประเทศได้ไหม</div>,
        answer: (
          <p>
            ส่งได้ หากคุณต้องการส่งต่างประเทศ กรุณาติดต่อแอดมินทางไลน์
            เพื่อเช็คค่าขนส่ง
          </p>
        ),
      },
      {
        question: <div>ใช้เวลาจัดส่งกี่วัน</div>,
        answer: (
          <p>
            ระยะเวลาจัดเตรียมสินค้าประมาณ 1-2 วันทำการ และระยะเวลาขนส่งประมาณ 2
            วันทำการ ดังนั้นโดยปกติคุณจะได้รับสินค้าภายในเวลา 2-5 วันทำการ
            เว้นแต่ช่วงวันหยุดยาว ที่อาจได้รับสินค้าช้าเกินกว่ากำหนด
          </p>
        ),
      },
    ],
    refund: [
      {
        question: <div>ถ้าไม่พึงพอใจสามารถคืนสินค้าได้ไหม</div>,
        answer: (
          <p>
            หากคุณไม่พอใจ สามารถขอคืนเงินได้ภายใน 30 วัน
            ทั้งนี้เป็นไปตามเงื่อนไข{' '}
            <Link
              href="/return-policy"
              class="font-bold text-primary hover:text-primaryLight"
            >
              การคืนเงินและการคืนสินค้า
            </Link>
          </p>
        ),
      },
    ],
    order: [
      {
        question: <div>ชำระเงินทางไหนได้บ้าง ?</div>,
        answer: (
          <p>
            คุณสามารถชำระเงินได้ทางโอนเงินผ่านธนาคาร เก็บเงินปลายทาง
            และชำระผ่านบัตรเครดิต
          </p>
        ),
      },
      {
        question: <div>สามารถสั่งซื้อทางโทรศัพท์หรือทางไลน์ได้ไหม ?</div>,
        answer: (
          <p>
            หากคุณต้องการสั่งซื้อทางโทรศัพท์ กรุณาติดต่อ 088-980-8497 หรือ
            ทางไลน์
          </p>
        ),
      },
      {
        question: <div>หากต้องการสั่งซื้อจำนวนมาก มีส่วนลดไหม ?</div>,
        answer: (
          <p>
            หากคุณต้องการสั่งซื้อเป็นจำนวนมาก หรือซื้อในเรทตัวแทนจำหน่าย
            กรุณาติดต่อแอดมินทางไลน์ เพื่อเปิดบิลตัวแทนจำหน่าย
          </p>
        ),
      },
    ],
  };
};
