import { LazyImage, Video } from '../../components/lazy-image';

export const content003 = () => {
  return {
    url: '/blog/review-glory-superthankky',
    title: 'สูตรผิวขาว เม็ดเดียว ขาวเหมือนฉีด',
    author: 'คุณแต๊ง',
    content: (
      <div>
        <Video url="https://www.youtube.com/watch?v=FQcpUq3x2DM" />
        <p class="mt-4">
          ตอนแรกตั้งใจว่าจะไม่อัดคลิปนี้ แต่ก็อยากรู้ว่ามันดีจริงไหม พูดตรง ๆ นะ
          เราไม่รู้จักสินค้าตัวนี้เลย แบบถ่ายไปก็คงไม่มีใครรู้จักหรอกมั้ง
        </p>
        <p class="mt-2">
          แต๊งรู้จักตัวนี้ เพราะเพื่อนคนนึงที่ไม่ได้เจอมานานมาก
          นางก็ไปทำจมูกมาใหม่ ฉีดหน้ามาใหม่ แต๊งถามว่าเมิงไปฉีดผิวมาด้วยหรอ
          นางบอกไม่ได้ฉีด แล้วผิวมันดีแบบดุจดั่งปาฏิหารย์ แบบผิวมันดีขึ้นมาก
        </p>
        <p class="mt-2">
          เลยถามไปว่ากินตัวไหนอยู่ แลกเปลี่ยนความรู้กัน มันก็บอกตัวนี้กินดีจริง
          ๆ คือแบบผิวมันขาวใส ขาวแบบเห็นเส้นเลือด
          เราเลยถามไปว่ามีสารอันตรายเปล่า นางบอกว่า{' '}
          <i>มันอ่อนโยนมาก เป็นคอลลาเจนเพียว 100% </i>
          ไม่ใส่สารแต่งกลิ่น ไม่ใส่สารเร่งขาว มาจากธรรมชาติ 100%
          เราเลยตัดสินใจลองกินดู
        </p>
        <div class="my-4">
          <LazyImage src="/glorycollagen/review-thankky-4.jpg" />
        </div>
        <div class="mt-1 text-center text-sm italic">
          คอลลาเจนเพียว เพียว นำเข้าจากญี่ปุ่น
        </div>
        <p class="mt-2">
          แบบถ้าตัวไหนดีเราก็อยากลอง แต่บอกก่อนเลยนะ
          ตอนแรกไม่มีปัญหาเรื่องผิวเลย รู้สึกพอใจผิวตัวเองมากแล้ว แต่!
          เมื่อไม่กี่อาทิตย์ที่ผ่านมา แต๊งไปต่างจังหวัด ไปคาเฟ่ ไปโดนแดด
          คือร้อนมากจริง ๆ และทุกคนบนโลกนี้ที่ไม่ได้ขาวโดยกรรมพันธุ์
          สามารถกลับมาคล้ำได้เหมือนเดิม ถ้าคุณไม่ดูแลผิวอย่างต่อเนื่อง
          หรือออกไปตากแดดโดยไม่ดูแล
        </p>
        <p class="mt-2">
          อะมาดู แต๊งกินได้ประมาณ 8 วันแล้วนะครับ สัมผัสได้ตอนล้างหน้า ถูสบู่
          แบบมันลื่นน มันเห็นได้ชัดว่าดีขึ้น แต๊งเลยถ่ายคลิปดีกว่า
          ให้ทุกคนเป็นสักขีพยาน ให้เห็นเลยว่า มันขาวขึ้นจริง ๆ
        </p>
        <p class="mt-2">
          คือบอกก่อนเลยนะ ว่าไม่ได้คลั่งขาวขนาดนั้น เรารู้สึกว่า
          ถ้าอัดคลิปไว้ปุ้ปอ่าถ้ามันดีขึ้นมา
          และมีความเชื่อว่าตัวไหนที่กินแล้วดีอ่ะ วันนึงมันจะดัง
          งั้นเราก็กินเองแล้ว ถ่ายคลิปไว้ก่อนเลย เผื่อว่าคนที่กิน{' '}
          <strong>glory </strong>มาเห็น ว่ามันดังปุ้ป อ้าา แต๊งเคยกิน ฮ่าๆๆ
        </p>
        <div class="my-4">
          <LazyImage src="/glorycollagen/review-thankky-3.jpg" />
        </div>
        <div class="mt-1 text-center text-sm italic">
          ซองสีชมพู ทานหลังตื่นนอน/ซองสีส้ม ทานหลังอาหารเช้า
        </div>
        <p class="mt-2">
          เรามากินต่อวันที่ 9 และจะเก็บไปทั้งหมด 14 วัน
          แล้วเดี๋ยวเรามาสรุปอีกทีวันที่ 14 นะครับ สูตรแต๊ง
          แต๊งกินกลอรี่คอลลาเจน ตอนเช้า 2 เม็ด กินตอนท้องว่าง ตื่นมาแล้วกินเลย
          ตัวนี้ดูดซึมได้ 100% เม็ดจะเป็นสีชมพู
          และเราสามารถเอาผงคอลลาเจนไปชงใส่น้ำกินได้นะ
        </p>
        <p class="mt-2">
          ทุกคนครับแต๊งกินมา 8 วันสิวไม่ขึ้นเลยนะครับ
          วันนี้เหมือนรู้ว่าจะออกกล้องสิวดันขึ้น แต๊งรู้ตัวนะ สิวขึ้นเพราะ
          ใส่แมสเป็นแมสผ้าแล้วค่อนข้างที่จะอบ แต่มาดูต่อไปนะว่าเป็นไง
          เพราะเค้าบอกว่าตัวเนี่ยช่วยเรื่องสิวด้วยนะ แต่ไม่รู้จริงเปล่า
          เพราะเพื่อนแต๊งไม่ได้เป็นสิว แต่นี่เป็นไง !!
        </p>
        <p class="mt-2">
          เพื่อนแต๊งมันบอกว่า คนที่หกล้ม เป็นแผลจากสิว หรือแผลจากการกดสิว
          ตัวนี้ซ่อมแซมผิวเร็วมาก เร็วจริงๆ ไม่เกิน 3 วัน
          มันจะดีขึ้นแบบเห็นได้ชัด ระหว่างที่กิน สิวไม่มีขึ้นนะ
        </p>
        <div class="my-4">
          <LazyImage src="/glorycollagen/review-thankky-1.jpg" />
          <div class="mt-1 text-center text-sm italic">ลดสิว ผิวกระจ่างใส</div>
        </div>
        <p class="mt-2">
          ผ่านไปแล้วประมาณ 12-13 วันนะครับ หน้าแต๊งดูมีความฉ่ำวาว
          มีความผิวสุขภาพดี ผิวดูฟูมาก มีน้ำมีนวล ดูใสๆ และวันนี้นะ
          แต๊งแอบไปซื้อ <i>tomato vit c </i> มานะครับ ไม่รู้ว่ามันดีหรือไม่ดีนะ
          ตัวเนี่ย 1 แคปซูลมีมะเขือเทศ 34 ผล และมีวิตามินซีเข้มข้นถึง 80 เท่า
          ทำให้ผิวแข็งแรงและลดจุดด่างดำ ช่วยปกป้องแสงแดดจากภายใน
          กินคู่กับคอลลาเจน อย่างละ 2 เม็ด ต่อวัน
        </p>
        <p class="mt-2">
          สิ่งที่แต๊งรู้สึกจริงๆ หลังทานไป 14 วัน ผิวหน้าแต๊งดูสุขภาพดีมาก ดูฟู
          ดูผิวฉ่ำวาว ผิวละเอียด ดูใส รูขุมขนดูตื้นขึ้น
          กล้าพูดว่าครั้งเนี่ยแต๊งนอนเร็วขึ้น และใช้ครีมบำรุงปกติทั่วไป
          ไม่ได้ลองตัวไหนเพิ่มนะครับ กินกลอรี่และบำรุงปกติ
          กินอาหารที่มีประโยชน์มากขึ้น ที่สำคัญแขนดูขาวกระจ่างใสขึ้น
          เมื่อเปรียบเทียบกับต้นคลิปที่ถ่ายไว้
        </p>
        <div class="my-4">
          <LazyImage src="/glorycollagen/review-thankky-2.jpg" />
          <div class="mt-1 text-center text-sm italic">ก่อนทานและหลังทาน</div>
        </div>
        <p class="mt-2">
          แต่! ที่อยากจะอวดกว่านั้นก็คือ ในร่างกายเราจะมีความขาวอยู่แล้ว
          และใครที่ขาวอยู่แล้วมันขาวขึ้นจริงๆ โชว์ผิวให้ดู
          ผิวขาวขึ้นจนเห็นเส้นเลือดเลย ส่วนใครที่ตากแดดผิวไหม้ ผิวเสียจากข้างใน
          นอนดึก คือคนนอกนึกจะมีความผิวโทรม ทานู่นทานี่ไม่ติด
          พอกินตัวนี้แล้วผิวมันดูดีขึ้นจริงๆ
        </p>
        <div class="my-4">
          <LazyImage src="/glorycollagen/review-thankky-5.jpg" />
        </div>
        <div class="mt-1 text-center text-sm italic">
          ผิวใส สุขภาพดี มีออร่า
        </div>
        <p class="mt-2">
          ส่วนผสม <i>collagen dipeptide pure 100% </i>{' '}
          เป็นสายพันธุ์ปลาที่ดีที่สุดในโลก นำเข้าจากญี่ปุ่น
          ตัวโมเลกุลจะเล็กเท่าผิวเราเลย ดูดซึมไว ทำให้ผิวแข่็งแรงในระยะยาว
          ไม่ทำให้ผิวกลับมาดำคล้ำง่าย กระตุ้นให้สร้างอิลาสติน ไฮยาลูรอน
          และทำให้รูขุมขนกระชับ ไม่มีสี ไม่มีกลิ่น มีอ.ย. มีวันหมดอายุชัดเจน
          ช่วยปรับโครงสร้างชั้นผิวให้ผิวใสมีออร่า ขาวใสขึ้นไวภายใน 1
          สัปดาห์อย่างธรรมชาติ บอกเลยว่าดี !!
        </p>
        <p class="mt-2">
          เพราะเพื่อนแต๊งไปฉีดผิวขาวมา บอกเลยว่าขาวขึ้นจริง แต่ไวต่อแดดมาก
          ขาวซีดๆ และโดยส่วนตัวนะกินกลอรี่ขาวใสขึ้นจริง
          และมันไม่ได้ขาวออร่าเหมือนฉีดขนาดนั้น นี่ขนาดแต๊งไม่ใช่คนขาวนะ
          แต่ผิวแต๊งดูมีความขาวใสขึ้น
        </p>
        <p class="mt-2">
          ส่วนตัววิตซี 1ซอง มี 60 แคปซูลเหมือนกัน ลองไปหาซื้อกันดูนะทุกคน
          แต๊งคิดว่ามันเป็นความชอบส่วนตัว คอลลาเจนมีหลากหลายมาก
          แต่บางคนถูกกับบางตัว กินแล้วดี แต่แต๊งกินกลอรี่ไป 8 วัน
          ทำไมถึงลุกขึ้นมาถ่ายคลิป ทั้ง ๆ ที่ตอนแรกไม่คิดจะถ่าย
          เพราะเรากินแล้วมันดีมากจริง ๆ
        </p>
      </div>
    ),
    relateProducts: ['GLRSET1', 'GLRCL1', 'GLRVC1'],
  };
};
