import { Link } from 'preact-router/match';

export const ESTHC2 = () => ({
  url: '/product/essentia-hand-cream-2',
  title: 'เอสเซนเทีย แฮนด์ครีม 2 หลอด',
  brand: 'ESSENTIA',
  sku: 'ESTHC2',
  totalSale: 389098,
  price: 450,
  offerPrice: 780,
  images: ['/product/ESTHC1.jpg'],
  product: 'เอสเซนเทีย แฮนด์ครีม 2 หลอด',
  shortDescription: 'แฮนด์ครีมบำรุงผิวมือ ปกป้องจากแบคทีเลีย',
  description: (
    <div class="mb-2 text-baseColor">
      <p>
        Essentia Handcream ผลิตภัณฑ์บำรุงผิวมืออย่างล้ำลึก
        ด้วยเนื้อครีมที่บางเบาไม่เหนียวเหนอะหนะ
        หลอดเดียวทั้งบำรุงผิวมือและปกป้องจากแบคทีเรีย
      </p>
      <p class="mt-2">
        แก้ปัญหาสำหรับผู้ที่แพ้เจลแอลกอฮอล์ บำรุงและช่วยลดอาการมือลอก แห้ง
        เหี่ยว จากการใช้เจลแอลกอฮอล์ ด้วย Nano Silver
        ที่ผ่านการทดสอบจากห้องปฏิบัติการงานบริการวิชาการ ภาควิชาจุลชีววิทยา
        คณะวิทยาศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย ว่าช่วยลดการสะสมของแบคทีเรีย มากถึง
        99.8%
      </p>
      <p class="mt-2">
        พร้อมมอบความชุ่มชื้นให้มือคุณเนียนนุ่ม และปกป้องแบคทีเรีย
      </p>
      <p class="mt-2">
        และเพื่อผลลัพธ์ที่ดีขึ้น ควรใช้เป็นประจำอย่างต่อเนื่อง
        ผิวสวยจากภายในสู่ภายนอกเมื่อใช้ควบคู่กับ{' '}
        <Link
          href="/product/collagen"
          class="font-bold text-primary hover:text-primaryLight"
        >
          Collagen
        </Link>
        {', '}
        <Link
          href="/product/scrub"
          class="font-bold text-primary hover:text-primaryLight"
        >
          Scrub
        </Link>
        {', '}และ{' '}
        <Link
          href="/product/body-serum"
          class="font-bold text-primary hover:text-primaryLight"
        >
          Body Serum
        </Link>
      </p>
      <ul class="list-disc mt-4 ml-10 text-baseColor">
        <li>ลดการสะสมของแบคทีเรียที่มือ</li>
        <li>ลดอาการมือแห้ง มือลอก แห้งเหี่ยว</li>
        <li>บำรุงผิวนุ่ม</li>
        <li>เนื้อครีมบางเบา ไม่เหนียวเนอะนะ</li>
      </ul>
    </div>
  ),
  options: [
    {
      sku: 'ESTHC1',
      title: (
        <div>
          1 หลอด <del>฿390</del>
        </div>
      ),
      price: 229,
    },
    {
      sku: 'ESTHC2',
      title: (
        <div>
          2 หลอด <del>฿780</del>
        </div>
      ),
      price: 450,
    },
    {
      sku: 'ESTHC3',
      title: (
        <div>
          3 หลอด <del>฿1,170</del>
        </div>
      ),
      price: 580,
    },
  ],
  guarantee: {
    title: 'คุณภาพจากกลอรี่',
    description: (
      <div>
        เพราะความสวยสร้างได้
        กลอรี่ผลิตภัณฑ์คุณภาพที่ออกแบบมาเพื่อให้คุณมีผิวสวยใส ไร้สิว
        จากภายในสู่ภายนอก
      </div>
    ),
  },
  ingredients: [
    {
      image: '/ingredient/est-ingredient-1.png',
      title: 'Nano Silver',
      description: (
        <div>
          เทคโนโลยีการสังเคราะห์เงินหรือซิลเวอร์ (Silver)
          ให้มีขนาดเล็กระดับนาโนเมตร คือมีขนาดไม่เกิน 100 นาโนเมตร เทียบเท่ากับ
          DNA ในร่างกายมนุษย์ โลหะเงินสามารถแตกตัวในระดับอนุภาคนาโน
          จากนั้นอนุภาคซิลเวอร์จะไปเกาะที่ผนังของเชื้อแบคทีเรียทำให้แบคทีเรียถูกทำลายและตายในที่สุด
          Nano Silver ที่ผ่านการทดสอบจากห้องปฏิบัติการงานบริการวิชาการ
          ภาควิชาจุลชีววิทยา คณะวิทยาศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย
          ว่าช่วยลดการสะสมของแบคทีเรีย มากถึง 99.8%
        </div>
      ),
    },
    {
      image: '/ingredient/est-ingredient-2.png',
      title: 'Citrofresh 14P',
      description: (
        <div>
          ยับยั้งเชื้อแบคทีเรียได้ดี มีสาร Bioflavonoids
          ออกฤทธิ์โดยตรงกับเชื้อแบคทีเรีย
          หยุดยั้งการเจริญเติบโตทำให้เชื้อตายอย่างถาวร
        </div>
      ),
    },
    {
      image: '/ingredient/est-ingredient-3.png',
      title: 'D-Panthenol',
      description: (
        <div>
          เป็นสารตั้งต้นของวิตามินบี 5 ทำหน้าที่เป็น Moistures
          ให้ความชุ่มชื้นผิวและกักเก็บความชุ่มชื้น
          ช่วยสมานผิวจากอาการไหม้เกรียมจากแสงแดด ลดการอักเสบและระคายเคืองผิว
        </div>
      ),
    },
    {
      image: '/ingredient/est-ingredient-4.png',
      title: 'Vitamin E Acetate ',
      description: (
        <div>
          ปกป้องผิวจากอนุมูลอิสระและริ้วรอยก่อนวัยซึ่งมักเกิดจาก รังสี UV
          ลดรอยเหี่ยวย่น แตกลาย ให้ความชุ่มชื้นและลดความหยาบกร้านของผิว
        </div>
      ),
    },
    {
      image: '/ingredient/est-ingredient-5.png',
      title: 'Shea Butter',
      description: (
        <div>
          คืนความชุ่มชื้นให้ผิว ฟื้นฟูเซลล์ผิวที่ถูกทำลาย มี Cinnamic acid
          ที่ช่วยปกป้องผิวจากรังสี UV รักษาความชุ่มชื้น คืนความยืดหยุ่นให้กับผิว
        </div>
      ),
    },
    {
      image: '/ingredient/est-ingredient-6.png',
      title: 'Collagen',
      description: (
        <div>
          ช่วยบำรุงสุขภาพของผิวหนัง เพิ่มความยืดหยุ่นของผิว ความชุ่มชื้น
          การสูญเสียน้ำทางผิวหนัง ทำให้ผิวหนังกระชับ เรียบเนียน
          เป็นวิธีดูแลผิวหน้าเป็นอย่างดี
          และช่วยให้เซลล์ผิวหนังผลัดเซลล์และซ่อมแซมเซลล์ได้ตามปกติ
        </div>
      ),
    },
    {
      image: '/ingredient/est-ingredient-7.png',
      title: 'Hydrolyzed Silk',
      description: (
        <div>
          ประกอบไปด้วยกรดอะมิโนหลายชนิด มีความสามารถในการจับ กับโปรตีนอื่นได้ดี
          จึงจับกับโปรตีนเคอราตินในผิวหนังและเส้นผม เกิดเป็นฟิล์มเคลือบ
          ป้องกันการสูญเสียความชุ่มชื้นได้ดี
          สามารถเข้าถึงชั้นในของเส้นผมจึงให้คุณค่าบำรุง จากภายใน
          และผสานเส้นผมชั้นนอกโดยการเกิดเป็นฟิล์มปกป้องจากมลภาวะภายนอก
        </div>
      ),
    },
  ],
  ingredientSummary: (
    <div>
      <ul>
        <li>Nano Silver</li>
        <li>Citrofresh 14P</li>
        <li>D-Panthenol</li>
        <li>Vitamin E Acetate</li>
        <li>Shea Butter </li>
        <li>Collagen</li>
        <li>Hydrolyzed Silk</li>
      </ul>
    </div>
  ),
  fdaId: '10-1-20960-5-0112',
  weight: '30 มล.',
  faqs: [
    {
      question: 'เอสเซนเทีย 1 หลอด มีปริมาณเท่าไร',
      answer: <div>30 มล ใช้ได้ 2 อาทิตย์</div>,
    },
  ],
  videoContent: null,
  disclaimer: (
    <div>
      1. ใช้สำหรับภายนอกเท่านั้น
      <br />
      2. หากเกิดการระคายเคือง ควรหยุดใช้และปรึกษาแพทย์หรือเภสัชกร
    </div>
  ),
  reviews: [
    {
      image: '/essentia/esthc-review-1.jpg',
      review: (
        <div>
          เป็นแฮนด์ครีมอีกหลอดที่ใช้แล้วติดใจมาก มือหอมหรูสุดๆ
          และบำรุงให้ผิวชุ่มชื้นด้วยโปรตีนรังไหม เนื้อซึมดีไม่เนียว
          นอกจากนี้ยังช่วยลดการสะสมของแบคทีเรีย ทั้งบำรุงและปกป้องครบในหลอดนี้
          Essentia Brand ใช้ดีบอกต่อ
        </div>
      ),
      name: 'น้ำขิง',
      product: 'ESTHC1',
    },
    {
      image: '/essentia/esthc-review-2.jpg',
      review: (
        <div>
          ช่วงนี้ล้างมือบ่อย มือแห้ง แต่ก็ยังมือนุ่ม เลยแอบกระซิบบอก
          ว่าไปเจอแฮนด์ครีม ตัวเด็ดของ Essentia Brand ที่มีสาร Nano Silver
          ที่ลดการสะสมแบคทีเรีย ทำให้ไม่ต้องใช้แอลกอฮอล์ สาเหตุให้มือแห้ง
          แถมยังให้ความชุ่มชื้นอีกด้วย จัดเป็นไอเท็มเลิฟ
          หมดยุคล้างมือบ่อยจนมือแห้งแล้วจ้า ใช้ดีบอกต่อ
        </div>
      ),
      name: 'คุณอินดี้',
      product: 'ESTHC1',
    },
    {
      image: '/essentia/esthc-review-3.jpg',
      review: (
        <div>
          มือแห้งเพราะใช้เจลแอลกอฮอล์บ่อยต้องลอง แฮนด์ครีม Essentia
          ช่วยเพิ่มความชุ่มชื้น ให้ผิวไม่เหนอะหนะซึมง่าย แถมกลิ่นหอมละมุนมาก
          มีนาโนซิลเวอร์ตัวข่วยลดการสะสมของแบคทีเรียด้วยนะ
          อยากให้ลองเพราะน้องราคาน่ารัก พกไว้อุ่นใจ อารมณ์แบบบำรุง +
          ปกป้องในหลอดเดียวอ่ะ Essentia Brand ใช้ดีบอกต่อ
        </div>
      ),
      name: 'คุณออฟฟี่',
      product: 'ESTHC1',
    },
    {
      image: '/essentia/esthc-review-5.jpg',
      review: (
        <div>
          ช่วงนี้นอกจากแอลกอฮอล์ฉีดมือ คือก็พกเจ้านี้มาตลอด แก้มือแห้ง
          แถมทำให้มือแถม Hand Cream Essentia ใช้ละมือนุ่ม กลิ่นหอมติดผิว
          ที่สำคัญมีสารนาโนซิลเวอร์ ลดการสะสมของแบคทีเรียอีก นางเริ่ดนะ
          อยากป้ายยารัวๆ สอยมา โปร 1 แถม 1 โครตคุ้ม Essentia Brand Hand Cream
          ใช้ดีบอกต่อ
        </div>
      ),
      name: 'คุณใหม่',
      product: 'ESTHC1',
    },
    {
      image: '/essentia/esthc-review-6.jpg',
      review: (
        <div>
          ล้างมือบ่อย แล้วมือแห้งมาก ต้องการตัวช่วยด่วน เลยต้องมี Hand Cream
          ติดตัวตลอดเวลาเลย ใช้่ของ Essentia Brand ล้างมือเสร็จทาปั้บเลย
          ลดการสะสมของแบคทีเรียด้วย เนื้อครีมไม่เหนียว ให้ความชุ่มชื้น
          พร้อมกลิ่นหอมสุดๆ ได้ทั้งบำรุงและปกป้องไปเลย ใช้ดีบอกต่อ
        </div>
      ),
      name: 'คุณนินิว',
      product: 'ESTHC1',
    },
    {
      image: '/essentia/esthc-review-7.jpg',
      review: (
        <div>
          เราเป็นคนที่ชอบเอามือมาจับหน้า จับตามาก แต่ไม่ค่อยชอบล้างมือ เลยหา
          Hand Cream มาใช้ เจอตัวนี้ของ Essentia Brand ช่วยบำรุงและปกป้องดีมั้ก
          ลดการสะสมของแบคทีเรีย และยังบำรุงมือให้นุ่ม ชุ่มชื้น มีกลิ่นหอม ดีมาก
          ป้ายยาแรงๆ เลย
        </div>
      ),
      name: 'คุณออนนี่',
      product: 'ESTHC1',
    },
    {
      image: '/essentia/esthc-review-8.jpg',
      review: (
        <div>
          ติดเฉยเลย Handcream ตัวนี้อะ คือแบบปกติไม่คิดจะใช้เลยนะ พอหลังๆ
          มือมันแห้งจริงจนต้องหาบำรุงแล้ว Essentia Brand
          ตั้งแต่ใช้มาก็คือมือนุ่ม แถมหอมมาก ทาทีไรมีแต่คนถาม และน้องยังมีสาร
          Nano Silver ที่ลดการสะสมแบคทีเรียด้วยอะ ป้องกัน x 2 ไปเล้ยย
          พกพาง่ายใครอยากลองลองเลย
        </div>
      ),
      name: 'คุณปัน',
      product: 'ESTHC1',
    },
    {
      image: '/essentia/esthc-review-9.jpg',
      review: (
        <div>
          สิ่งที่ขาดไม่ได้ช่วงนี้ก็ Handcream เนี่ยแหละ Must have item
          ชาวทำงานในรพ. ล้างมือบ่อยจนแห้ง
          หลังล้างมือเลยต้องทาแฮนด์ครีมเติมความชุ่มชื้นต่อ เราใช้ของ
          EssentiaBrand นะ มือนุ่มมาก กลิ่นหอมอ่อนๆ
          เห็นเค้าบอกช่วยลดการสะสมของแบคทีเรียด้วย ใช้ดีบอกต่อ
        </div>
      ),
      name: 'คุณเพลง',
      product: 'ESTHC1',
    },
    {
      image: '/essentia/esthc-review-11.jpg',
      review: (
        <div>
          พกตลอด Handcream ตั้งแต่มือแห้งเพราะใช้แอลกอฮอล์บ่อยๆ อันนี้ดี
          ทาแล้วมือนุ่มโครตหอม ของ Essentia Brand แถมยังมีสาร Nano Silver
          ที่ช่วยลดการสะสมแบคทีเรียอีกนะ ยิ่งใช้มือยิ่งนุ่ม และได้บำรุง + ปกป้อง
          แบบ 2 ชั้นอะ ยุคนี้มันต้องงี้แล้วสินะ
        </div>
      ),
      name: 'คุณนุ่น',
      product: 'ESTHC1',
    },
    {
      image: '/essentia/esthc-review-12.jpg',
      review: (
        <div>
          ไอเทมบำรุงผิวมือ Essentia Brand อันนี้ได้มากับโปร 1 แถม 1
          ใครฉีดแอลจนมือแห้ง หา HandCream มาลองจ้า ตัวนี้บำรุงและปกป้องครบมาก
          เนื้อบางเบาไม่เหนียว บำรุงมือด้วยโปรตีนรังไหม + ลดการสะสมของแบคทีเรีย
          แถมมีกลิ่นหอมติดมือด้วย ใช้จริงรีวิวจริง
        </div>
      ),
      name: 'คุณณภัทร',
      product: 'ESTHC1',
    },
  ],
  productsIncluded: null,
  relateProducts: [
    'GLRSET1',
    'GLRSET6',
    'GLRSR1',
    'GLRCL1',
    'GLRSC1',
    'GLRBS1',
    'GLRBO1',
  ],
  upsellProducts: ['GLRSC1', 'GLRBS1'],
});
