import { content001 } from './blog/content-001';
import { content002 } from './blog/content-002';
import { content003 } from './blog/content-003';
import { content004 } from './blog/content-004';
import { content005 } from './blog/content-005';

export const blog = () => {
  const data = [content003(), content004(), content005()];

  return data;
};
