import { memo, PureComponent } from 'preact/compat';
import { GLRBS1 } from './product/GLRBS1';
import { GLRCL1 } from './product/GLRCL1';
import { GLRCL2 } from './product/GLRCL2';
import { GLRCL3 } from './product/GLRCL3';
import { GLRCL4 } from './product/GLRCL4';
import { GLRBO1 } from './product/GLRBO1';
import { GLRBO2 } from './product/GLRBO2';
import { GLRBO3 } from './product/GLRBO3';
import { GLRBO4 } from './product/GLRBO4';
import { GLRBO5 } from './product/GLRBO5';
import { GLRSC1 } from './product/GLRSC1';
import { GLRSET1 } from './product/GLRSET1';
import { GLRSET6 } from './product/GLRSET6';
import { GLRSET8 } from './product/GLRSET8';
import { GLRSET9 } from './product/GLRSET9';
import { GLRSET10 } from './product/GLRSET10';
import { GLRSET11 } from './product/GLRSET11';
import { GLRSET12 } from './product/GLRSET12';
import { GLRSET21 } from './product/GLRSET21';
import { GLRVC1 } from './product/GLRVC1';
import { GLRVC2 } from './product/GLRVC2';
import { GLRVC3 } from './product/GLRVC3';
import { GLRVC4 } from './product/GLRVC4';
import { GLRVG1 } from './product/GLRVG1';
import { GLRVG2 } from './product/GLRVG2';
import { GLRVG3 } from './product/GLRVG3';
import { GLRSR1 } from './product/GLRSR1';
import { GLRSR2 } from './product/GLRSR2';
import { GLRSR3 } from './product/GLRSR3';
import { GLRSR4 } from './product/GLRSR4';
import { GLRSR5 } from './product/GLRSR5';
import { ESTHC1 } from './product/ESTHC1';
import { ESTHC2 } from './product/ESTHC2';
import { ESTHC3 } from './product/ESTHC3';

export const product = () => {
  const data = [
    GLRBS1(),
    GLRCL1(),
    GLRCL2(),
    GLRCL3(),
    GLRCL4(),
    GLRBO1(),
    GLRBO2(),
    GLRBO3(),
    GLRBO4(),
    GLRBO5(),
    GLRSC1(),
    GLRSET1(),
    GLRSET6(),
    GLRSET8(),
    GLRSET9(),
    GLRSET10(),
    GLRSET11(),
    GLRSET12(),
    GLRSET21(),
    GLRVC1(),
    GLRVC2(),
    GLRVC3(),
    GLRVC4(),
    GLRVG1(),
    GLRVG2(),
    GLRVG3(),
    GLRSR1(),
    GLRSR2(),
    GLRSR3(),
    GLRSR4(),
    GLRSR5(),
    ESTHC1(),
    ESTHC2(),
    ESTHC3(),
  ];

  return data;
};
