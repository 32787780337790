import { Link } from 'preact-router/match';

export const GLRSET6 = () => {
  return {
    url: '/product/set-6',
    title: 'ชุดรักผิว 3 in 1',
    brand: 'GLORY',
    sku: 'GLRSET6',
    totalSale: 56021,
    price: 1080,
    offerPrice: 1430,
    images: ['/product/GLRSETCL1VC1SC1.jpg'],
    product: 'ชุดรักผิว 3 in 1: Collagen 1 + Vit C 1 + Scrub 1',
    shortDescription: 'ผิวใส ลดสิว กำจัดเซลล์ผิวที่ตายแล้ว',
    description: (
      <div class="mb-2 text-baseColor">
        <p>
          ชุดรักผิว 3 in 1 เหมาะสำหรับคนที่ต้องการดูแลผิวทั้งภายนอกและภายใน
          คอลลาเจนช่วยให้ผิวใส นุ่มลื่น, โทเมโท่วิตซีช่วยลดสิว ลดรอยแดง รอยดำ
          และสครับช่วยเปิดผิวใสกำจัดเซลล์ผิวที่ตายแล้ว ชุดนี้ใช้ได้ 1 เดือน
        </p>
        <p class="mt-2">ในชุดนี้ประกอบด้วย</p>
        <ul class="list-disc my-2 ml-10 text-baseColor">
          <li>Glory Collagen 1 ซอง</li>
          <li>Glory Tomato Vit C 1 ซอง</li>
          <li>Glory Scrub 1 หลอด</li>
        </ul>
      </div>
    ),
    options: null,
    guarantee: {
      title: 'คุณภาพจากกลอรี่',
      description: (
        <div>
          เพราะความสวยสร้างได้
          กลอรี่ผลิตภัณฑ์คุณภาพที่ออกแบบมาเพื่อให้คุณมีผิวสวยใส ไร้สิว
          จากภายในสู่ภายนอก
        </div>
      ),
    },
    ingredients: null,
    fdaId: null,
    weight: null,
    faqs: [
      {
        question: 'วิธีใช้ให้เห็นผลลัพธ์',
        answer: (
          <div class="grid md:grid-cols-2">
            <div class="mb-4">
              <span class="font-bold">ตอนเช้า</span>
              <ul class="list-decimal mt-2 ml-6">
                <li>
                  ทาน Glory Collagen คู่กับ Tomato Vit C อย่างละ 1 แคปซูล
                  ก่อนอาหารเช้า
                </li>
              </ul>
            </div>
            <div>
              <span class="font-bold">ตอนกลางคืน</span>
              <ul class="list-decimal mt-2 ml-6">
                <li>
                  ทาสครับบริเวณ แขน ขา ข้อศอก ตาตุ่ม ถูวนให้ทั่ว 2-3 นาที
                  จากนั้นอาบน้ำล้างตัวให้สะอาด
                </li>
                <li>
                  ทาน Glory Collagen คู่กับ Tomato Vit C อย่างละ 1 แคปซูล
                  ก่อนเข้านอน
                </li>
              </ul>
            </div>
          </div>
        ),
      },
    ],
    videoContent: {
      title: 'สิวหาย ออร่ากระจาย',
      subtitle:
        'หมดปัญหาสิว ผิวโกลว์สุด เคล็ดลับทำให้หน้าเนียน ผิวดี ด้วย Glory Collagen',
      url: 'https://www.youtube.com/watch?v=rDbLAJNC4pU',
    },
    disclaimer: null,
    reviews: null,
    productsIncluded: ['GLRCL1', 'GLRVC1', 'GLRSC1'],
    relateProducts: [
      'GLRSR1',
      'GLRSET1',
      'GLRCL1',
      'GLRVC1',
      'GLRSC1',
      'GLRBS1',
    ],
    upsellProducts: ['GLRSC1', 'GLRBS1'],
  };
};
