import { Component } from 'preact';
import { Router } from 'preact-router';
import { useState } from 'preact/hooks';
import AsyncRoute from 'preact-async-route';
import Blank from 'async!./routes/_blank';
import NavBar from 'async!./components/navbar';
import CookiePopup from 'async!./components/cookie-popup';
import Footer from 'async!./components/footer';
import { config } from './config';
import { getSkus } from './lib/sku';
export default class App extends Component {
  handleRoute = (e) => {
    this.currentUrl = e.url;

    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0 });
    }
  };

  Home = () => {
    return import('async!./routes/home').then((module) => module.default);
  };

  Shop = () => {
    return import('async!./routes/shop').then((module) => module.default);
  };

  Checkout = () => {
    return import('async!./routes/checkout').then((module) => module.default);
  };

  Product = () => {
    return import('async!./routes/product').then((module) => module.default);
  };

  Blog = () => {
    return import('async!./routes/blog').then((module) => module.default);
  };

  Order = () => {
    return import('async!./routes/order').then((module) => module.default);
  };

  OrderHistory = () => {
    return import('async!./routes/order-history').then(
      (module) => module.default
    );
  };

  Review = () => {
    return import('async!./routes/review').then((module) => module.default);
  };

  Faq = async () => {
    return import('async!./routes/faq').then((module) => module.default);
  };

  About = () => {
    return import('async!./routes/about').then((module) => module.default);
  };

  Safety = () => {
    return import('async!./routes/safety').then((module) => module.default);
  };

  Contact = () => {
    return import('async!./routes/contact').then((module) => module.default);
  };

  Agent = () => {
    return import('async!./routes/agent').then((module) => module.default);
  };

  CartBulk = () => {
    return import('async!./routes/cart-bulk').then((module) => module.default);
  };

  PrivacyPolicy = () => {
    return import('async!./routes/privacy-policy').then(
      (module) => module.default
    );
  };

  ReturnPolicy = () => {
    return import('async!./routes/return-policy').then(
      (module) => module.default
    );
  };

  TermsOfUse = () => {
    return import('async!./routes/terms-of-use').then(
      (module) => module.default
    );
  };

  Affiliate = () => {
    return import('async!./routes/affiliate').then((module) => module.default);
  };

  Login = () => {
    return import('async!./routes/login').then((module) => module.default);
  };

  render(props) {
    const [cartNotification, setCartNotification] = useState(0);
    const products = getSkus();
    const configs = config();

    return (
      <div>
        <NavBar
          cartPage={
            this.props.cartPage !== undefined ? this.props.cartPage : 'page'
          }
          cartNotification={cartNotification}
          config={configs}
        />
        <Router onChange={this.handleRoute}>
          <AsyncRoute path="/" getComponent={this.Home} config={configs} />
          <AsyncRoute
            path="/shop"
            getComponent={this.Shop}
            cartNotificationCallback={(q) => setCartNotification(q)}
            products={products}
            config={configs}
          />
          <AsyncRoute
            path="/checkout/:cartId"
            getComponent={this.Checkout}
            cartNotificationCallback={(q) => setCartNotification(q)}
            config={configs}
            products={products}
          />
          <AsyncRoute
            path="/checkout"
            getComponent={this.Checkout}
            cartNotificationCallback={(q) => setCartNotification(q)}
            config={configs}
            products={products}
          />
          {configs.product
            .filter((r) => r.url !== null)
            .map((r) => (
              <AsyncRoute
                path={r.url}
                sku={r.sku}
                getComponent={this.Product}
                cartNotificationCallback={(q) => setCartNotification(q)}
                products={products}
                config={configs}
              />
            ))}
          {configs.blog
            .filter((r) => r.url !== null)
            .map((r) => (
              <AsyncRoute
                path={r.url}
                getComponent={this.Blog}
                post={r}
                cartNotificationCallback={(q) => setCartNotification(q)}
                products={products}
                config={configs}
              />
            ))}

          <AsyncRoute
            path="/order/:orderKey"
            getComponent={this.Order}
            config={configs}
            products={products}
          />
          <AsyncRoute
            path="/order"
            getComponent={this.OrderHistory}
            config={configs}
            products={products}
          />
          <AsyncRoute
            path="/review"
            getComponent={this.Review}
            config={configs}
          />
          <AsyncRoute
            path="/about"
            getComponent={this.About}
            config={configs}
          />
          <AsyncRoute
            path="/safety"
            getComponent={this.Safety}
            config={configs}
          />
          <AsyncRoute
            path="/agent"
            getComponent={this.Agent}
            config={configs}
          />
          <AsyncRoute path="/faq" getComponent={this.Faq} config={configs} />
          <AsyncRoute
            path="/contact"
            getComponent={this.Contact}
            config={configs}
          />
          <AsyncRoute
            path="/cart/bulk"
            getComponent={this.CartBulk}
            config={configs}
          />
          <AsyncRoute
            path="/privacy-policy"
            getComponent={this.PrivacyPolicy}
            config={configs}
          />
          <AsyncRoute
            path="/return-policy"
            getComponent={this.ReturnPolicy}
            config={configs}
          />
          <AsyncRoute
            path="/terms-of-use"
            getComponent={this.TermsOfUse}
            config={configs}
          />
          <AsyncRoute
            path="/affiliate"
            getComponent={this.Affiliate}
            config={configs}
            products={products}
          />
          <AsyncRoute
            path="/login"
            getComponent={this.Login}
            config={configs}
          />
          <Blank path="/_blank" />
        </Router>
        <CookiePopup config={configs} />
      </div>
    );
  }
}
