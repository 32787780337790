import { Link } from 'preact-router/match';

export const GLRSET21 = () => {
  return {
    url: '/product/set-21',
    title: 'ชุดบูสผิว 1 เดือน',
    brand: 'GLORY',
    sku: 'GLRSET21',
    totalSale: 8865,
    price: 2380,
    offerPrice: 3260,
    images: ['/product/GLRCL4VC2.jpg'],
    product: 'ชุดบูสผิว 1 เดือน: Collagen 4 + Vit C 2',
    shortDescription: 'สครับขจัดเซลล์ผิว ช่วยให้ผิวขาวกระจ่างใส',
    description: (
      <div class="mb-2 text-baseColor">
        <p>
          ชุดบูสผิว เหมาะสำหรับคนที่ต้องการลดสิว รอยแดง รอยดำ ทานได้ 2 เดือน
        </p>
        <p class="mt-2">ในชุดนี้ประกอบด้วย</p>
        <ul class="list-disc my-2 ml-10 text-baseColor">
          <li>Glory Collagen 4 ซอง</li>
          <li>Glory Tomato Vit C 2 ซอง</li>
        </ul>
      </div>
    ),
    options: null,
    guarantee: {
      title: 'คุณภาพจากกลอรี่',
      description: (
        <div>
          เพราะความสวยสร้างได้
          กลอรี่ผลิตภัณฑ์คุณภาพที่ออกแบบมาเพื่อให้คุณมีผิวสวยใส ไร้สิว
          จากภายในสู่ภายนอก
        </div>
      ),
    },
    ingredients: null,
    fdaId: null,
    weight: null,
    faqs: [
      {
        question: 'วิธีทานให้เห็นผล',
        answer: (
          <div class="grid md:grid-cols-2">
            <div class="mb-4">
              <span class="font-bold">ตอนเช้า</span>
              <ul class="list-decimal mt-2 ml-6">
                <li>ทานคอลลาเจน 2 แคปซูลหลังตื่นนอน</li>
                <li>ทานโทเมโท่วิตซี 1 แคปหลังอาหารเช้า</li>
              </ul>
            </div>
            <div>
              <span class="font-bold">ตอนกลางคืน</span>
              <ul class="list-decimal mt-2 ml-6">
                <li>ทานคอลลาเจน 2 แคปก่อนนอน</li>
                <li>ทานโทเมโท่วิตซี 1 แคปก่อนนอน</li>
              </ul>
            </div>
          </div>
        ),
      },
    ],
    videoContent: {
      title: 'สิวหาย ออร่ากระจาย',
      subtitle:
        'หมดปัญหาสิว ผิวโกลว์สุด เคล็ดลับทำให้หน้าเนียน ผิวดี ด้วย Glory Collagen',
      url: 'https://www.youtube.com/watch?v=rDbLAJNC4pU',
    },
    disclaimer: null,
    reviews: null,
    productsIncluded: ['GLRCL4', 'GLRVC2'],
    relateProducts: [
      'GLRSET1',
      'GLRSET6',
      'GLRSET7',
      'GLRSET8',
      'GLRSET11',
      'GLRSET12',
      'GLRCL1',
      'GLRVC1',
      'GLRSC1',
      'GLRBS1',
    ],
    upsellProducts: ['GLRSC1', 'GLRBS1'],
  };
};
