export const about = () => {
  return {
    title: 'เกี่ยวกับกลอรี่',
    intro: {
      title: 'สินค้าคุณภาพจาก GLORY',
      image: '/glorycollagen/ceo-cherry.jpg',
      content: (
        <div>
          <p>
            แบรนด์กลอรี่เริ่มต้นจาก CEO เชอร์รี่ เจ้าของช่องยูทูป CherryChu
            ต้องการทำผลิตภัณฑ์ที่ตอบโจทย์เรื่องผิวให้เหมาะกับผิวของคนไทย
            ในราคาที่จับต้องได้ และเห็นผลลัพธ์จริง
          </p>
          <p class="mt-2">
            ด้วยการวิจัยและพัฒนากว่า 1 ปี และทดสอบจากผู้ใช้จริงกว่าพันคน
            จึงเกิดเป็นแบรนด์กลอรี่
            อาหารเสริมบำรุงผิวและเครื่องสำอางค์ที่ช่วยลดสิว เปิดผิวใส มีออร่า
            เหมาะกับสภาพผิวของคนไทย
          </p>
        </div>
      ),
    },
    video: {
      title: '5 ตัวผิวใส ไม่กลับมาดำอีก',
      url: 'https://www.youtube.com/watch?v=9mamT93ZPxw',
    },
    product: {
      title: 'สินค้าแบรนด์กลอรี่',
      image: '/product/GLRSETCL1VC1SC1BS1.jpg',
      description: <p>สินค้าแบรนด์กลอรี่ประกอบไปด้วย</p>,
      items: [
        {
          title: 'Glory Collagen',
          url: '/product/collagen',
        },
        {
          title: 'Tomato Vit C',
          url: '/product/tomato-vit-c',
        },
        {
          title: 'Glory Scrub',
          url: '/product/scrub',
        },
        {
          title: 'Glory Body Serum',
          url: '/product/body-serum',
        },
      ],
    },
    media: {
      title: 'กลอรี่ในสื่อต่างๆ',
      items: [
        {
          image: '/glorycollagen/review-glory-youtube-9.jpg',
          title: '[ไหนเล่าซิ๊] Vlog 31 เม้าเรื่องผิวๆ สิวไม่มา ออร่ากระจาย',
          url: 'https://www.youtube.com/watch?v=rDbLAJNC4pU',
        },
        {
          image: '/glorycollagen/review-scrub-youtube-1.jpg',
          title:
            '[กูรูยาหม่อง] (เช็คสูตร) ตัวช่วยเร่งผิวขาวใส ใน 10 นาที ดีจริงจนบอกต่อ',
          url: 'https://www.youtube.com/watch?v=j-64rpvzR9M',
        },
        {
          image: '/glorycollagen/review-glory-youtube-1.jpg',
          title:
            '[Superthankky] สูตรผิวขาว เม็ดเดียว! ขาวเหมือนฉีด!! เคลมหนัก ขาวเห็นเส้นเลือด เกินไปละแมะ!',
          url: 'https://www.youtube.com/watch?v=FQcpUq3x2DM',
        },
        {
          image: '/glorycollagen/review-glory-youtube-2.jpg',
          title:
            '[Nutties11311] ทำอะไรมาผิวดีขึ้น? รีวิวอาหารผิวกู้ผิวใสเร่งด่วน',
          url: 'https://www.youtube.com/watch?v=ccpOhC2xwwE',
        },
      ],
    },
  };
};
