import { Link } from 'preact-router/match';

export const review = () => {
  return {
    communityReview: [
      {
        image: '/glorycollagen/review-glory-youtube-9.jpg',
        title: '[ไหนเล่าซิ๊] Vlog 31 เม้าเรื่องผิวๆ สิวไม่มา ออร่ากระจาย',
        url: 'https://www.youtube.com/watch?v=rDbLAJNC4pU',
      },
      {
        image: '/glorycollagen/review-scrub-youtube-1.jpg',
        title:
          '[กูรูยาหม่อง] (เช็คสูตร) ตัวช่วยเร่งผิวขาวใส ใน 10 นาที ดีจริงจนบอกต่อ',
        url: 'https://www.youtube.com/watch?v=j-64rpvzR9M',
      },
      {
        image: '/glorycollagen/review-glory-youtube-1.jpg',
        title:
          '[Superthankky] สูตรผิวขาว เม็ดเดียว! ขาวเหมือนฉีด!! เคลมหนัก ขาวเห็นเส้นเลือด เกินไปละแมะ!',
        url: 'https://www.youtube.com/watch?v=FQcpUq3x2DM',
      },
      {
        image: '/glorycollagen/review-glory-youtube-2.jpg',
        title:
          '[Nutties11311] ทำอะไรมาผิวดีขึ้น? รีวิวอาหารผิวกู้ผิวใสเร่งด่วน',
        url: 'https://www.youtube.com/watch?v=ccpOhC2xwwE',
      },
      {
        image: '/glorycollagen/review-glory-youtube-3.jpg',
        title:
          '[โลกของคนมีหนวด] ดีจริงหรือจกตา !!! โดนท้าให้กิน 7เม็ดต่อวัน ! เคลมแรงสิวหายผิวใสเห็นเส้นเลือด เจอแน่',
        url: 'https://www.youtube.com/watch?v=X__vaqEHZXU',
      },
      {
        image: '/glorycollagen/review-glory-youtube-4.jpg',
        title:
          '[pimwa] สู่หุ่นในฝัน Ep.4 ฟิตหุ่นโหด 7 วัน + สรุปผล Diet Vlog 1 เดือน🔥',
        url: 'https://www.youtube.com/watch?v=8bU6Jw_iZSs',
      },
      {
        image: '/glorycollagen/review-glory-youtube-5.jpg',
        title: '[CHERRYCHU] 5 ตัวผิวใสแบบธรรมชาติ ไม่กลับมาดำอีก!',
        url: 'https://www.youtube.com/watch?v=9mamT93ZPxw',
      },
      {
        image: '/glorycollagen/review-glory-youtube-6.jpg',
        title: '[James Badbitch] ผลการกิน Glory Collagen 14 วัน',
        url: 'https://www.youtube.com/watch?v=9mamT93ZPxw',
      },
      {
        image: '/glorycollagen/review-glory-youtube-7.jpg',
        title:
          '[Bell Warisara] 1 เดือนกับการดูเเลผิวให้ดีขึ้น🧖🏻‍♀! ใช้อะไร? ผลออกมาจะเป็นยังไง?',
        url: 'https://www.youtube.com/watch?v=nVnOBJS2TCE',
      },
      {
        image: '/glorycollagen/review-glory-youtube-8.jpg',
        title:
          '[THEPANG Channel] ผิวขาวใสภายใน 7 วัน ต้อนรับปีใหม่ 2021 ด้วย Glory Collagen',
        url: 'https://www.youtube.com/watch?v=IfMteqNwNvo',
      },
      {
        image: '/glorycollagen/review-glory-youtube-10.jpg',
        title: '[Kokoming] How To ผิวขาวใสง่ายๆ เห็นผลเร็วและธรรมชาติ 100%',
        url: 'https://www.youtube.com/watch?v=dZiQJWMkSlI',
      },
      {
        image: '/glorycollagen/review-glory-youtube-11.jpg',
        title: '[Kokoming] How To ผิวขาวใสง่ายๆ เห็นผลเร็วและธรรมชาติ 100%',
        url: 'https://www.youtube.com/watch?v=rBtLs7RCHt4',
      },
      {
        image: '/glorycollagen/review-glory-youtube-12.jpg',
        title:
          '[GYNE GENE] Review Collagen ที่กินแล้วเห็นผลจริง ผิวขาวใสขึ้น มีรูปให้ดู!',
        url: 'https://www.youtube.com/watch?v=m357_DnL1L4',
      },
      {
        image: '/glorycollagen/review-glory-youtube-13.jpg',
        title: '[babyposie pae] How to ผิวขาวไว ปลอดภัย 100%',
        url: 'https://www.youtube.com/watch?v=5CLXXxY1i2U',
      },
      {
        image: '/glorycollagen/review-glory-youtube-14.jpg',
        title:
          '[nownow p.] สครับที่ทุกคนต้องหลงรัก ขาวใสขึ้นตั้งแต่ครั้งแรกที่ใช้',
        url: 'https://www.youtube.com/watch?v=8YMBtU4GCYo',
      },
      {
        image: '/glorycollagen/review-glory-youtube-15.jpg',
        title:
          '[Review] เปิดห้องสกินแคร์ (ทาตัว) หลักแสน !!! เคล็ดลับผิวสวย ออร่าพุ่ง',
        url: 'https://www.youtube.com/watch?v=tDjlRAPGUg4',
      },
      {
        image: '/glorycollagen/review-glory-youtube-16.jpg',
        title:
          '[Tatum] ผิวขาวใส มีออร่าด้วย GLory Collagen หลังทานกับก่อนทาน คือ แตกต่างมาก',
        url: 'https://www.youtube.com/watch?v=mIHfEVDFnQM',
      },
      {
        image: '/glorycollagen/review-glory-youtube-17.jpg',
        title: '[Khemii World] 5 Items รักษาสิว ผิวใส Glory Collagen',
        url: 'https://www.youtube.com/watch?v=eAx42E8kGIM',
      },
      {
        image: '/glorycollagen/review-glory-youtube-18.jpg',
        title:
          '[AOMNTP] รีวิว Glory Collagen เห็นผลจริง ผิวนุ่มลื่นชุ่มชื้น สุขภาพดี',
        url: 'https://www.youtube.com/watch?v=ovsbUvjflqM',
      },
      {
        image: '/glorycollagen/review-glory-youtube-19.jpg',
        title:
          '[James Badbitch] รีวิวจกตา ผิวใสใน 5 นาที จริงหรือป่าว? อย่ามาโกหกกันนะ เพราะมันดูออก !! Glory Scrub',
        url: 'https://www.youtube.com/watch?v=vnGhUWWM95I',
      },
      {
        image: '/glorycollagen/review-glory-youtube-20.jpg',
        title:
          '[Oumttp] กู้สิวผิวใส ด้วย Glory Collagen และ Tomato Vit C ที่สุดของปี',
        url: 'https://www.youtube.com/watch?v=uFFwhySxstI',
      },
      {
        image: '/glorycollagen/review-glory-youtube-21.jpg',
        title:
          '[Cherry Chu] รีวิว Best of The Year อาหารผิว Glory Collagen & Tomato Vit C',
        url: 'https://www.youtube.com/watch?v=om784Ks883Q',
      },
      {
        image: '/glorycollagen/review-glory-youtube-22.jpg',
        title: '[Kokoming] How to หน้าขาวใส รอยสิวจางใน 1 อาทิตย์',
        url: 'https://www.youtube.com/watch?v=dU_3evPU0ms',
      },
      {
        image: '/glorycollagen/review-glory-youtube-23.jpg',
        title: '[James Badbitch] กิน Glory Collagen 7 วัน ทำไมเป็นแบบนี้!!!',
        url: 'https://www.youtube.com/watch?v=xb25AWFxZkQ',
      },
      {
        image: '/glorycollagen/review-glory-youtube-24.jpg',
        title: '[เกรซ] กินจริง อินจริง คุณเกรซ แนะนำ Glory Collagen',
        url: 'https://www.youtube.com/watch?v=2mZHpXbktu0',
      },
      {
        image: '/glorycollagen/review-glory-youtube-25.jpg',
        title: '[Nuttyploy] กินแล้วเห็นผลจริง Glory คอลลาเจนและ Tomato Vit C',
        url: 'https://www.youtube.com/watch?v=zgXWEw7H6Ic',
      },
      {
        image: '/glorycollagen/review-glory-youtube-26.jpg',
        title: '[Wasitpol Pookme] 15 Days Challenge!! กับ Glory Collagen',
        url: 'https://www.youtube.com/watch?v=Ef5A80lcWZ8',
      },
      {
        image: '/glorycollagen/review-glory-youtube-27.jpg',
        title: '[MS.FAFAII] 7 วันกู้ผิวใส "ปัง" หรือ "พัง!" ด้วย Glory',
        url: 'https://www.youtube.com/watch?v=QqoiVa3CqYQ',
      },
      {
        image: '/glorycollagen/review-glory-youtube-28.jpg',
        title: '[BaLaBee] รีวิว Glory Collagen',
        url: 'https://www.youtube.com/watch?v=V93baL8Bf_c',
      },
      {
        image: '/glorycollagen/review-glory-youtube-29.jpg',
        title:
          '[Superthankky] จับโป๊ะ! Glory กินจริง หรือโดนจ้าง? ไม่คิดว่าจะเยอะขนาดนี้',
        url: 'https://www.youtube.com/watch?v=mzxXlC_6Zkg',
      },
    ],
    customerReview: [
      {
        image: '/glorycollagen/rv-twitter-1.jpg',
        review: (
          <div>
            จัดคอลลาเจนของ Glory มาอีกแบรนด์ ตัวนี้เป็นคอลลาเจน Di-Peptide นะจ๊ะ
            หลังจากที่ลองไปอาทิตย์นึง
            คือรู้สึกได้ว่าผิวมันดูใสและนุ่มขึ้นกว่าเดิมอ่ะ เป็นดี!
            แนะนำว่าให้ทานคู่กับวิตซีด้วย ช่วยให้เห็นผลไวขึ้นนะ
          </div>
        ),
        name: 'คุณออย',
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-twitter-2.jpg',
        review: (
          <div>
            สองสามอาทิตย์ก่อนออกข้างนอกบ่อย ทำให้โดนแดดตลอด
            ผิวคล้ำไปช่วงนึงเลยแหละ แต่เริ่มกิะคอลลาเจนแบรนด์ Glory มาสักพัก
            คนก็เริ่มทักว่าทำไมผิวใส ใสขึ้นจริงๆ
            มุกทานตัวนี้ตอนเช้าเม็ดเดียวเองนะ เป็นแบรนด์ที่กินแล้วเห็นผลไวมาก
            เลิฟเลย
          </div>
        ),
        name: 'คุณมุก',
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-twitter-3.jpg',
        review: (
          <div>
            เมื่อก่อนเริมผิวคล้ำมากแหละเพราะไม่ค่อยดูแลตัวเอง
            อย่างที่บอกช่วงนี้หันมาดูแลตัวเองโดยเฉพาะเรื่องผิว
            ผิวเราไม่ได้ขาวแต่ใสขึ้น ได้เท่านี้ก็คือดีใจสุดแล้ว
            เจอใครก็บอกว่าผิวใสขึ้น ปลื้มมม แนะนำคอลลาเจนตัวนี้เลยงับ "GLORY"
          </div>
        ),
        name: 'คุณจ๊ะ',
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-twitter-4.jpg',
        review: (
          <div>
            อยากผิวสวยแต่ไม่ชอบกินน้ำมะเขือเทศ ต้องลอง Glory Tomato Vit C กินไป
            1 สัปดาห์ วันละ 2 เม็ด ตอนเช้า รู้สึกได้เลยว่าผิวใสขึ้นอ่ะ
            แถมยังช่วยเสริมสร้างภูมิคุ้มกันให้เราในช่วงนี้ด้วย
          </div>
        ),
        name: 'คุณตัง',
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-twitter-5.jpg',
        review: (
          <div>
            ปกติไม่ค่อยกินอาหารเสริม แต่อยากลอง อยากผิวสวยๆ
            แต่กินมะเขือเทศไม่ได้เลย ทั้งแบบลูก แบบน้ำดอยคำ
            อันนี้กินง่ายดีของแบรนด์ Glory เป็นมะเขือเทศอัดแคปซูล
            ได้กินวิตามินซี กินวันละ 1 แคป ผิวกระจ่างใส ขาวขึ้นนะ
          </div>
        ),
        name: 'คุณขนมผิง',
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-twitter-6.jpg',
        review: (
          <div>
            ตอนแรกซื้อมาลอง เห็น packaging สวยดี แต่เห้ย!! เจอตัวนี้อาทิตย์เดียว
            ผิวใสจนเพื่อนทัก ทำกิจกรรมกลางแจ้งก็ไม่กลัวเลย
          </div>
        ),
        name: 'คุณบรัช',
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-twitter-7.jpg',
        review: (
          <div>
            ใครไม่ชอบกินน้ำมะเขือเทศดอยคำ แต่อยากผิวขาวหน้าใส ลอง Vit C ของ
            Glory ดูค่า เป็นมะเขือเทศอัดเม็ด 1 แคปซูล
            ส่วนตัวจูนทานมาได้สักพักผิวมันใสขึ้น แต่มันยังไม่ถึงกับขาวนะ
            อาจต้องกินต่ออีก แต่หน้าดูขาวใสขึ้นเร็วจริง
            และสิวหรือรอยแดงจางลงด้วย
          </div>
        ),
        name: 'คุณจูน',
        product: 'GLCVC1',
      },
      {
        image: '/glorycollagen/rv-twitter-8.jpg',
        review: (
          <div>
            ชอบกินวิตามินซีกับน้ำมะเขือเทศคู่กัน 1 เม็ด เหมีอนได้กินมะเขือเทศ 34
            ผลเลย ช่วงที่รักษาสิวอาทิตย์เดียวยิ่งเห็นชัดเลยว่า
            รอยสิวหายไวมากกจนแฟนทัก ผิวหายหมอง แก้มใสๆ ขึ้น
          </div>
        ),
        name: 'คุณโอป',
        product: 'GLCVC1',
      },
      {
        image: '/glorycollagen/rv-twitter-9.jpg',
        review: (
          <div>
            เซ็ทคู่ดูแลผิวค้าบ Tomato Vit C + Collagen Dipeptide ใครอยากผิวสวย
            ขาวใส อยากให้ลอง อย่างละ 2 เม็ดทุกเช้า คือผิวดีมาก เลิฟเลย
          </div>
        ),
        name: 'คุณออนนี',
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-twitter-10.jpg',
        review: (
          <div>
            เพิ่งมาสังเกตตอนที่เพื่อนทักว่าผิวดูใส ขาวขึ้น
            นี่ไม่ได้คาดหวังอะไรมากตอนที่กิน Vit C กับคอลลาเจนคู่นี้
            แต่ผลที่ได้หลังกินต่อเนื่องก็คือ ผิวดีขึ้นจริง นี่ชอบ Vit C
            ส่วนผสมจัดเต็ม เหมาะกับคนที่อยากผิวใสแต่กินดอยคำไม่ไหว
            เห็นว่าได้เรื่องสิวต่างๆ ด้วย ครบมาก!
          </div>
        ),
        name: 'คุณไหม',
        product: 'GLCVC1',
      },
      {
        image: '/glorycollagen/rv-twitter-11.jpg',
        review: (
          <div>
            เป็นคนผิวแทนที่กินกลูต้ากับคอลลาเจนตลอด ไม่ได้ซีเรื่องความขาว
            แต่เวลากินพวกนี้แล้วผิวจะใสมาก ถ่ายรูปออกมาก็ดูผิวเงาสุขภาพดีทั้งๆ
            ที่ไม่ได้ทาครีมหรืออะไรเลย ตอนนี้กินคอลลาเจนกับมะเขือเทศอัดเม็ดของ
            Glory คู่กันอยู่ ผิวเนียนใสมาก
          </div>
        ),
        name: 'คุณเมล่อน',
        product: 'GLCVC1',
      },
      {
        image: '/glorycollagen/rv-line-1.jpg',
        review: null,
        name: null,
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-line-2.jpg',
        review: null,
        name: null,
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-line-3.jpg',
        review: null,
        name: null,
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-line-4.jpg',
        review: null,
        name: null,
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-line-5.jpg',
        review: null,
        name: null,
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-line-6.jpg',
        review: null,
        name: null,
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-line-7.jpg',
        review: null,
        name: null,
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-line-8.jpg',
        review: null,
        name: null,
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-line-9.jpg',
        review: null,
        name: null,
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-line-10.jpg',
        review: null,
        name: null,
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-line-11.jpg',
        review: null,
        name: null,
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-line-12.jpg',
        review: null,
        name: null,
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-line-13.jpg',
        review: null,
        name: null,
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-line-14.jpg',
        review: null,
        name: null,
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-line-15.jpg',
        review: null,
        name: null,
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-line-16.jpg',
        review: null,
        name: null,
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-line-17.jpg',
        review: null,
        name: null,
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-line-18.jpg',
        review: null,
        name: null,
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-line-19.jpg',
        review: null,
        name: null,
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-line-20.jpg',
        review: null,
        name: null,
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-line-21.jpg',
        review: null,
        name: null,
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-line-22.jpg',
        review: null,
        name: null,
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-line-23.jpg',
        review: null,
        name: null,
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-line-24.jpg',
        review: null,
        name: null,
        product: 'GLCCL1',
      },
    ],
  };
};
