import { Link } from 'preact-router/match';

export const GLRVG2 = () => ({
  url: '/product/veggy-2',
  title: 'กลอรี่เว็จจี่ 2 กล่อง',
  brand: 'GLORY',
  sku: 'GLRVG2',
  totalSale: 23451,
  price: 760,
  offerPrice: 900,
  images: ['/product/GLRVG1.jpg'],
  product: 'เว็จจี่ 2 กล่อง',
  shortDescription: 'Glory Preibiotic Veggy Plus',
  description: (
    <div class="mb-2 text-baseColor">
      <p>
        เวจจี้ พลัส ดีท็อกซ์วิตามินผัก เสริม Probiotic
        แบคทีเรียที่มีประโยชน์ต่อลำไส้ ถูกใจสายออแกนิก ช่วยปรับสมดุล
        ล้างสารพิษตกค้าง ป้องกันการเกิดโรคต่างๆในลำไส้
        พร้อมดูดซึมสารอาหารที่มีประโยชน์ มีไฟเบอร์ช่วยระบบขับถ่าย ลดไขมันสะสม
        ลดความมันผิว สิวขึ้นน้อยลง ผิวพรรณสดใส สุขภาพดีจากภายในสู่ภายนอก
      </p>

      <p class="mt-2">ทำไมต้องทำดีท็อกซ์ด้วย Glory Veggy</p>
      <ul class="list-disc mt-4 ml-10 text-baseColor">
        <li>พุงยุบใน 1 คืน</li>
        <li>ล้างลำไส้ขับสารพิษ</li>
        <li>เสริมระบบย่อยอาหาร</li>
        <li>ลดการปวดประจำเดือน</li>
        <li>เสริม Probiotic แบคทีเรียที่มีประโยชน์ต่อร่างกาย</li>
        <li>ปรับสมดุลลำไส้</li>
        <li>ป้องกันการเกิดโรคต่างๆในลำไส้</li>
        <li>ช่วยให้ขับถ่ายดีขึ้น</li>
        <li>มีสารสกัดจากคาโมมายช่วยให้หลับสบาย</li>
      </ul>
      <p class="mt-2">
        และเพื่อผลลัพธ์ที่ดีขึ้น ควรทานเป็นประจำอย่างต่อเนื่อง
        ควบคู่กับผลิตภัณฑ์{' '}
        <Link
          href="/product/collagen"
          class="font-bold text-primary hover:text-primaryLight"
        >
          Glory Collagen
        </Link>
        {', '}
        <Link
          href="/product/tomato-vit-c"
          class="font-bold text-primary hover:text-primaryLight"
        >
          Tomato Vit C
        </Link>
        {'และ '}
        <Link
          href="/product/scrub"
          class="font-bold text-primary hover:text-primaryLight"
        >
          Scrub
        </Link>
      </p>
    </div>
  ),
  options: [
    {
      sku: 'GLRVG1',
      title: (
        <div>
          1 กล่อง <del>฿450</del>
        </div>
      ),
      price: 390,
    },
    {
      sku: 'GLRVG2',
      title: (
        <div>
          2 กล่อง <del>฿900</del>
        </div>
      ),
      price: 760,
    },
    {
      sku: 'GLRVG3',
      title: (
        <div>
          3 กล่อง <del>฿1,350</del>
        </div>
      ),
      price: 1120,
    },
  ],
  guarantee: {
    title: 'คุณภาพจากกลอรี่',
    description: (
      <div>
        เพราะความสวยสร้างได้
        กลอรี่ผลิตภัณฑ์คุณภาพที่ออกแบบมาเพื่อให้คุณมีผิวสวยใส ไร้สิว
        จากภายในสู่ภายนอก
      </div>
    ),
  },
  ingredients: [
    {
      image: '/ingredient/glory-veggy-ingredient-1.png',
      title: 'สารสกัดผลส้มแขก',
      description: (
        <div>
          ช่วยลดความอยากอาหาร ความรู้สึกหิวอาหาร ส้มแขก
          ช่วยเร่งระบบการเผาผลาญอาหาร ช่วยดักจับแป้ง
          และไขมันจากอาหารที่รับประทานเข้าไป สารสกัดจากส้มแขก
          ช่วยทำให้ลำไส้เกิดการเคลื่อนไหวตัวได้เร็วขึ้น และขับไขมันออกมา ส้มแขก
          มีสรรพคุณในการช่วยลดน้ำหนัก และช่วยลดไขมันส่วนเกินของร่างกายได้
        </div>
      ),
    },
    {
      image: '/ingredient/glory-veggy-ingredient-2.png',
      title: 'สารสกัดมะขามป้อม',
      description: (
        <div>
          คุณสมบัติในการลดไขมันในเลือด (Dyslipidemia) โรคหลอดเลือดหัวใจและสมอง
          ที่เกิดจากการตีบ แตก หรือ ตันของเส้นเลือด
          มักมีระดับไขมันในเลือดเข้ามาเกี่ยวข้อง และระดับไขมันในเลือดก็จัดว่า
          เป็นปัจจัยเสี่ยงหนึ่งที่จะก่อให้เกิดโรคดังกล่าวขึ้นได้
          ซึ่งจากการศึกษาวิจัยพบว่า สารออกฤทธิ์ทางยาที่พบในมะขามป้อม
          สามารถลดระดับไขมันในเลือดได้ เช่น ลดระดับไขมันไตรกลีเซอไรด์
          ลดระดับไขมันคอเลสเตอรอลแบบรวม
        </div>
      ),
    },
    {
      image: '/ingredient/glory-veggy-ingredient-3.png',
      title: 'ผงแอปเปิ้ล ',
      description: (
        <div>
          มีสารสำคัญคือ เบต้าแคโรทีน วิตามินซี และเส้นใยไฟเบอร์ชนิดละลายน้ำได้
          คือ เพคติน แอ๊ปเปิ้ลมีกรด 2 ชนิด คือ กรดมาลิคและกรดทาร์ทาริก
          ช่วยในการย่อยอาหารจำพวกโปรตีนและไขมัน นอกจากนั้นแอ๊ปเปิ้ล
          ยังมีการกล่าวถึงสรรพคุณ บำรุงหัวใจ ลดคลอเลสเตอรอล ลดความดัน
          ควบคุมปริมาณน้ำตาลในเลือด กระตุ้นการทำงานของสารต้านอนุมูลอิสระ
          และฆ่าเชื้อไวรัส
        </div>
      ),
    },
    {
      image: '/ingredient/glory-veggy-ingredient-4.png',
      title: 'สารสกัดว่านหางจระเข้',
      description: (
        <div>
          อุดมไปด้วยวิตามินและแร่ธาตุ
          รวมไปถึงกรดอะมิโนอีกหลายชนิดที่จำเป็นและมีประโยชน์ต่อร่างกาย เช่น
          แมกนีเซียม โพแทสเซียม แมงกานีส วิตามินเอ ซี อี บี 1 , 3 , 6 , 9
          โคลีนและยังเป็นหนึ่งในพืชไม่กี่ชนิดที่มีวิตามินบี 12 ด้วย
          ช่วยในการย่อยอาหาร ทำความสะอาดลำไส้ใหญ่ ดีท็อกซ์สารพิษในร่างการ
          ช่วยในการทำงานของระบบกระเพาะอาหารและช่วยลดปริมาณของเชื้อแบคทีเรียในลำไส้
        </div>
      ),
    },
    {
      image: '/ingredient/glory-veggy-ingredient-5.png',
      title: 'สารสกัดเม็ดกาแฟไม่คั่ว',
      description: (
        <div>
          ตัวช่วยสำคัญในกระบวนการเผาผลาญในร่างกาย จากคุณสมบัติ
          และสารสำคัญของสารสกัดเมล็ดกาแฟดิบที่กล่าวมาข้างต้น
          สามารถสรุปคุณสมบัติของสารสกัดเมล็ดกาแฟดิบได้ดังนี้
          ช่วยลดระดับคอเลสเตอรอลทั้งหมด (total cholesterol)
          และลดคอเลสเตอรอลไม่ดี (LDL Cholesterol) ในเลือด
        </div>
      ),
    },
    {
      image: '/ingredient/glory-veggy-ingredient-6.png',
      title: 'ผงสับปะรด',
      description: (
        <div>
          สับปะรดมีกากใยอาหารอาหารมากซึ่งมีความสำคัญกับการย่อยอาหาร
          ช่วยลดคอเลสเตอรอล ควบคุมน้ำตาลในเส้นเลือดและช่วยลดความเสี่ยงของมะเร็ง
          ในสับปะรดมีเอนไซม์ตามธรรมชาติที่มีชื่อว่า “บรอมีเลน”
          สามารถช่วยย่อยอาหารได้ทั้งในสภาวะเป็นกรดและด่าง
          จึงเหมาะมากที่จะพาไปช่วยย่อยในกระเพาะซึ่งเป็นกรด
          ช่วยในเรื่องของระบบขับถ่าย ช่วยให้เลือดลมไหลเวียนดี
        </div>
      ),
    },
    {
      image: '/ingredient/glory-veggy-ingredient-7.png',
      title: 'ผงจากลูกพรุน',
      description: (
        <div>
          ลูกพรุนเป็นแหล่งไฟเบอร์ที่ดี
          ซึ่งสามารถช่วยส่งเสริมการเคลื่อนไหวของลำไส้และป้องกันอาการท้องผูก
          ช่วยส่งเสริมการลดน้ำหนักโดยลดความอยากอาหาร เพิ่มความรู้สึกอิ่ม
          และลดปริมาณแคลอรี่
          ปรับปรุงการควบคุมระดับน้ำตาลในเลือดโดยชะลอการดูดซึมคาร์โบไฮเดรต
          อีกทั้งยังมีสารต้านอนุมูลอิสระสูง โดยเฉพาะวิตามินเอและวิตามินเค
          ซึ่งสามารถช่วยปกป้องเซลล์จากความเสียหายที่เกิดจากอนุมูลอิสระ
        </div>
      ),
    },
    {
      image: '/ingredient/glory-veggy-ingredient-8.png',
      title: 'ผงชาเขียว',
      description: (
        <div>
          มีสารอีพิกัลโลคาเทชินกัลเลต (EGCG)
          ซึ่งมีความสำคัญในการออกฤทธิ์ต้านอนุมูลอิสระ
          และมีฤทธิ์ช่วยเพิ่มการเผาผลาญพลังงานและไขมันจึงส่งผลต่อการควบคุมน้ำหนักของร่างกาย
          ช่วยเพิ่มแบคทีเรียชนิดดีในลำไส้
          จึงสามารถช่วยล้างสารพิษและกำจัดพิษในลำไส้ได้
          ช่วยลดระดับคอเลสเตอรอลและระดับน้ำตาลในเลือด
          และมีงานวิจัยทางคลินิคพบว่าชาเขียวมีฤทธิ์ต่อต้านการเกิดโรคของหลอดเลือดหัวใจด้วย
        </div>
      ),
    },
    {
      image: '/ingredient/glory-veggy-ingredient-9.png',
      title: 'ผงคาโมมายด์',
      description: (
        <div>
          ทำให้สงบ คลายกังกล ช่วยให้หลับ ลดอาการระคายเคืองทางเดินอาหาร ขับลม
          ลดอาการปวดเกร็งท้อง ลดการปวดประจำเดือน
        </div>
      ),
    },
    {
      image: '/ingredient/glory-veggy-ingredient-10.png',
      title: 'กาแลคโต-โอลิโกแซคคาไรท์',
      description: (
        <div>
          กาแลคโต-โอลิโกแซ็กคาไรด์หรือ GOS นั้นพบตามธรรมชาติในน้ำนมแม่
          เนื่องจากทารกที่ได้รับนมแม่มีระดับ bifidobacteria และ lactobacillus
          สูงกว่าซึ่งเป็นผลมาจาก GOS ดังนั้นนอกจาก GOS
          จะเป็นอาหารให้กับจุลินทรีย์แล้ว
          ยังไปเพิ่มปริมาณเชื้อจุลินทรีย์ให้เพิ่มมากขึ้น
          กระบวนการหมักในลำไส้จึงสมบูรณ์ ระบบขับถ่ายเป็นไปอย่างมีประสิทธิภาพ
          ทำให้ digestive system เป็นไปอย่างสมบูรณ์ ช่วยลดความอยากอาหาร
          และเพิ่มภูมิต้านทานได้อีกด้วย
        </div>
      ),
    },
    {
      image: '/ingredient/glory-veggy-ingredient-11.png',
      title: 'โพรไบโอติก (บาซิลลัส โคแอกกูแลน)',
      description: (
        <div>
          เป็น Probiotic (โพรไบโอติก) จุลินทรีย์เชื้อดี กลุ่ม Lactobacillus spp.
          ที่มีประโยชน์ต่อร่างกายโดยเฉพาะระบบทางเดินอาหาร
          ช่วยผลิตเอนไซม์ย่อยอาหาร ผลิตสารเมตาโบไลต์ เช่น ไดอะซิทิล
          กรดไขมันสายสั้น (SCFAs) และวิตามิน สามารถกระตุ้นลำไส้ ให้เกิดการบีบตัว
          (peristalsis) ลดการผลิตสารอันตราย
        </div>
      ),
    },
    {
      image: '/ingredient/glory-veggy-ingredient-12.png',
      title: 'แอล-ธีอะนีน',
      description: (
        <div>
          ช่วยทำให้ระบบประสาท และสมองผ่อนคลาย ลดความเครียดทำให้จิตใจสงบ สดชื่น
          กระปรี้กระเปร่า มีความจำที่ดีขึ้น
          รวมถึงยังสามารถช่วยให้ประสิทธิภาพในการนอนหลับดีขึ้นอีกด้วย
          โดยแอล-ธีอะนีน จะกระตุ้นการผลิตคลื่นสมองชนิดอัลฟา (Alpha wave)
          ซึ่งเป็นช่วงคลื่นสมองที่พบบ่อยในเด็กที่มีความสุข หรือ
          ขณะที่ร่างกายอยู่ในช่วงของการผ่อนคลาย
          รวมทั้งเป็นช่วงคลื่นที่สมองมีความคิดสร้างสรรค์ เรียนรู้
          และจดจำได้อย่างรวดเร็ว
        </div>
      ),
    },
  ],
  ingredientSummary: (
    <div>
      <ul>
        <li>สารสกัดผลส้มแขก</li>
        <li>สารสกัดมะขามป้อม</li>
        <li>ผงแอปเปิ้ล</li>
        <li>สารสกัดว่านหางจระเข้</li>
        <li>สารสกัดเม็ดกาแฟไม่คั่ว</li>
        <li>ผงสับปะรด</li>
        <li>ผงจากลูกพรุน</li>
        <li>ผงชาเขียว</li>
        <li>ผงคาโมมายด์</li>
        <li>กาแลคโต-โอลิโกแซคคาไรท์</li>
        <li>โพรไบโอติก (บาซิลลัส โคแอกกูแลน)</li>
        <li>แอล-ธีอะนีน</li>
      </ul>
    </div>
  ),
  fdaId: '13-1-01760-5-0208',
  weight: '11.92 กรัม. 20 แคปซูล',
  faqs: [
    {
      question: 'โพรไบโอติกส์เวจจี้ 1 ซองมีกี่แคปซูล ?',
      answer: (
        <div>1 ซอง บรรจุ 20 แคปซูล ทานได้ 10 วัน ปริมาณสุทธิ 11.92 กรัม</div>
      ),
    },
    {
      question: 'วิธีทาน Glory Veggy',
      answer: (
        <div>
          ทาน 1-2 เม็ดตอนท้องว่างช่วงเย็น หรือก่อนเข้านอน ตื่นมาหน้าท้องแบนราบ
          ขับถ่ายสบายท้อง
        </div>
      ),
    },
    {
      question: 'กลอรี่ปลอดภัยไหม มีอย.หรือไม่',
      answer: (
        <div>
          <div>
            สินค้าทุกชิ้นของกลอรี่ ผลิตโดยโรงงานมาตรฐาน ปลอดภัย และมีอย.
          </div>
          <div class="mt-2">
            เลขที่อย.: 13-1-01760-5-0208 (โพรไบโอติกส์ เวจจี้พลัส).
            <br />
            เลขที่อย.: 10-1-20960-5-0093 (คอลลาเจน)
            <br />
            เลขที่อย.: 10-1-20960-5-0112 (โทเมโท่วิตซี)
            <br />
            เลขที่อย.: 10-1-63000-3-2598 (สครับ)
            <br />
            เลขที่อย.: 11-1-64000-0-2539 (บอดี้เซรั่ม)
            <br />
            เลขที่อย.: 13-2-12461-2-0085 (โกโก้)
          </div>
        </div>
      ),
    },
    {
      question: 'Veggy Plus เหมาะกับใครมากที่สุด?',
      answer: (
        <div>
          <ul className="list-disc ml-4">
            <li>คนไม่ชอบทานผัก ผลไม้</li>
            <li>คนชอบทานปิ้งย่าง ของทอด ของมัน</li>
            <li>คนขับถ่ายยาก ท้องผูก</li>
            <li>คนกินน้อยลงพุง</li>
            <li>คนผิวพรรณไม่สดใส หมองคล้ำ</li>
            <li>คนทานอาหารเสริมเท่าไหร่ก็ไม่เห็นผล</li>
          </ul>
        </div>
      ),
    },
    {
      question: 'ใครบ้างที่ไม่ควรทานกลอรี่เวจจี้',
      answer: (
        <div>
          <ul className="list-disc ml-4">
            <li>คนที่มีความดัน โรคหัวใจ โรคไต</li>
            <li>สตรีมีครรภ์และให้นมบุตร</li>
            <li>เด็กที่อายุต่ำกว่า 15 ปี</li>
            <li>ผู้สูงอายุ 60 ปีขึ้นไป</li>
          </ul>
        </div>
      ),
    },
  ],
  videoContent: {
    title: 'พุงยุบ สุขภาพดีใน  1  คืน',
    subtitle: 'ปรับสมดุลลำไส้ ช่วยการขับถ่าย ด้วย Glory Probiotic Veggy Plus',
    url: 'https://www.youtube.com/watch?v=6Ps2tP7nUqM&ab_channel=GURUCHECK',
  },
  disclaimer: null,
  reviews: [
    {
      image: '/glorycollagen/glory-veggy-review-1.jpg',
      review: (
        <div>
          ซื้อมาแบบขำๆ แต่ว้าวมาก Glory 3 ซอง 3 สีนี้เลย ลองกินมา 7
          วันแล้วเวิร์คมาก น้องชมพูส้ม = อาหารผิวล้วนๆ ทานชมพู 3 ส้ม 2
          แล้วช่วงเย็นกินเวจจี้ 1 แคป เค้าเป็นโพรไบโอติกส์จ้าทุกคน ช่วยปรับสมดุล
          ลำไส้ ดีท็อกซ์ผิวให้เริ่ด อึไม่ออกบอกแคปเขียว
          ประเด็นคือผิวก็ดูโกลว์สวยขึ้นมาก !!
        </div>
      ),
      name: 'นินิว',
      product: 'GLCVG1',
    },
    {
      image: '/glorycollagen/glory-veggy-review-2.jpg',
      review: (
        <div>
          กลอรี่เนี่ยเค้าทำอึ้งอยู่นะ คือกินอาหารผิวอยู่แล้ว
          แต่อยากลองโพรไบโอติกส์เวจจี้ด้วย ที่ช่วยปรับสมดุลลำไส้
          แม่มันดีท็อกของเสียแบบหมดพุง ให้ผิวดีจากภายในด้วย ไม่พูดเยอะ 1
          คืนรู้เรื่องพุงแบนไปเลย
        </div>
      ),
      name: 'กิ๊ฟท์',
      product: 'GLCVG1',
    },
    {
      image: '/glorycollagen/glory-veggy-review-3.jpg',
      review: (
        <div>
          แล้วอันนี้คือกินเวจจี้ 1 แคปก่อนนอน เช้ามาคือถ่ายคล่องมากก หมดพุง
          ท้องแบนไปเลยอ่ะ
        </div>
      ),
      name: 'โดนัท',
      product: 'GLCVG1',
    },
    {
      image: '/glorycollagen/glory-veggy-review-4.jpg',
      review: (
        <div>
          โดนกระแสป้ายยาค่ะ! จัดมา 3 สีเลยทั้งคอลลาเจน วิตซี กับน้องเวจจี้
          โพรไบโอติกส์ ช่วยดีท็อกตั้งแต่ลำไส้ไปเลยจุกๆ ตื่นมาคือพุงยุบจริงจัง
          ถ่ายสบายท้องมาก ส่วนอาหารผิวคือไม่ต้องพูดนะ ผิวใสแบบตะโกน
          แม่ทักว่าใช้อะไรผิวดีมาก!
        </div>
      ),
      name: 'มิ้ม',
      product: 'GLCVG1',
    },
  ],
  productsIncluded: null,
  relateProducts: ['GLRSET1', 'GLRSC1', 'GLRBS1', 'GLRCL1', 'GLRVC1'],
  upsellProducts: ['GLRSC1', 'GLRBS1'],
});
