export const general = () => {
  return {
    appVersion: '3.0.0',
    appName: 'GLORY',
    siteUrl: 'gloryth.com',
    siteBrand: 'GloryTH',
    logo: '/glorycollagen/glory-logo-black.jpg',
    gtmId: 'GTM-5LSNHC5',
    lineUrl: 'https://line.me/R/ti/p/%40198yewjy',
    lineChannel: null,
    messengerUrl: null,
    pwaText: 'ติดตั้งแอปเพื่อรับส่วนลดและโปรโมชั่นพิเศษจากกลอรี่',
    shopName: 'Glory',
    // company: 'บริษัท เดอ ละมุน จำกัด',
    company: 'บริษัท แอลเอ็มเอ็น เทรดดิ้ง จำกัด',
    address:
      '299/115 ซอยกาญจนาภิเษก 005/1 แขวงหลักสอง เขตบางแค กรุงเทพมหานคร 10160',
    tel: '088-980-8497',
    telUrl: 'tel:+66889808497',
    email: 'cs@delamun.com',
    paymentBankAccount: 'บจก. เดอ ละมุน',
    paymentBankName: 'ธนาคารกสิกรไทย',
    paymentBankId: '071-3-48481-4',
    // paymentBankAccount: 'บจก. แอลเอ็มเอ็น เทรดดิ้ง',
    // paymentBankName: 'ธนาคารไทยพาณิชย์',
    // paymentBankId: '412-1-84286-8',
    shippingDateMin: 2,
    shippingDateMax: 5,
    codFees: 50,
    shippingFees: 0,
    facebookAppId: '1525108917781704',
    oneSignalId: '646d8c87-6d02-46f0-85f3-90d493406fef',
    oneSignalSafariId:
      'web.onesignal.auto.1f7edc6b-077e-4a04-b244-6d0a0c671761',
    asSeenOn: [
      '/general/jeban-logo-grayscale.webp',
      '/general/sanook-logo-grayscale.jpg',
      '/general/dekd-logo-grayscale.jpg',
      '/general/trueid-logo-grayscale.jpg',
      '/general/medthai-logo-grayscale.jpg',
      '/general/healthy-dd-logo-grayscale.jpg',
    ],
    disclaimer: (
      <div>
        "กลอรี่" เป็นอาหารเสริมไม่ใช่ยารักษาโรค
        ดังนั้นจึงไม่มีผลป้องกันหรือรักษาโรคใดๆ
        เมื่อรับประทานอาหารเสริมกลอรี่เป็นประจำจะเริ่มเห็นผลลัพธ์ใน 2-4
        สัปดาห์แรก และจะสังเกตถึงการเปลี่ยนแปลงได้ชัดภายใน 2-3
        เดือนหลังจากรับประทานติดต่อกัน โดยผลลัพธ์ที่ได้ขึ้นอยู่กับแต่ละบุคคล
        สำหรับผู้ป่วยหรือผู้ที่แพ้ยาควรปรึกษาแพทย์และเภสัชกรก่อนรับประทาน
      </div>
    ),
    promoteAgent: {
      title: '100,000 บาทแรกของพลอย เกิดขึ้นที่นี่...',
      description:
        'จากอดีตพนักงานทวงหนี้ สู่การเป็นเฮดหลักกลอรี่คอลลาเจน ที่พร้อมช่วยคุณให้มีรายได้เสริม โดยไม่จำเป็นต้องมีประสบการณ์มาก่อน',
      url: 'https://glorydealer.com/agent/',
      image: '/glorycollagen/ploy-4.jpg',
    },
  };
};
