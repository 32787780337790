import { Link } from 'preact-router/match';

export const GLRSR5 = () => ({
  url: '/product/serum-5',
  title: 'กลอรี่เซรั่มแก้มใส 5 ขวด',
  brand: 'GLORY',
  sku: 'GLRSR5',
  totalSale: 201937,
  price: 2820,
  offerPrice: 4450,
  images: ['/product/GLRSR1.png'],
  product: 'กลอรี่เซรั่มแก้มใส 5 ขวด',
  shortDescription: 'Advanced Glow Skin Booster Serum เซรั่มแก้มใส',
  description: (
    <div class="mb-2 text-baseColor">
      <p>กลอรี่เซรั่ม Advanced Glow Skin Booster Serum เซรั่มแก้มใส</p>
      <p class="mt-2">
        No.1 เซรั่มที่เข้าใจผิวคนไทยมากที่สุด Glory Glow Skin Booster Serum
        นวัตกรรมใหม่จากกลอรี่ ตัวช่วยกู้ผิวเร่งด่วนใน 24 ชั่วโมง
      </p>
      <p class="mt-2">
        60% ของผู้ใช้ ผิวกระจ่างใส เผยผิวสุขภาพดีในข้ามคืน ด้วยสารสกัด Pauseile
        ช่วยปรับผิวใสเร่งด่วน 24 ชั่วโมง
      </p>
      <ul class="list-disc mt-4 ml-10 text-baseColor">
        <li>ทรีทเม้นท์ฟื้นฟูผิว ลดสิว พร้อมเผยผิวใสสุขภาพดี</li>
        <li>ชะลอการเสื่อมของเซลล์ผิว</li>
        <li>ผิวใสอิ่มน้ำ เพิ่มความชุ่มชื้น</li>
        <li>ผิวนุ่มอ่อนเยาว์ รูขุมขนกระชับ</li>
        <li>ดีท็อกซ์ผิวให้สะอาด</li>
      </ul>
      <p class="mt-2">
        และเพื่อผลลัพธ์ที่ดีขึ้น ควรใช้เป็นประจำอย่างต่อเนื่อง
        ควบคู่กับผลิตภัณฑ์{' '}
        <Link
          href="/product/tomato-vit-c"
          class="font-bold text-primary hover:text-primaryLight"
        >
          Tomato Vit C
        </Link>
        {', '}
        <Link
          href="/product/scrub"
          class="font-bold text-primary hover:text-primaryLight"
        >
          Scrub
        </Link>
        {', '}และ{' '}
        <Link
          href="/product/body-serum"
          class="font-bold text-primary hover:text-primaryLight"
        >
          Body Serum
        </Link>
      </p>
    </div>
  ),
  options: [
    {
      sku: 'GLRSR1',
      title: (
        <div>
          1 ขวด <del>฿890</del>
        </div>
      ),
      price: 690,
    },
    {
      sku: 'GLRSR2',
      title: (
        <div>
          2 ขวด <del>฿1,780</del>
        </div>
      ),
      price: 1180,
    },
    {
      sku: 'GLRSR3',
      title: (
        <div>
          3 ขวด <del>฿2,670</del>
        </div>
      ),
      price: 1720,
    },
  ],
  guarantee: {
    title: 'คุณภาพจากกลอรี่',
    description: (
      <div>
        เพราะความสวยสร้างได้
        กลอรี่ผลิตภัณฑ์คุณภาพที่ออกแบบมาเพื่อให้คุณมีผิวสวยใส ไร้สิว
        จากภายในสู่ภายนอก
      </div>
    ),
  },
  ingredients: [
    {
      image: '/ingredient/serum-pauseile.png',
      title: (
        <>
          Pauseile<sup>TM</sup>
        </>
      ),
      description: (
        <>
          <div>
            นวัตกรรม Active จากจุลชีพใต้ท้องทะเล ผ่านการสำรวจมากกว่า 42,000 ไมล์
            จาก Mauritius Island ปรับผิวให้กระจ่างใสใน 24 ชั่วโมง (Skin Radiance
            +22%) ล็อคความชุ่มชื้น ลดการสูญเสียน้ำให้แก่ผิว
            คืนความยืดหยุ่นให้แก่ผิว รวมถึงลดการหลุดลอกของผิว
          </div>
          <div>
            มีคุณสมบัติกระตุ้นการสร้างโปรตีนสำคัญ Filaggrin
            ที่ช่วยควบคุมความชุ่มชื้นในชั้นผิว
            อีกทั้งเพิ่มอัตราการสร้างเกาะป้องกัน (Barrier Function)
            และกระตุ้นการสร้าง Ceramide ลดการสูญเสียความชุ่มชื้น
            ทำให้ผิวแลดูเปล่งปลั่งได้อย่างรวดเร็วภายใน 24 ชั่วโมง
          </div>
        </>
      ),
    },
    {
      image: '/ingredient/serum-hyaluronic-acid.png',
      title: 'Hyaluronic Acid',
      description: (
        <div>
          ไฮยาลูรอนิค แอซิด (Hyaluronic Acid) หรือ ไฮยาลูรอน
          เป็นหนึ่งในส่วนผสมตัวท็อปที่แพทย์ผิวหนังแนะนำ
          หากคุณกำลังมองหาผลิตภัณฑ์บำรุงผิวที่ออกแบบมาเพื่อช่วยดูแลให้ผิวดูสุขภาพดี
          อิ่มน้ำ และนุ่มชุ่มชื้น
        </div>
      ),
    },
    {
      image: '/ingredient/serum-vitamin-c.png',
      title: 'Centella',
      description: (
        <div>
          สารสกัดจาก Cica หรือ Centella Asiatica เป็นสารสกัดจากใบบัวบก
          ซึ่งนิยมนำมาใช้เป็นส่วนผสมยอดฮิตในสกินแคร์ อุดมไปด้วย
          สารต้านอนุมูลอิสระ ไม่ว่าจะเป็น กรดอะมิโน, กรดไขมัน,เบต้าแคโรทีน
          วิตามิน A,B1,B2 นอกจากนี้ใน Cica (ซิก้า) ยังอุดมไปด้วยสารใน
          กลุ่มไทรเทอร์พินอยด์ (Triterpinoid) อย่าง เอเชียติโคไซด์
          (Asiaticaside) กรดเอเชียติก (Asiatic acid) กรดมาเดแคสซิค (Madecassic
          acid) มาเดแคสโซไซด์ (Madecassoside)
          ซึ่งมีคุณสมบัติเด่นที่ช่วยเรื่องการรักษาสิว รอยสิวให้หายขาดได้
          ลดการอักเสบของแผล ทำให้รอยแผลหายไวขึ้น
        </div>
      ),
    },
    {
      image: '/ingredient/serum-vitamin-c.png',
      title: 'Vitamin C',
      description: (
        <div>
          เป็นสารต้านอนุมูลอิสระ ช่วยชะลอความแก่ และลดการเกิดริ้วรอยแห่งวัย
          เป็นตัวสร้างคอลาเจน ซึ่งเป็นเส้นใยทำหน้าที่เชื่อมเนื้อเยื่อต่างๆ
          ไว้ด้วยกัน ทำให้ช่วยให้ผิวพรรณเต่งตึง
          ช่วยป้องกันการเปลี่ยนแปลงของเซลล์
        </div>
      ),
    },
    {
      image: '/ingredient/serum-niacinamide.png',
      title: 'Niacinamide',
      description: (
        <div>
          เป็นวิตามินที่มีประโยชน์ต่อผิวของเราที่สามารถช่วยฟื้นฟูผิวได้อย่างครอบคลุม
          โดยสามารถไล่ตั้งแต่ช่วยลดริ้วรอยและรอยแผลเป็นที่เกิดจากสิว
          ช่วยลดอาการแดงและอาการระคายเคืองบนผิว
          ไปจนถึงช่วยกระตุ้นการผลิตเซราไมด์ (ceramide)
          ที่ช่วยให้ผิวกระชับและชุ่มชื้น ซึ่งจะส่งผลให้ผิวเรียบเนียนขึ้น
          อีกทั้งยังเป็นส่วนผสมที่ช่วยกระชับรูขุมขนและคุมความมันบนผิว
          จึงเหมาะเป็นพิเศษสำหรับคนที่มีผิวมัน
          อย่างไรก็ตามเป็นส่วนผสมที่เข้าได้กับทุกสภาพผิว
        </div>
      ),
    },
    {
      image: '/ingredient/serum-ceramide.png',
      title: 'Ceramide 1 | 3 | 6 ',
      description: (
        <div>
          เซราไมด์จะช่วยเชื่อมให้เซลล์ผิวต่อติดกัน
          เสริมความแข็งแรงของเกราะชั้นผิวซึ่งเป็นส่วนสำคัญในการปกป้องผิวจากมลภาวะ
          แสงแดด และการสูญเสียความชุ่มชื้น
          อีกทั้งเซราไมด์ยังช่วยลดริ้วรอยได้ทรงพลังพอกับสารอื่น ๆ เช่น เรตินอล
          เปปไทด์ โดยไม่มีผลข้างเคียงเหมือนสารตัวอื่น
          ดังนั้นไม่ว่าคุณจะมีผิวแบบไหนก็สามารถใช้เซราไมด์ได้
          แม้แต่ผิวบอบบางแพ้ง่ายก็ตาม
        </div>
      ),
    },
    {
      image: '/ingredient/serum-madonna-lily.png',
      title: 'Madonna lily',
      description: (
        <>
          <div>
            พืชในตำนานหายากสายพันธุ์แท้ฝรั่งเศส ผสานศาสตร์แห่งเทคโนโลยีชีวภาพ
            (Biotechnology)
            จนได้เซลล์ต้นกำเนิดที่ดีที่สุดดุจได้สารสกัดจากดอกมาดอนน่าลิลลี่
            ศาสตร์แห่งความงามอย่างแท้จริง
          </div>
          <div>
            พบสารโพลีเปปไทด์ (Polypeptides)
            ทำหน้าที่เป็นสารควบคุมการปล่อยเม็ดสีเมานิน (Regulating Skin
            Pigmentation) ช่วยป้องกันการส่งผ่านเม็ดสีขึ้นสู่ชั้นผิว
            ปรับสภาพสีผิวให้สว่าง กระจ่างใสขึ้น ป้องกันการเกิดด่างดำ
            และกระตุ้นการผลัดเซลล์ผิวพร้อมลดเลือนริ้วรอย ทำให้ผิวผ่องใส
            เผยผิวใหม่ไม่หมองคล้ำ จุดด่างดำแลดูจางลง และดูอ่อนกว่าวัย
          </div>
          <div>
            การทดสอบการผลัดเซลล์ผิว พบว่า Lily Active Plant Cellที่ 0.5%
            ช่วยกระตุ้นการสร้างเซลล์ผิวใหม่ชั้นล่างสุดของหนังกำพร้า
            ทำให้เซลล์ผิวที่ตายแล้วค่อยๆถูกดันขึ้นและหลุดออก
            เพิ่มการผลัดเปลี่ยนเซลล์ผิวได้ดีขึ้น 20%ผิวจึงไม่มองคล้ำ
            แลดูกระจ่างใสขึ้นอย่างเป็นธรรมชาติ
          </div>
        </>
      ),
    },
    {
      image: '/ingredient/serum-collagen.png',
      title: 'Collagen',
      description: (
        <>
          คอลลาเจนเสริม ที่ช่วยเสริมความแข็งแรงให้กับคอลลาเจนในชั้นผิว
          ช่วยให้เซลล์ผิวมีความยืดหยุ่น อุ้มน้ำ และผิวดูเต่งตึงขึ้น
        </>
      ),
    },
    {
      image: '/ingredient/serum-prebiotic.png',
      title: 'Pre | Pro | Post Biotic',
      description: (
        <>
          จุลินทรีย์สายพันธ์ุดีคัดสรรเฉพาะจากกลอรี่
          ให้ประสิทธิภาพในการขับล้างสารพิษในผิวรวมถึงผลัดเซลล์ผิวอย่างอ่อนโยน
        </>
      ),
    },
    {
      image: '/ingredient/serum-dragon-blood.png',
      title: 'Dragon blood',
      description: (
        <>
          มีคุณสมบัติช่วยชะลอและลดเลือนริ้วรอย ลบเลือนรอยแผลเป็น
          ปกป้องผิวจากอนุมูลอิสระ รังสียูวี มลภาวะที่เป็นพิษ
          ช่วยลดรอยแดงบนใบหน้า ทำให้ผิวเรียบเนียนขึ้น กระชับรูขุมขน
          รูขุมขนเล็กลง ฟื้นฟูผิวที่ถูกทำร้าย พร้อมทั้งกระตุ้นการสร้างคอลลาเจน
          แก้ปัญหาผิวหน้าหมองคล้ำ จุดด่างดำ ยกกระชับผิวหน้า
          พร้อมเติมความชุ่มชื้นให้แก่ผิวสวย
        </>
      ),
    },
  ],
  ingredientSummary: (
    <div>
      <ul>
        <li>Fish Collagen Di-Peptide / คอลลาเจนไดเปปไทด์จากปลา 454 มก</li>
      </ul>
    </div>
  ),
  fdaId: '12-1-65000-1-9029',
  weight: '16 มล.',
  faqs: [
    {
      question: 'กลอรี่เซรั่มเหมาะกับใคร',
      answer: (
        <div>
          เหมาะสำหรับ ผู้ที่มีผิวแพ้ง่าย, เป็นสิวอักเสบ (ต่อเนื่อง),
          หน้าหมองคล้ำไม่สม่ำเสมอ และมีปัญหาผิวไม่แข็งแรง
        </div>
      ),
    },
    {
      question: '1 ขวดมีปริมาณเท่าไร ใช้ได้กี่วัน',
      answer: <div>1 ขวด มีปริมาณ 16 มล. ใช้ได้ 15-30 วัน</div>,
    },
    {
      question: 'กลอรี่ปลอดภัยไหม มีอย.หรือไม่',
      answer: (
        <div>
          <div>
            สินค้าทุกชิ้นของกลอรี่ ผลิตโดยโรงงานมาตรฐาน ปลอดภัย และมีอย.
          </div>
          <div class="mt-2">
            เลขที่อย.: 10-1-20960-5-0093 (คอลลาเจน)
            <br />
            เลขที่อย.: 10-1-20960-5-0112 (โทเมโท่วิตซี)
            <br />
            เลขที่อย.: 10-1-63000-3-2598 (สครับ)
            <br />
            เลขที่อย.: 11-1-64000-0-2539 (บอดี้เซรั่ม)
            <br />
            เลขที่อย.: 12-1-65000-1-9029 (เซรั่มแก้มใส)
            <br />
          </div>
        </div>
      ),
    },
    {
      question: 'คนท้องสามารถทาได้ไหม',
      answer: (
        <div>
          คุณแม่ที่ตั้งครรภ์สามารถใช้ได้
          เนื่องจากเซรั่มกลอรี่สารสกัดหลักมาจากธรรมชาติ
          และไม่มีสารที่ก่อให้เกิดอันตรายต่อคุณแม่และบุตรค่ะ
        </div>
      ),
    },
  ],
  videoContent: {
    title: 'เซรั่มแก้มใส กลอรี่ สูตรใหม่!',
    subtitle:
      'พิสูจน์!  กลอรี่เซรั่มแก้มใส หน้าใส ลดรอยสิว แบบ FAST SPEED ใน 24 ชั่วโมง ด้วยนวัตกรรม ผิวสปอร์ตไลท์ ลดรอยดำแบบเร่งด่วน!',
    url: 'https://www.youtube.com/watch?v=wTCDAvBaUxc',
  },
  disclaimer: (
    <div>
      1. ใช้สำหรับภายนอกเท่านั้น
      <br />
      2. หากเกิดการระคายเคือง ควรหยุดใช้และปรึกษาแพทย์หรือเภสัชกร
    </div>
  ),
  reviews: [
    {
      image: '/glorycollagen/rv-twitter-1.jpg',
      review: (
        <div>
          จัดคอลลาเจนของ Glory มาอีกแบรนด์ ตัวนี้เป็นคอลลาเจน Di-Peptide นะจ๊ะ
          หลังจากที่ลองไปอาทิตย์นึง
          คือรู้สึกได้ว่าผิวมันดูใสและนุ่มขึ้นกว่าเดิมอ่ะ เป็นดี!
          แนะนำว่าให้ทานคู่กับวิตซีด้วย ช่วยให้เห็นผลไวขึ้นนะ
        </div>
      ),
      name: 'คุณออย',
      product: 'GLCCL1',
    },
    {
      image: '/glorycollagen/rv-twitter-2.jpg',
      review: (
        <div>
          สองสามอาทิตย์ก่อนออกข้างนอกบ่อย ทำให้โดนแดดตลอด
          ผิวคล้ำไปช่วงนึงเลยแหละ แต่เริ่มกิะคอลลาเจนแบรนด์ Glory มาสักพัก
          คนก็เริ่มทักว่าทำไมผิวใส ใสขึ้นจริงๆ มุกทานตัวนี้ตอนเช้าเม็ดเดียวเองนะ
          เป็นแบรนด์ที่กินแล้วเห็นผลไวมาก เลิฟเลย
        </div>
      ),
      name: 'คุณมุก',
      product: 'GLCCL1',
    },
    {
      image: '/glorycollagen/rv-twitter-3.jpg',
      review: (
        <div>
          เมื่อก่อนเริมผิวคล้ำมากแหละเพราะไม่ค่อยดูแลตัวเอง
          อย่างที่บอกช่วงนี้หันมาดูแลตัวเองโดยเฉพาะเรื่องผิว
          ผิวเราไม่ได้ขาวแต่ใสขึ้น ได้เท่านี้ก็คือดีใจสุดแล้ว
          เจอใครก็บอกว่าผิวใสขึ้น ปลื้มมม แนะนำคอลลาเจนตัวนี้เลยงับ "GLORY"
        </div>
      ),
      name: 'คุณจ๊ะ',
      product: 'GLCCL1',
    },
    {
      image: '/glorycollagen/rv-twitter-4.jpg',
      review: (
        <div>
          อยากผิวสวยแต่ไม่ชอบกินน้ำมะเขือเทศ ต้องลอง Glory Tomato Vit C กินไป 1
          สัปดาห์ วันละ 2 เม็ด ตอนเช้า รู้สึกได้เลยว่าผิวใสขึ้นอ่ะ
          แถมยังช่วยเสริมสร้างภูมิคุ้มกันให้เราในช่วงนี้ด้วย
        </div>
      ),
      name: 'คุณตัง',
      product: 'GLCCL1',
    },
    {
      image: '/glorycollagen/rv-twitter-5.jpg',
      review: (
        <div>
          ปกติไม่ค่อยกินอาหารเสริม แต่อยากลอง อยากผิวสวยๆ
          แต่กินมะเขือเทศไม่ได้เลย ทั้งแบบลูก แบบน้ำดอยคำ
          อันนี้กินง่ายดีของแบรนด์ Glory เป็นมะเขือเทศอัดแคปซูล ได้กินวิตามินซี
          กินวันละ 1 แคป ผิวกระจ่างใส ขาวขึ้นนะ
        </div>
      ),
      name: 'คุณขนมผิง',
      product: 'GLCCL1',
    },
    {
      image: '/glorycollagen/rv-twitter-6.jpg',
      review: (
        <div>
          ตอนแรกซื้อมาลอง เห็น packaging สวยดี แต่เห้ย!! เจอตัวนี้อาทิตย์เดียว
          ผิวใสจนเพื่อนทัก ทำกิจกรรมกลางแจ้งก็ไม่กลัวเลย
        </div>
      ),
      name: 'คุณบรัช',
      product: 'GLCCL1',
    },
    {
      image: '/glorycollagen/rv-twitter-9.jpg',
      review: (
        <div>
          เซ็ทคู่ดูแลผิวค้าบ Tomato Vit C + Collagen Dipeptide ใครอยากผิวสวย
          ขาวใส อยากให้ลอง อย่างละ 2 เม็ดทุกเช้า คือผิวดีมาก เลิฟเลย
        </div>
      ),
      name: 'คุณออนนี',
      product: 'GLCCL1',
    },
  ],
  productsIncluded: null,
  relateProducts: [
    'GLRBO1',
    'GLRSR1',
    'GLRSET1',
    'GLRSET6',
    'GLRVC1',
    'GLRSC1',
    'GLRBS1',
  ],
  upsellProducts: ['GLRSC1', 'GLRBS1'],
});
