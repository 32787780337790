import { about } from './about';
import { affiliate } from './affiliate';
import { agent } from './agent';
import { blog } from './blog';
import { faq } from './faq';
import { general } from './general';
import { home } from './home';
import { product } from './product';
import { review } from './review';
import { safety } from './safety';
import { shop, checkoutUpsell, cartUpsell, checkoutCrosssell } from './shop';

export const config = () => {
  return {
    about: about(),
    affiliate: affiliate(),
    agent: agent(),
    blog: blog(),
    faq: faq(),
    general: general(),
    home: home(),
    product: product(),
    review: review(),
    safety: safety(),
    shop: shop(),
    checkoutUpsell: checkoutUpsell(),
    cartUpsell: cartUpsell(),
    checkoutCrosssell: checkoutCrosssell(),
  };
};
