import { Link } from 'preact-router/match';

export const GLRBO3 = () => ({
  url: '/product/baobao-3',
  title: 'กลอรี่เบาเบา 3 กล่อง',
  brand: 'GLORY',
  sku: 'GLRBO3',
  totalSale: 10293,
  price: 1120,
  offerPrice: 1350,
  images: ['/product/GLRBO3.jpg'],
  product: 'เบาเบา 3 กล่อง',
  shortDescription:
    'เครื่องดื่มทางเลือก รสโกโก้เข้มข้น แต่แคลน้อย เพียง 30 Kcal คุมหิว ผิวใส',
  description: (
    <div class="mb-2 text-baseColor">
      <p>
        กลอรี่เบาเบา เครื่องดื่มโกโก้ที่มีคอลลาเจน + พรีไบโอติกส์ + ไฟเบอร์
        ช่วยบล็อกและเบิร์นได้ดี แคลน้อยเพียง 30 KCal ไม่มีน้ำตาล เหมาะกับ...
      </p>
      <ul class="list-disc mt-4 ml-10 text-baseColor">
        <li>ผู้ที่ระบบย่อยอาหารไม่ดี ขับถ่ายยาก</li>
        <li>ผู้ที่ต้องการคุมหิว หิวบ่อย</li>
        <li>ผู้ที่ลดพุง</li>
        <li>ผู้ที่มีผิวคล้ำ ไม่กระจ่างใส</li>
      </ul>
      <p class="mt-2">กลอรี่เบาเบาช่วยให้</p>
      <ul class="list-disc mt-4 ml-10 text-baseColor">
        <li>ระบบขับถ่ายทำงานดีขึ้น อย่างเห็นได้ชัดใน7วัน</li>
        <li>ผิวจะนุ่มชุมชื่นดูผ่องมากขึ้น</li>
        <li>
          สัดส่วนกระชับ เนื่องจากมีการ Block and Burn แป้ง และไขมัน
          ไม่ให้เข้าสู่ร่างกาย
        </li>
        <li>ไขมันรอบเอลลดลงทำให้หุ่นดูเพียวขึ้น</li>
        <li>อัตราการเผาผลาญทำงานดีขึ้น</li>
        <li>ร่างกายสดชื่น สมองกระปรี้ประเปร่า</li>
      </ul>
      <p class="mt-2">
        และเพื่อผลลัพธ์ที่ดีขึ้น ควรทานเป็นประจำอย่างต่อเนื่อง
        ควบคู่กับผลิตภัณฑ์{' '}
        <Link
          href="/product/collagen"
          class="font-bold text-primary hover:text-primaryLight"
        >
          Glory Collagen
        </Link>
        {', '}
        <Link
          href="/product/tomato-vit-c"
          class="font-bold text-primary hover:text-primaryLight"
        >
          Tomato Vit C
        </Link>
        {'และ '}
        <Link
          href="/product/scrub"
          class="font-bold text-primary hover:text-primaryLight"
        >
          Scrub
        </Link>
      </p>
    </div>
  ),
  options: null,
  guarantee: {
    title: 'คุณภาพจากกลอรี่',
    description: (
      <div>
        เพราะความสวยสร้างได้
        กลอรี่ผลิตภัณฑ์คุณภาพที่ออกแบบมาเพื่อให้คุณมีผิวสวยใส ไร้สิว
        จากภายในสู่ภายนอก
      </div>
    ),
  },
  ingredients: [
    {
      image: '/ingredient/baobao1.png',
      title: 'Cocoa Powder (ผงโกโก้)',
      description: (
        <div>
          เร่งสลายไขมันที่สะสมในชั้นผิวสารสกัดจากพืชธรรมชาติ มีไฟเบอร์ช่วยลด
          Cellulite
          มีความสำคัญกับขบวนการเมตาลิซิมของคาร์โบไฮเดรตและช่วยส่งเสริมการทำงานของอินชูลินในร่างกาย
        </div>
      ),
    },
    {
      image: '/ingredient/baobao2.png',
      title: 'Collagen Hydrolysate (คอลลาเจน ไฮโดรไลเซท)',
      description: (
        <div>
          คอลลาเจน ไฮโดรไลเซต (Collagen Hydrolysate)
          เป็นสารอาหารที่ผ่านขบวนการทางวิทยาศาสตร์
          เพื่อให้ได้คอลลาเจนที่มีขนาดและความยาวสั้นลงนั่นเอง
          ซึ่งดูดซึมเข้าสู่ร่างกายได้ดี
          สามารถนำไปใช้ในกระบวนการสร้างคอลลาเจนให้แก่เซลล์ผิวได้
        </div>
      ),
    },
    {
      image: '/ingredient/baobao3.png',
      title: 'Fructo-Oligosaccharides ',
      description: (
        <div>
          มีคุณสมบัติชะลอการดูดซึมน้ำตาลจากอาหาร ช่วยป้องกันการเกิดโรคเบาหวาน
          โรคหัวใจ โรคหลอดเลือด และโรคอ้วน ฟรุกโตโอลิโกแซคคาไรค์
          ช่วยเสริมสร้างประสิทธิภาพในกระบวนการทำงานของร่างกาย ดังนี้
        </div>
      ),
    },
    {
      image: '/ingredient/baobao4.png',
      title: 'Inulin',
      description: (
        <div>
          อินนูลิน (Inulin) คือ พรีไบโอติก (Prebiotic)
          มีเส้นใยอาหารชนิดหนึ่งที่กระตุ้นการเจริญเติบโตของแบคทีเรียที่มีประโยชน์
          พบตามธรรมชาติในหัวหรือรากของพืช ผัก ผลไม้ และสมุนไพรบางชนิด
          มีคุณสมบัติพิเศษละลายน้ำได้ที่สำคัญมีประโยชน์ต่อสุขภาพมากมาย
          อินนูลินสามารถเพิ่มจำนวนของแบคทีเรียที่ดีในลำไส้ของคุณสามารถช่วยลดจำนวนแบคทีเรียที่ไม่ดีที่สามารถนำไปสู่อาการต่าง
          ๆ รวมถึงการอักเสบและลดการดูดซึมสารอาหารได้อีกด้วย
        </div>
      ),
    },
    {
      image: '/ingredient/baobao5.png',
      title: 'Oat Fiber',
      description: (
        <div>
          เส้นใยข้าวโอ๊ต
          คือส่วนของข้าวโอ๊ตกลุ่มพอลิแซ็กคาไรด์ที่ร่างกายย่อยไม่ได้ เช่น
          บีตา-กลูแคน ซึ่งเชื่อว่าสามารถช่วยลดคอเลสเตอรอลในเลือดได้ทางอ้อม
          โดยเส้นใยอาหารจะช่วยดูดซับน้ำดีและขับออกทางอุจจาระ
          ทำให้ร่างกายต้องสร้างน้ำดีใหม่จากคอเลสเตอรอล จึงช่วยลดคอเลสเตอรอลได้
        </div>
      ),
    },
    {
      image: '/ingredient/baobao6.png',
      title: 'L-Carnitine Fumarate',
      description: (
        <div>
          L- carnitine fumarate คือ
          เป็นกรดอะมิโนที่เกิดขึ้นตามธรรมชาติซึ่งช่วยลำเลียงกรดไขมันเข้าไปในเซลล์ของไมโทคอนเดรีย
          ซึ่งไขมันส่วนเกินสามารถสลายตัวและเปลี่ยนเป็นพลังงาน
        </div>
      ),
    },
    {
      image: '/ingredient/baobao7.png',
      title: 'White Kidney Beans Extract (สารสกัดถั่วขาว)',
      description: (
        <div>
          มีคุณสมบัติเป็นตัวบล็อกแป้ง (Starch Blocker)
          โดยจะจับกับพันธะระหว่างโมเลกุลของแป้ง
          ทำให้เอนไซม์อะไมเลสไม่สามารถย่อยแป้งให้กลายเป็นน้ำตาลได้
          ทำให้อาหารประเภทคาร์โบไฮเดรตหรือแป้งที่เราทานเข้าไปไม่เปลี่ยนเป็นน้ำตาลทั้งหมด
        </div>
      ),
    },
    {
      image: '/ingredient/baobao8.png',
      title: 'Garcinia Cambogia Extract (สารสกัดส้มแขก)',
      description: (
        <div>
          ที่เราเรียกกันทั่วไปว่าสาร HCA
          มีคุณสมบัติช่วยลดไขมันส่วนเกินของร่างกาย
        </div>
      ),
    },
    {
      image: '/ingredient/baobao9.png',
      title: 'Citrus Aurantium Extract (สารสกัดซิตรัสออแรนเทียม)',
      description: (
        <div>
          ช่วยกระตุ้นระบบเผาผลาญอาหารที่ได้รับประทานเข้าไป
          เผาผลาญแคลลอรี่ที่เกินความจำเป็น และเพิ่มพลังงานให้กับร่างกาย
          ช่วยควบคุมและลดความอยากอาหาร
          โดยไม่ก่อให้เกิดผลข้างเคียงต่อระบบการทำงานของหัวใจและระบบประสาทส่วนกลาง
        </div>
      ),
    },
    {
      image: '/ingredient/baobao10.png',
      title: 'Green Tea Extract (สารสกัดชาเขียว)',
      description: (
        <div>เพิ่มการเผาผลาญ ต่อต้านอนุมูลอิสระและช่วยลดคอเรสเตอรอล</div>
      ),
    },
    {
      image: '/ingredient/baobao11.png',
      title: 'Cactus Extract (สารสกัดกระบองเพชร)',
      description: (
        <div>
          กระบองเพชรอุดมด้วยคุณค่าสารอาหารมากมาย ที่มีประโยชน์ในการลดน้ำหนัก
          ลดไขมันและคอเลสเตอรอล ลดระดับน้ำตาล และช่วยต้านอนุมูลอิสระได้อีกด้วย
        </div>
      ),
    },
    {
      image: '/ingredient/baobao12.png',
      title: 'Mango Extract (สารสกัดมะม่วง)',
      description: (
        <div>
          เร่งสลายไขมันที่สะสมในชั้นผิวสารสกัดจากพืชธรรมชาติ มีไฟเบอร์ช่วยลด
          Cellulite
          มีความสำคัญกับขบวนการเมตาลิซิมของคาร์โบไฮเดรตและช่วยส่งเสริมการทำงานของอินชูลินในร่างกาย
        </div>
      ),
    },
  ],
  ingredientSummary: (
    <div>
      <ul>
        <li>Fish Collagen Di-Peptide / คอลลาเจนไดเปปไทด์จากปลา 454 มก</li>
      </ul>
    </div>
  ),
  fdaId: '13-2-12461-2-0085',
  weight: '33 กรัม. 30 แคปซูล',
  faqs: [
    {
      question: 'เบาเบาโกโก้ 1 กล่องมีกี่ซอง',
      answer: <div>1 มี 7 ซอง ทานได้กี่ 7 วัน</div>,
    },
    {
      question: 'วิธีทานโกโก้เบาเบา',
      answer: (
        <div>
          ชงดื่มกับน้ำร้อน 150 ml.
          เพื่อรสชาติที่ดีขึ้นสามารถเติมน้ำแข็งและบุกได้
        </div>
      ),
    },
    {
      question: 'กลอรี่ปลอดภัยไหม มีอย.หรือไม่',
      answer: (
        <div>
          <div>
            สินค้าทุกชิ้นของกลอรี่ ผลิตโดยโรงงานมาตรฐาน ปลอดภัย และมีอย.
          </div>
          <div class="mt-2">
            เลขที่อย.: 10-1-20960-5-0093 (คอลลาเจน)
            <br />
            เลขที่อย.: 10-1-20960-5-0112 (โทเมโท่วิตซี)
            <br />
            เลขที่อย.: 10-1-63000-3-2598 (สครับ)
            <br />
            เลขที่อย.: 11-1-64000-0-2539 (บอดี้เซรั่ม)
            <br />
            เลขที่อย.: 13-2-12461-2-0085 (โกโก้)
          </div>
        </div>
      ),
    },
    {
      question: 'โกโก้เก็บได้นานเท่าไร',
      answer: (
        <div>
          <div>สินค้ามีอายุ 2 ปีนับจากวันที่ผลิต</div>
        </div>
      ),
    },
    {
      question: 'คนกินคีโต ทานได้มั้ย',
      answer: (
        <div>ไม่แนะนำให้ทาน เพราะมีส่วนผสมของซูคาโรส และมีคาร์บ 13 กรัม</div>
      ),
    },
    {
      question: 'คนที่แพ้นมทานได้มั้ย',
      answer: <div>ไม่แนะนำให้ทาน เพราะมีส่วนผสมของนมวัว</div>,
    },
    {
      question: 'คนท้องทานได้มั้ย',
      answer: (
        <div>
          ทานได้ แต่ไม่แนะนำให้ทานเยอะ เพราะมีการเร่งการเผาผลาญ
          แนะนำให้ปรึกษาแพทย์ก่อนรับประทาน
        </div>
      ),
    },
    {
      question: 'สามารถทานแทนมื้อเย็นได้เลยมั้ย',
      answer: <div>แทนได้เย</div>,
    },
    {
      question: 'อายุเยอะทานได้มั้ย',
      answer: (
        <div>
          ทานได้ดีเลย ตัวนี้ช่วยในการดูและระบบประสาท
          ดูแลระบบการไหลเวียนหลอดเลือดหัวใจ คนเป็นเบาหวานก็สามารถทานได้
        </div>
      ),
    },
    {
      question: 'เป็นเบาหวานทานได้มั้ย',
      answer: <div>ทานได้</div>,
    },
    {
      question: 'กินวีแกนทานได้มั้ย',
      answer: <div>ไม่แนะนำให้ทาน เพราะมีส่วนผสมของนมพร่องมันเนย</div>,
    },
    {
      question: 'บล็อกน้ำตาลด้วยมั้ย',
      answer: (
        <div>
          บล็อกแป้งและน้ำตาลได้ดีเลย เพราะมีแอลคาร์นิทีน (L-Carnitine)
          ซึ่งช่วยดักจับไขมันและเปลี่ยนให้เป็นพลังงาน และมีส่วนผสมของกระบองเพชร
          สายพันธุ์ Hoodia จากทะเลทรายแอฟริกา ซึ่งมีปริมาณไฟเบอร์สูงมาก
          ช่วยควบคุมความอยากอาหารและดักจับโมเลกุลไขมันที่ลอยตัวเหนือกระเพาะอาหาร
          ทำให้ไขมันไม่สามารถดูดซึมทางผนังลำไส้เล็ก
          และจะถูกกำจัดออกจากการขับถ่าย
        </div>
      ),
    },
  ],
  videoContent: null,
  disclaimer: <div>1. มีส่วนผสมของโกโก้</div>,
  reviews: null,
  productsIncluded: null,
  relateProducts: ['GLRSET1', 'GLRSC1', 'GLRBS1', 'GLRCL1', 'GLRVC1'],
  upsellProducts: ['GLRSC1', 'GLRBS1'],
});
