import TagManager from 'react-gtm-module';
import { config } from '../config';

export const initialTagManager = () => {
  TagManager.initialize({ gtmId: config().general.gtmId });
};

export const gtmDataLayer = (dataLayer) => {
  TagManager.dataLayer({ dataLayer });
};
