export const safety = () => {
  return {
    title: 'มาตรฐานและความปลอดภัย',
    fda: {
      title: 'ใบสำคัญการจดทะเบียนอาหาร',
      images: [
        '/glorycollagen/collagen-certificate-1.jpg',
        '/glorycollagen/collagen-certificate-2.jpg',
        '/glorycollagen/tomato-certificate-1.jpg',
        '/glorycollagen/tomato-certificate-2.jpg',
        '/glorycollagen/scrub-certificate-1.jpg',
      ],
    },
  };
};
