export const home = () => {
  return {
    meta: {
      title: 'Glory - Glow Your Ordinary',
      description: 'ลดสิว ผิวใส ด้วยกลอรี่คอลลาเจน และ Tomato Vit C',
    },
    mainProduct: {
      title: 'Glory',
      description:
        'ลดสิว ผิวใส ด้วยเซ็ตคู่กู้สิวผิวใส Glory Collagen + Tomato Vit C',
      tag: 'เทียบเท่ามะเขือเทศ 34 ลูก',
      image: '/glorycollagen/glc-set-1-home-4.jpg',
      url: '/product/set-1',
    },
    benefit: {
      title: 'ผิวสวยสร้างได้',
      description: (
        <div>
          เรารู้ว่าจุดเริ่มต้นของผิวใส ต้องเริ่มจากภายในสู่ภายนอก
          แบรนด์กลอรี่ได้ออกแบบมาเพื่อสร้างผิวสวยแบบครบสูตรด้วยผลิตภัณฑ์ดังต่อไปนี้
          <ul class="mt-2 list-disc ml-6">
            <li>
              <span class="font-bold">Glory Collagen</span> คอลลาเจนลดสิว ผิวใส
            </li>
            <li>
              <span class="font-bold">Tomatio Vit C</span> วิตามินซีจากมะเขือเทศ
              34 ลูก
            </li>
            <li>
              <span class="font-bold">Glory Scrub</span> สครับเร่งผลัดเซลล์ผิว
            </li>
            <li>
              <span class="font-bold">Glory Body Serum</span>{' '}
              บอดี้เซรั่มบำรุงผิว
            </li>
          </ul>
        </div>
      ),
      image: '/glorycollagen/glc-home-all-product.jpg',
    },
    recommendProduct: {
      title: 'ชุดเริ่มต้นผิวใส',
      description: (
        <div>
          เติมผิวให้ชุ่มชื้น นุ่มลื่น ลดเรือนริ้วรอย ลดฝ้ากระ ปกป้องคุณจากแสงแดด
        </div>
      ),
      image: '/glorycollagen/glc-set-2-home-5.png',
      url: '/product/set-6',
    },
    reviewImageProduct: '/glorycollagen/glory-scrub-transparent.png',
    reviews: [
      {
        image: '/glorycollagen/rv-twitter-1.jpg',
        review: (
          <div>
            จัดคอลลาเจนของ Glory มาอีกแบรนด์ ตัวนี้เป็นคอลลาเจน Di-Peptide นะจ๊ะ
            หลังจากที่ลองไปอาทิตย์นึง
            คือรู้สึกได้ว่าผิวมันดูใสและนุ่มขึ้นกว่าเดิมอ่ะ เป็นดี!
            แนะนำว่าให้ทานคู่กับวิตซีด้วย ช่วยให้เห็นผลไวขึ้นนะ
          </div>
        ),
        name: 'คุณออย',
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-twitter-2.jpg',
        review: (
          <div>
            สองสามอาทิตย์ก่อนออกข้างนอกบ่อย ทำให้โดนแดดตลอด
            ผิวคล้ำไปช่วงนึงเลยแหละ แต่เริ่มกิะคอลลาเจนแบรนด์ Glory มาสักพัก
            คนก็เริ่มทักว่าทำไมผิวใส ใสขึ้นจริงๆ
            มุกทานตัวนี้ตอนเช้าเม็ดเดียวเองนะ เป็นแบรนด์ที่กินแล้วเห็นผลไวมาก
            เลิฟเลย
          </div>
        ),
        name: 'คุณมุก',
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-twitter-3.jpg',
        review: (
          <div>
            เมื่อก่อนเริมผิวคล้ำมากแหละเพราะไม่ค่อยดูแลตัวเอง
            อย่างที่บอกช่วงนี้หันมาดูแลตัวเองโดยเฉพาะเรื่องผิว
            ผิวเราไม่ได้ขาวแต่ใสขึ้น ได้เท่านี้ก็คือดีใจสุดแล้ว
            เจอใครก็บอกว่าผิวใสขึ้น ปลื้มมม แนะนำคอลลาเจนตัวนี้เลยงับ "GLORY"
          </div>
        ),
        name: 'คุณจ๊ะ',
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-twitter-4.jpg',
        review: (
          <div>
            อยากผิวสวยแต่ไม่ชอบกินน้ำมะเขือเทศ ต้องลอง Glory Tomato Vit C กินไป
            1 สัปดาห์ วันละ 2 เม็ด ตอนเช้า รู้สึกได้เลยว่าผิวใสขึ้นอ่ะ
            แถมยังช่วยเสริมสร้างภูมิคุ้มกันให้เราในช่วงนี้ด้วย
          </div>
        ),
        name: 'คุณตัง',
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-twitter-5.jpg',
        review: (
          <div>
            ปกติไม่ค่อยกินอาหารเสริม แต่อยากลอง อยากผิวสวยๆ
            แต่กินมะเขือเทศไม่ได้เลย ทั้งแบบลูก แบบน้ำดอยคำ
            อันนี้กินง่ายดีของแบรนด์ Glory เป็นมะเขือเทศอัดแคปซูล
            ได้กินวิตามินซี กินวันละ 1 แคป ผิวกระจ่างใส ขาวขึ้นนะ
          </div>
        ),
        name: 'คุณขนมผิง',
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-twitter-6.jpg',
        review: (
          <div>
            ตอนแรกซื้อมาลอง เห็น packaging สวยดี แต่เห้ย!! เจอตัวนี้อาทิตย์เดียว
            ผิวใสจนเพื่อนทัก ทำกิจกรรมกลางแจ้งก็ไม่กลัวเลย
          </div>
        ),
        name: 'คุณบรัช',
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-twitter-9.jpg',
        review: (
          <div>
            เซ็ทคู่ดูแลผิวค้าบ Tomato Vit C + Collagen Dipeptide ใครอยากผิวสวย
            ขาวใส อยากให้ลอง อย่างละ 2 เม็ดทุกเช้า คือผิวดีมาก เลิฟเลย
          </div>
        ),
        name: 'คุณออนนี',
        product: 'GLCCL1',
      },
      {
        image: '/glorycollagen/rv-twitter-7.jpg',
        review: (
          <div>
            ใครไม่ชอบกินน้ำมะเขือเทศดอยคำ แต่อยากผิวขาวหน้าใส ลอง Vit C ของ
            Glory ดูค่า เป็นมะเขือเทศอัดเม็ด 1 แคปซูล
            ส่วนตัวจูนทานมาได้สักพักผิวมันใสขึ้น แต่มันยังไม่ถึงกับขาวนะ
            อาจต้องกินต่ออีก แต่หน้าดูขาวใสขึ้นเร็วจริง
            และสิวหรือรอยแดงจางลงด้วย
          </div>
        ),
        name: 'คุณจูน',
        product: 'GLCVC1',
      },
      {
        image: '/glorycollagen/rv-twitter-8.jpg',
        review: (
          <div>
            ชอบกินวิตามินซีกับน้ำมะเขือเทศคู่กัน 1 เม็ด เหมีอนได้กินมะเขือเทศ 34
            ผลเลย ช่วงที่รักษาสิวอาทิตย์เดียวยิ่งเห็นชัดเลยว่า
            รอยสิวหายไวมากกจนแฟนทัก ผิวหายหมอง แก้มใสๆ ขึ้น
          </div>
        ),
        name: 'คุณโอป',
        product: 'GLCVC1',
      },
      {
        image: '/glorycollagen/rv-twitter-10.jpg',
        review: (
          <div>
            เพิ่งมาสังเกตตอนที่เพื่อนทักว่าผิวดูใส ขาวขึ้น
            นี่ไม่ได้คาดหวังอะไรมากตอนที่กิน Vit C กับคอลลาเจนคู่นี้
            แต่ผลที่ได้หลังกินต่อเนื่องก็คือ ผิวดีขึ้นจริง นี่ชอบ Vit C
            ส่วนผสมจัดเต็ม เหมาะกับคนที่อยากผิวใสแต่กินดอยคำไม่ไหว
            เห็นว่าได้เรื่องสิวต่างๆ ด้วย ครบมาก!
          </div>
        ),
        name: 'คุณไหม',
        product: 'GLCVC1',
      },
      {
        image: '/glorycollagen/rv-twitter-11.jpg',
        review: (
          <div>
            เป็นคนผิวแทนที่กินกลูต้ากับคอลลาเจนตลอด ไม่ได้ซีเรื่องความขาว
            แต่เวลากินพวกนี้แล้วผิวจะใสมาก ถ่ายรูปออกมาก็ดูผิวเงาสุขภาพดีทั้งๆ
            ที่ไม่ได้ทาครีมหรืออะไรเลย ตอนนี้กินคอลลาเจนกับมะเขือเทศอัดเม็ดของ
            Glory คู่กันอยู่ ผิวเนียนใสมาก
          </div>
        ),
        name: 'คุณเมล่อน',
        product: 'GLCVC1',
      },
    ],
    guarantee: {
      title: 'คุณภาพจากกลอรี่',
      description: (
        <div>
          เพราะความสวยสร้างได้
          กลอรี่ผลิตภัณฑ์คุณภาพที่ออกแบบมาเพื่อให้คุณมีผิวสวยใส ไร้สิว
          จากภายในสู่ภายนอก
        </div>
      ),
      image: '/glorycollagen/glory-collagen-home-transparent-5.png',
      cta: 'เกี่ยวกับกลอรี่',
      url: '/about',
    },
  };
};
