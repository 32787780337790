import { config } from '../config';

export const getSkuList = () => sku;

export const getSkus = () => {
  const s = config().product.reduce((arr, p) => {
    arr[p.sku] = p;
    return arr;
  }, {});
  return s;
};

export const getSku = (s) => {
  return config().product.find((r) => r.sku === s);
};

export const getSkuBySlug = (id) => {
  const url = '/product/' + id;
  return config().product.find((r) => r.url === url).sku;
};
