import { LazyImage, Video } from '../../components/lazy-image';

export const content002 = () => {
  return {
    url: '/blog/review-glory-nuttyploy',
    title: 'อาหารผิว กู้ผิวใส ฉบับเร่งด่วน',
    author: 'Nuttyploy',
    content: (
      <div>
        <Video url="https://www.youtube.com/watch?v=ccpOhC2xwwE" />
        <p class="mt-4">
          สวัสดีจ้าเจอกันอีกแล้วน้า คลิปนี้เป็นคลิปที่ถูกขอมาเยอะมาก
        </p>
        <p class="mt-2">
          คือบอกก่อนเลยว่า นัตตี้ไม่ได้คาดหวังกับตัวนี้ตั้งแต่ตอนเริ่มกิน
          ก็เลยไม่ค่อยได้เก็บวิดีโอ หรือภาพในช่วงแรกๆ
          นัตตี้เริ่มกินมาตั้งแต่ช่วงเดือน ก.ค. ซึ่งก็ทดลองนานอยุ่เหมือนกัน
          ก.ค.-ก.ย. ก็ประมาณ 3 เดือน ที่นัตตี้ได้ทดลองทานอาหารผิวตัวนี้นะคะ
        </p>
        <p class="mt-2">
          จริงๆ นัตตี้เป็นคนที่ไม่ค่อยมารีวิวอาหารผิว
          หรือว่าพวกอาหารเสริมสักเท่าไหร่ เพราะพวกนี้ต้องใช้เวลาจริงๆ
          เพราะถ้าไม่เห็นผลอ่า ก็ไม่รู้จะมาอธิบายมาพูด มาบอกเล่าสู่กันฟังยังไง
          แต่ว่าตัวที่จะมาพูดถึงวันนี้คือ นัตตี้ลองเองมาประมาณ 3 เดือนแล้ว
          และเห็นผลจริง โดนส่วนตัวของนัตตี้เอง
        </p>
        <div class="my-4">
          <LazyImage src="/glorycollagen/review-natty-8.jpg" />
          <div class="mt-2 text-center text-sm italic">สภาพผิวก่อนทาน</div>
        </div>
        <p class="mt-2">
          นัตตี้ชอบถ่ายหน้าสดตัวเองเก็บไว้ เพราะชอบดูการเปลี่ยนแปลงแต่ละเดือน
          แต่ละวันอยู่แล้ว ช่วงนี้เราใช้อันนี้ ช่วงนี้เรากินอันนี้
          ก็จะแบบย้อนกลับมาดูว่า มันดีมันเห็นผลจริงๆ
        </p>
        <p class="mt-2">
          ช่วง ส.ค. ก่อนทานหน้าจะเป็นสิว มีรอยดำ รอยแดงจากสิว
          ขนาดใช้คอลซีลเลอร์แล้วนะ ก็ยังคงมีอยูู่บ้างเล็กน้อย พอเริ่มทานแล้ว
          ช่วงใกล้มีประจำเดือนปกติจะมีสิวขึ้น แต่ว่าครั้งนี้สิวไม่ขึ้นเลย
        </p>
        <div class="my-4">
          <LazyImage src="/glorycollagen/review-natty-6.jpg" />
          <div class="mt-2 text-center text-sm italic">สภาพผิวหลังทาน</div>
        </div>
        <p class="mt-2">
          ช่วงกลางเดือน ก.ย. เหมือนผิวเด็ก หลังทาสกินแคร์ แทบไม่ได้ลงรองพื้นเลย
          ทาแค่ครีมกันแดด แล้วตบด้วยแป้งฝุ่น จะได้ผิวที่เป็นงานผิวเลยอ่ะ
          แฮปปี้มาก
        </p>
        <p class="mt-2">
          1 ต.ค. หมดไปแล้วอย่างละ 2 ซอง กำลังจะขึ้นซองที่ 3 รู้สึกชอบมากก
          สำหรับอาหารผิวตัวนี้
        </p>
        <div class="my-4">
          <LazyImage src="/glorycollagen/review-natty-5.jpg" />
        </div>
        <p class="mt-2">
          ตัวที่นัตตี้จะมารีวิวคือ <b>Glory</b> ค่ะ ทั้งตัวชมพูแล้วก็ตัวสีส้ม
          ซองละ 60 แคปซูลนะ !! ละคือนัตตี้กินหมดไปแล้ว 4 ซองอ่ะ
        </p>
        <p class="mt-2">
          วันนี้ก็เลยโชว์หน้าสด หลายคนที่ติดตามนัตตี้ จะรู้ว่า ผิวเป็นสิวง่ายมาก
          มีจุดด่างดำให้เห็นกันอยู่บ่อยครั้ง แต่ช่วงนี้มีคนสังเกตว่า
          ผิวนัตตี้มันดีขึ้นมากๆ โดยส่วนตัวคือแฮปปรี้กับผิวในตอนนี้
        </p>
        <p class="mt-2">
          ถ้าถามว่าสิวอ่ะมีไหม ก็ยังคงมีอยูู่บ้างเล็กน้อย
          แต่มันน้อยกว่าแต่ก่อนมากๆ ถ้าจะให้บอกไม่ขึ้นเลย 100%
          มันก็เป็นไปค่อนข้างยากอยู่ เพราะเป็นคนผิวมัน ก็เลยต้องมีสิวขึ้นบ้าง
          และเป็นคนที่แต่งหน้าเป็นประจำอยู่แล้ว
        </p>
        <div class="my-4">
          <LazyImage src="/glorycollagen/review-natty-2.jpg" />
        </div>
        <div class="mt-1 text-center text-sm italic">
          กลอรี่คอลลาเจนและโทเมโท่วิตซี
        </div>
        <p class="mt-2">
          มาเริ่มกันเลยดีกว่า สิ่งที่จะมารีวิววันนี้คือ อาหารผิวนะคะ
          ทำไมถึงไม่เรียกว่าอาหารเสริม ซึ่งก็เคยไปคุยกับน้องเชอรี่
          เจ้าของแบรนด์มาแล้วนะ
        </p>
        <p class="mt-2">
          เค้าค่อนข้างที่จะไม่โอเคกับอาหารเสริมอยู่ในช่วงนั้น
          เพราะไปทานอาหารเสริมอะไรก็ไม่รู้แล้วแพ้มา เลยมาทำเป็นอาหารผิวของตัวเอง
          ที่มีความปลอดภัย และทุกคนสามารถทานได้แม้แต่เด็ก
        </p>
        <p class="mt-2">
          อย่างที่นัตตี้บอกว่า ไม่ได้คาดหวังตั้งแต่ทานตอนแรก ทานไปทุกวัน
          ใช้ชีวิตประจำวันเหมือนเดิมทุกอย่าง เพิ่งมาสังเกตตัวเอง แบบบ ฮึ่ยยยยยย
          “ทำไมสิวมันไม่ค่อยขึ้น” ในเมื่อชั้นทำทุกอย่างเหมือนเดิม
        </p>
        <p class="mt-2">
          แล้วก็มีสิ่งนึงที่เพิ่มมาใหม่ คืออาหารผิวที่ทาน
          ไม่รู้เลยว่ามันช่วยในเรื่องของสิวได้ด้วย
          คิดว่าทำให้ผิวของเรามีความเด้ง ผิวใสขึ้นแค่นั้น
          น้องบอกถ้าทานคู่กันจะช่วยในเรื่องของสิวได้ดีมากเลยค่ะพี่
          เราก็แบบเห้ยจริงอ่อ
        </p>
        <div class="my-4">
          <LazyImage src="/glorycollagen/review-natty-3.jpg" />
        </div>
        <div class="mt-2 text-center text-sm italic">
          คอลลาเจนนำเข้าจากญี่ปุ่น
        </div>
        <p class="mt-2">
          มาพูดถึงตัวนี้ก่อนเลยค่ะ <i>glory ไดเปปไทด์ </i>เป็นคอลลาเจน เพียว
          เพียว ที่ร่างกายเราต้องการ คือคอลลาเจนที่นำเข้ามาจากประเทศญี่ปุ่น
          เป็นคอลลาเจนที่ทานแล้วเห็นผลจริง
          เค้าไปสรรหาคอลลาเจนที่มีโมเลกุลคล้ายกับคอลลาเจนในผิวของเรามากที่สุด
          และเป็นสายพันธ์ปลาที่ดีที่สุดจากญี่ปุ่น เค้าการันตีผลเลยว่า
          ถ้าเราทานเนี่ย เช้า 2 เม็ด เย็น 2 เม็ดเนี่ย จะเห็นผลภายใน 7 วัน
        </p>
        <div class="my-4">
          <LazyImage src="/glorycollagen/review-natty-13.jpg" />{' '}
        </div>
        <p class="mt-2">
          ถามว่าคอลลาเจนสำคัญกับผิวของเราอย่างไร คือปกติคนเราตั้งแต่เกิดมา
          ร่างกายจะมีการผลิตคอลลาเจนอยู่แล้ว เวลาที่เราเป็นแผล
          หรือเวลาที่เรานอนดึก เราก็ไม่ได้โทรมเท่าไหร่
          คือร่างกายของเรายังมีคอลลาเจน ที่เพียงพอต่อร่างกายของเราอยู่
          แต่เมื่อเราโตขึ้น อายุมากขึ้น จะเห็นว่าผิวเรามีความทรุดโทรม เหี่ยว
          ริ้วรอย หรือเรียกง่ายๆ คือ แก่นั้นเอง{' '}
        </p>
        <p class="mt-2">
          แคปซูลเค้าจะมาเป็นสีชมพูอ่อนๆ เป็นคอลลาเจนเพียว 100% ไม่มีสี
          ไม่มีกลิ่น ไม่เติมสารเจือปนต่างๆ เลย
        </p>
        <ul class="list-disc mt-1 ml-10 text-baseColor">
          <li>
            ทานไป 1 สัปดาห์แรก จะเห็นผลของผิวลื่นขึ้น เนียนขึ้น แข็งแรงขึ้น
          </li>
          <li>
            สัปดาห์ที่ 2 ผิวจะใสขึ้น แบบประมาณเห็นเส้นเลือดอ่ะ
            เหมือนร่างกายเราสะอาดขึ้น
          </li>
          <li>สัปดาห์ที่ 3 ผิวแลดูกระจ่างใสขึ้น </li>
          <li>
            สัปดาห์ที่ 4 ช่วยลดเลือนริ้วรอย ผิวใสและแข็งแรง อย่างต่อเนื่อง
          </li>
        </ul>
        <p class="mt-2">
          นัตตี้ชอบมาก เพราะถ้าผิวเราแข็งแรง เวลาที่เราไปเจอแสงแดด
          อย่างนัตตี้มีวิ่งช่วงเช้าทั้งช่วง 4-5 โมงเย็น ก็ยังมีแดดอยู่
          ถ้าผิวเราไม่แข็งแรงมันจะทำให้ผิวเราคล้ำเร็ว คล้ำง่าย
        </p>
        <p class="mt-2">
          หลังจากที่ได้ลองทานมาประมาณ 3 เดือน ก็เห็นผลตามที่เค้าว่าเลย ผิวเนี่ย
          ใสขึ้น นัตตี้ไม่ได้ซีเรียสว่าจะต้องขาวอะไร ขอแค่ผิวใสขึ้น ดูมีออร่า
          นัตตี้ไม่ได้เป็นพวกคนขาว และไม่ได้สภาพผิวดีมาแต่เกิด
          ซองสีชมพูจะป้องกันสิว
          ซองสีส้มจะเป็นตัวรักษารอยแดงรอยดำที่เกิดจากสิวนั่นเอง
        </p>
        <div class="my-4">
          <LazyImage src="/glorycollagen/review-natty-1.jpg" />
        </div>
        <div class="mt-1 text-center text-sm italic">ลดรอยสิว ผิวกระจ่างใส</div>
        <p class="mt-2">
          มาดูซองสีส้มก็หมดไปแล้ว 2 ซองเหมือนกัน
          <i>ตัวนี้เป็นวิตซีที่ได้มาจากมะเขือเทศ</i> แบบอัดแน่นเต็มเม็ดเลยนะคะ
          คุณสมบัติเค้าบอกว่า เสริมภูมิคุ้มกัน ป้องกันแสงแดด ผิวกระจ่างใส
          ช่วยลดรอยสิว ทำไมต้องกินเป็น 2 ซอง คุ่กัน
          คือเค้าจะช่วยกันทำงานให้ตัวอาหารผิวมีประสิทธิภาพมากยิ่งขึ้น
        </p>
        <div class="my-4">
          <LazyImage src="/glorycollagen/review-natty-10.jpg" />{' '}
        </div>
        <div class="mt-1 text-center text-sm italic">
          1 แคปซูลเทียบเท่ามะเขือเทศ 34 ผล
        </div>
        <p class="mt-2">
          วิตซีจะช่วยเรื่องให้ผิวเรากระจ่างใสขึ้น ผิวใสขึ้น
          ช่วยลดรอยดำรอยแดงจากสิวด้วย ช่วยป้องกันแสงแดดที่เราเจอทุกวัน
          จากภายในของเรา อย่างนัตตี้ช่วงเดือนแรก ทานเช้า 2 เม็ด เย็น 2 เม็ดเลย
          คืออัดแน่น ตอนแรกก็กังวล เลยไปถามน้องเชอรี่ น้องบอกทานได้ไม่มีอันตราย
          การันตีความปลอดภัยแน่นอน{' '}
        </p>
        <p class="mt-2">
          พอเดือนต่อๆ มา รู้สึกว่าผิวเราดูโอเคแล้ว เลยเปลี่ยนไปทานแบบ เช้า 1
          เย็น 1 ซึ่งมะเขือเทศที่เลือกมาก็นำเข้าจากสวิตเซอแลนด์
        </p>
        <p class="mt-2">
          ทั้งนี้ทั้งนั้นผลลัพธ์ขึ้นอยู่กับแต่ละคน
          และที่สำคัญทานอาหารผิวหรืออาหารเสริม ควรดื่มน้ำเยอะๆ นะคะ วันละ 2 ลิตร
          ขึ้นไป 3 ลิตรได้ยิ่งดี
        </p>
      </div>
    ),
    relateProducts: ['GLRSET1', 'GLRCL1', 'GLRVC1'],
  };
};
