import { Link } from 'preact-router/match';

export const GLRBS1 = () => ({
  url: '/product/body-serum',
  title: 'คอลลาเจนบอดี้เซรั่ม',
  brand: 'GLORY',
  sku: 'GLRBS1',
  totalSale: 9872,
  price: 390,
  offerPrice: 590,
  images: ['/product/GLRBS1.jpg', '/product/GLRBS1-b.jpg'],
  product: 'คอลลาเจนบอดี้เซรั่ม 1 หลอด',
  shortDescription: 'บอดี้เซรั่มสูตรคอลลาเจน บำรุงผิวฟู นุ่มใส',
  description: (
    <div class="mb-2 text-baseColor">
      <p>
        Judy Charotte คอลลาเจนบอดี้เซรั่มบำรุงผิว ช่วยให้ผิวนุ่มลื่น ชุ่มชื้น
        มีออร่าใส ด้วย 4 สารสกัดคือ คอลลาเจน, ไฮยาลูรอนิกแอซิด, สวิทอัลมอลด์ออย
        และเมือกหอยทาก พร้อมด้วยกลิ่นน้ำหอมระดับ Hi-End ชั้นนำ ติดทนนาน
        ช่วยให้คุณดูมีเสน่ห์ ชวนหลงใหลทุกมิติ
      </p>
      <p class="mt-2">
        และเพื่อผลลัพธ์ที่ดีขึ้น ควรทานเป็นประจำอย่างต่อเนื่อง
        ควบคู่กับผลิตภัณฑ์{' '}
        <Link
          href="/product/collagen"
          class="font-bold text-primary hover:text-primaryLight"
        >
          Collagen
        </Link>
        {', '}
        <Link
          href="/product/tomato-vit-c"
          class="font-bold text-primary hover:text-primaryLight"
        >
          Tomato Vit C
        </Link>
        {', '}และ{' '}
        <Link
          href="/product/body-serum"
          class="font-bold text-primary hover:text-primaryLight"
        >
          Body Serum
        </Link>
      </p>
      <ul class="list-disc mt-4 ml-10 text-baseColor">
        <li>ครีมบำรุงผิวเนื้อเซรั่ม บำรุงผิวล้ำลึกถึงชั้นใต้ผิว</li>
        <li>ผิวเนียนนุ่มชุ่มชื้นทันทีหลังใช้</li>
        <li>สัมผัสผิวเนียน ขาว กระจ่างใส เมื่อใช้เป็นประจำ</li>
        <li>ไม่มีพาราเบน ไม่มีแอลกอฮอร์</li>
      </ul>
    </div>
  ),
  options: null,
  guarantee: {
    title: 'คุณภาพจากกลอรี่',
    description: (
      <div>
        เพราะความสวยสร้างได้
        กลอรี่ผลิตภัณฑ์คุณภาพที่ออกแบบมาเพื่อให้คุณมีผิวสวยใส ไร้สิว
        จากภายในสู่ภายนอก
      </div>
    ),
  },
  ingredients: [
    {
      image: '/ingredient/collagen.png',
      title: 'Hydrolyzed Collagen',
      description: (
        <div>
          คอลลาเจนเสริม ที่ช่วยเสริมความแข็งแรงให้กับคอลลาเจนในชั้นผิว
          ช่วยให้เซลล์ผิวมีความยืดหยุ่น อุ้มน้ำ และผิวดูเต่งตึงขึ้น
        </div>
      ),
    },
    {
      image: '/ingredient/almon.png',
      title: 'Prunus Amygdalus Dulcis Oil (น้ำมันอัลมอลต์)',
      description: (
        <div>
          เป็นน้ำมันที่อุดมไปด้วยวิตามินและแร่ธาตุมากมายหลากหลายชนิด เช่น Folic
          Alpha tocopherol (Vitamin E) Zinc
          ช่วยในเรื่องรักษาอาการผิดปกติของผิวหนัง มี Vitamin A B1 B2 B6 D และ
          Glycoside ที่ช่วยฟื้นฟูบำรุงผิวหนัง และยังมีกรดไขมันจำเป็นอย่าง
          Linoleic (Omega-6) ถึง 30% ซึ่งช่วยลดการสูญเสียน้ำภายใต้ผิวหนัง
        </div>
      ),
    },
    {
      image: '/ingredient/snail.png',
      title: 'Snail Secretion Filtrate (สารสกัดเมือกหอยทาก)',
      description: (
        <div>
          เปี่ยมด้วยคุณสมบัติในการต่อต่านอนุมูลอิสระ เกราะป้องกันผิวอ่อนล้า
          และปัญหาจากมลภาวะที่ต้องพบเจอระหว่างวัน พร้อมมอบความชุ่มชื่น
          ด้วยการเก็บกักน้ำในชั้นผิวให้มีความสมดุลทั้งวัน ผิวจึงกระชับ เรียบ ตึง
          ใส
        </div>
      ),
    },
    {
      image: '/ingredient/castor.png',
      title: 'Castor Oil (น้ำมันละหุ่ง)',
      description: (
        <div>
          ลดการอักเสบของผิวมักเกิดจากสาเหตุต่างๆ เช่น ผิวถูกเผาด้วยแสงแดด
          ผิวขาดความชุ่มชื้น และสาเหตุจากการเกิดสิว
        </div>
      ),
    },
    {
      image: '/ingredient/imperata.png',
      title:
        'Imperata Cylindrica Root Extract (สารสกัดจากรากต้นหญ้า Imperata Cylindrica)',
      description: (
        <div>
          ช่วยเพิ่มความชุ่มชื้นให้แก่ผิวได้ยาวนาน พร้อมกระตุ้นการสร้างคอลลาเจน
          ช่วยให้ผิวกระชับ เรียบเนียนน่าสัมผัส
        </div>
      ),
    },
    {
      image: '/ingredient/biotin.png',
      title: 'Biotin (ไบโอติน)',
      description: (
        <div>
          เสริมสร้างโครงสร้างของเคราตินซึ่งเป็นส่วนประกอบสำคัญของผิวหนัง
          ช่วยเสริมสร้างผิวหนังให้ชุ่มชื้น แข็งแรง บรรเทาอาการแพ้
          ผดอักเสบของผิวหนัง
        </div>
      ),
    },
  ],
  ingredientSummary: (
    <div>
      Aqua (Water), Glycerin, Ammonium Polyacryloyldimethyl Taurate,
      Phenoxyethanol, Butylene Glycol, Niacinamide, PPG-26-Buteth-26,
      Coprylic/Capric Triglyceride, Cetearyl Olivate, PEG-40 Hydrogenated Castor
      Oil, Polysorbate 20, Chlorphenesin, Sorbitan Olivate, Parfum (Fragrance),
      Imperata Cylindrica Root Extract, Disodium EDTA, Thymus Serpylium Extract,
      Sodium Hyaluronate, Caprylyl Glycol, Carbomer, Sodium Citrate, 3-O-Ethyl
      Ascorbic Acid, Prunus Amygdalus Dulcis (Sweet Almond) Oil, Snail Secretion
      Filtrate, Acrylates/C10-30 Alkyl Acrylate Crosspolymer, Citric Acid,
      Hydrolyzed Collagen, Biotin
    </div>
  ),
  fdaId: '11-1-6300014480',
  weight: '15 มล.',
  faqs: [
    {
      question: 'วิธีทาคอลลาเจนบอร์ดี้เซรั่ม',
      answer: (
        <div>
          <div>
            ลูบไล้เซรั่มทั่วร่างกายหลังอาบน้ำเป็นประจำ
            และเพื่อผลลัพธ์ที่ดีขึ้นควรใช้บอร์ดี้เซรั่มคู่กับกลอรี่สครับ
          </div>
        </div>
      ),
    },
    {
      question: 'กลอรี่ปลอดภัยไหม มีอย.หรือไม่',
      answer: (
        <div>
          <div>
            สินค้าทุกชิ้นของกลอรี่ ผลิตโดยโรงงานมาตรฐาน ปลอดภัย และมีอย.
          </div>
          <div class="mt-2">
            เลขที่อย.: 10-1-20960-5-0093 (คอลลาเจน)
            <br />
            เลขที่อย.: 10-1-20960-5-0112 (โทเมโท่วิตซี)
            <br />
            เลขที่อย.: 10-1-63000-3-2598 (สครับ)
            <br />
            เลขที่อย.: 11-1-64000-0-2539 (บอดี้เซรั่ม)
            <br />
          </div>
        </div>
      ),
    },
  ],
  videoContent: {
    title: 'สิวหาย ออร่ากระจาย',
    subtitle:
      'หมดปัญหาสิว ผิวโกลว์สุด เคล็ดลับทำให้หน้าเนียน ผิวดี ด้วย Glory Collagen',
    url: 'https://www.youtube.com/watch?v=rDbLAJNC4pU',
  },
  disclaimer: (
    <div>
      1. ใช้สำหรับภายนอกเท่านั้น
      <br />
      2. หากเกิดการระคายเคือง ควรหยุดใช้และปรึกษาแพทย์หรือเภสัชกร
    </div>
  ),
  reviews: null,
  productsIncluded: null,
  relateProducts: ['GLRSR1', 'GLRSET6', 'GLRSET1', 'GLRSET20'],
  upsellProducts: ['GLRSC1', 'GLRBS1'],
});
