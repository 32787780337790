import { LazyImage, Video } from '../../components/lazy-image';

export const content001 = () => {
  return {
    url: '/blog/review-glory-yipso',
    title: 'เคล็ดลับผิวใส ไร้สิวด้วย Glory Collagen',
    author: 'คุณยิปโซ คุณปาย และคุณเนะ',
    content: (
      <div>
        <Video url="https://www.youtube.com/watch?v=rDbLAJNC4pU" />
        <p class="mt-4">
          วันนี้พลอยนำคลิปที่สาวๆ หลายคนชอบและติดตาม 3 สาว 3 สไตล์มาฝากกันนะคะ
          ไปดูกันค่ะว่า พี่ยิปซี พี่ปาย และพี่เนะ จะมาเล่าเรื่องอะไรกันน้าาา
        </p>
        <p class="mt-2">
          วันนี้เราจะมาคุยเรื่องผิว งานผิวกันนะคะ คลิปที่ทุกคนถามมาตลอดว่า พี่ๆ
          ทำยังไงให้สวยคะ? พี่ๆ ทำยังไงให้หน้าใสคะ? พี่ๆ ไม่เคยมีสิวหรอคะ?
          อยากจะบอกว่า "พวกเรามีสิวนะจ๊ะ"
        </p>
        <p class="mt-2">
          <b>ปาย</b> : เราจะมาว่ากันด้วยเรื่องผิวโดยรวมทั้งหมด
          ว่าพวกเราดูแลผิวยังไง โดยเฉพาะวิตามินต่างๆ จากภายในสู่ภายนอก
          และเคล็ดลับความโกลว์ของผิว
        </p>
        <p class="mt-2">
          <b>เนะ</b> : งั้นมาเริ่มที่ พวกมึงเคยแพ้อะไรจนหน้าแหกไหม
        </p>
        <p class="mt-2">
          <b>ปาย</b> : หูววววว ตอนเราแพ้ เราแพ้หนักมากนะ เราเคยแพ้โฟมล้างหน้า
          เมิงงงง ขึ้นผื่นทั้งหน้าเลย เริ่มแรกตอนแพ้อ่ะ เป็นผื่นก่อน
          แล้วก็เริ่มคัน ตอนแรกคิดว่าเป็นฝุ่นเลยล้างหน้าเพิ่ม
          เพราะตอนนั้นไม่รู้ว่าแพ้โฟมล้างหน้า
          ก็ล้างอยู่อย่างนั้นจนมันเป็นปืดแดงทั้งหน้าเลย แพ้หนักมากจริง
          ตอนนั้นคือต้องหาหมอ เหมือนมันแพ้สารอะไรไม่รู้ในโฟมล้างหน้า
          เป็นครั้งเดียวที่แพ้
        </p>
        <div class="my-4">
          <LazyImage src="/glorycollagen/review-yipso-1.jpg" />
        </div>
        <p class="mt-2">
          <b>ยิปซี</b> : เคยแพ้ออยล์ จากนั้นไม่ใช้ออยล์อีกเลย
          ออยล์คลีนเซอร์ยี่ห้อดังมาก แพงมากยี่ห้อนึง พอใช้แล้วก็อุดตันแบบขั้นสุด
        </p>
        <p class="mt-2">
          <b>เนะ</b> : เคยแพ้ดรีมเซรั่ม คือใช้แล้วหน้าบวม แต่ปกติเราอ่ะ
          เป็นคนหน้ามีผดๆ อยู่แล้ว ไม่ได้มีสิวเม็ดใหญ่ จะมีเล็กๆ น้อยๆ
        </p>
        <p class="mt-2">
          <b>ยิปซี</b> : เรามีข้อสงสัยอ่ะ เราไม่รู้จริงๆ ว่า ผดคืออะไร
          เพราะเราสับสน อันไหนคือผด อันไหนคือสิวอุดตันที่เล็ก
        </p>
        <p class="mt-2">
          <b>เนะ</b> : มันคือผสมกัน สำหรับกุอะนะ ผด ก็คือคนที่หน้าไม่เรียบ
          มองไกลๆเรียบ เหมือนหน้ากุจะมีเม็ดเล็กๆ
        </p>
        <h4 class="mt-4">มีวิธีที่ทำให้สิวหายเร็วยังไง?</h4>
        <ul class="list-disc mt-1 ml-10 text-baseColor">
          <li>ฉีดสิว</li>
          <li>
            กดสิว (เหตุผลที่ไม่กดสิว
            เพราะบางครั้งถ้าเค้ากดไม่ดีมันจะอักเสบขึ้นมาอีก
            ถ้ามันเป็นก็ปล่อยให้มันหายเอง)
          </li>
        </ul>
        <div class="my-4">
          <LazyImage src="/glorycollagen/review-yipso-5.jpg" />
        </div>
        <h4 class="mt-4">เคล็ดลับลดสิว (วิธีการเร่งด่วน)</h4>
        <ul class="list-disc mt-1 ml-10 text-baseColor">
          <li>หาหมอ</li>
          <li>
            {' '}
            ลองพักหน้า ไม่ลงรองพื้น ไม่ลงแป้งเลย อย่างน้อย 5-10 วัน
            ต้องไม่ให้หน้าโดนสารเคมีใดๆ ล้างน้ำเปล่า ไม่แต่งหน้าเลย
          </li>
          <li>
            ฉีดสิว (แต่มันไม่ดีนะ เพราะเป็นสเตียรอยด์ แล้วมันจะไปสะสม
            ควรเลือกหมอให้ดี เพราะการฉีดสิวอาจทำให้หน้าบุ๋มได้)
          </li>{' '}
        </ul>
        <p class="mt-2">
          ** กรณีหน้าบุ๋ม คือฉีดในปริมาณที่เยอะไป เหมือนจำนวนโดสเยอะไป
          สเตียรอยด์เยอะไป มันบุ๋มเหมือนดวงจันทร์ เป็นอยู่นาน
          แต่งหน้ายังไงก็เห็นว่าเป็นหลุม**
        </p>
        <ul class="list-disc mt-1 ml-10 text-baseColor">
          <li>
            กินยาลดสิวเลย แต่ไม่แนะนำเพราะมันทำให้ผิวแห้ง ปากแห้ง ตาแห้ง
            ต้องใช้วิจารณญาณของตัวเอง และปรึกษาคุณหมอ
          </li>
          <li>แผ่นดูดสิว มันจะดูดหนองออกมาจริงๆ</li>
        </ul>
        <div class="my-4">
          <LazyImage src="/glorycollagen/review-yipso-2.jpg" />
        </div>
        <p class="mt-2">
          <b>ปาย</b> : เรามาพูดเรื่องวิตามินกันมั่งดีกว่า
          กุพิสูจน์แล้วว่าอยู่ได้นานที่สุด
          เพราะเป็นการบำรุงจากภายในสู่ภายนอกจริงๆ
        </p>
        <h4 class="mt-2">พวกพี่กินอาหารเสริมอะไร ?</h4>
        <p>ไม่กินอาหารเสริม เรียกว่ากินวิตามินดีกว่า</p>
        <p class="mt-2">
          <b>เนะ</b> : กินวิตามินซีธรรมดา น้ำมันตับปลา ช่วยเรื่องสมอง ความจำ
          และกินคอลลาเจนแบบชง
        </p>
        <p class="mt-2">
          <b>ยิปซี</b> : วิตามินซีกินเป็นประจำเพราะช่วยแก้หวัด
          และกดสิวบ่อยจะมีรอยดำรอยแดงตกค้าง การกินวิตามินซีอ่ะ
          มันช่วยทำให้รอยหายเร็วขึ้น
        </p>
        <p class="mt-2">
          <b>ปาย</b> : กินวิตามินเยอะ นอกจากวิตามินช่วยในเรื่องผิวแล้ว
          วิตามินซีช่วยเรื่องหวัด เรื่องผิว กินวิตามินบีรวม ช่วยเรื่องปลายประสาท
          Q10 ช่วยเรื่องของการหมุนเวียนหลอดเลือด
        </p>
        <div class="my-4">
          <LazyImage src="/glorycollagen/review-yipso-3.jpg" />
        </div>
        <p class="mt-2">
          <b>ปาย</b> : <i>เราได้แนะนำให้เพื่อนๆ กินเลย คือ กลอรี่คอลลาเจน</i>{' '}
          ซึ่งเหตุผลที่เรากินคอลลาเจน
          เพราะร่างกายของเราเริ่มผลิตคอลลาเจนลดลงตั้งแต่อายุ 25
          คอลลาเจนไดเปปไทด์ร่างกายเราดูดซึมได้รวดเร็ว และเป็นแคปซูลกินง่าย
          เพราะตอนที่กินเป็นชงอ่า เหม็นคาว แต่กินกลอรี่แค่ 2 เม็ดก็เพียงพอแล้ว
          เพราะเป็นไดเปปไทด์ดูดซึมได้ดีกว่า และบางครั้งถ้าเราถ่ายไม่ออก
          แล้วเรากินไฟเบอร์ ถ้าต้องกินคอลลาเจนแบบชงอีก มันจะ ชง ชง ชง
          กินไม่ไหววว และสำหรับใครที่มีปัญหาเรื่องผิว อันนี้แนะนำจริงๆ
          กินง่ายมาก ก่อนอาหาร แล้วช่วยเรื่องความโกลว์
        </p>
        <p class="mt-2">
          <b>เนะ</b> : เราเป็น 3 สาว 3 สไตล์ ที่ไม่ได้ชอบผิวขาวมาก
          แต่ชอบความโกลว์ ความออร่า
        </p>
        <p class="mt-2">
          <b>ปาย</b> : <i>และอีกตัวเราก็แนะนำให้ยิปกิน คือโทเมโท่วิตซี</i>{' '}
          เพราะอยู่ดีๆ หน้ายิปก็ผื่นขึ้น ทำทรีทเมนท์ยังไงหน้าก็ไม่หาย
          เป็นมะเขือเทศอัดเม็ด
        </p>
        <p class="mt-2">
          <b>ยิปซี</b> : กุกินวิตามินซีทุกวันอยู่แล้ว แค่เปลี่ยนวิตามินซีจากส้ม
          มาเป็นจากมะเขือเทศ ซึ่งมีปมวัยเด็ก
          ยิปโซเวลาออกกำลังกายนิดนึงแก้มจะเป็นสีแดง แล้วก็มีเลือดฝาด
          แล้วกุเห็นแล้วก็รู้สึกว่าสวยมาก เห็นแล้วอยากมีบ้าง
          แต่มะเขือเทศสดคือกินไม่ได้เลย แต่โทเมโท่วิตซีคือดีมาก
          เป็นมะเขือเทศอัดเม็ดแล้วไม่มีกลิ่นเลย สานฝันวันเด็ก
        </p>
        <p class="mt-2">
          <b>ปาย</b> : ซึ่งคอลลาเจนกินหลังตื่นนอนตอนเช้า 2 เม็ด กินก่อนนอน 2
          เม็ด วิตซีกินหลังอาหารเช้า 2 เม็ด
        </p>
        <div class="my-4">
          <LazyImage src="/glorycollagen/review-yipso-4.jpg" />
        </div>
        <p class="mt-2">
          <b>เนะ</b> : กินคู่กันจะดี โทเมโท่วิตซีสร้างภูมิคุ้มกัน ป้องกันแดด
          ผิวขาวกระจ่างใส ลดรอยสิว เวลาเค้าบอกว่าผิวขาวกระจ่างใสอ่ะ
          เราก็อย่าไปคาดหวังว่า มันจะข๊าว ขาว อะไรที่ขาวจนน่ากลัว
          แต่เชื่อในการกินวิตามันซี ช่วยในการปกป้องผิวจากแสงแดดนะ
          พวกเราไม่ได้ยึดติดความขาว แต่อยากผิวกระจ่างใส
          วิตามินซีอันอื่่นซื้อได้ตามร้านขายยาทั่วไป ของปายเป็นกลอรี่นะ
          เป็นคอลลาเจนที่นำเข้าจากญี่ปุ่น สาวๆ ก็ลองดูนะ
        </p>
      </div>
    ),
    relateProducts: ['GLRSET1', 'GLRCL1', 'GLRVC1'],
  };
};
