import { Link } from 'preact-router/match';

export const agent = () => {
  return {
    title: 'เปิดรับตัวแทนจำหน่ายกลอรี่',
    images: [
      '/glorycollagen/banner-agent-1000x1000.png',
      '/glorycollagen/ploy-profile-a12.jpg',
      '/glorycollagen/ploy-agent-1.jpg',
      '/glorycollagen/ploy-agent-2.jpg',
      '/glorycollagen/ploy-agent-3.jpg',
      '/glorycollagen/ploy-agent-4.jpg',
      '/glorycollagen/ploy-agent-7.jpg',
      '/glorycollagen/ploy-agent-6.jpg',
    ],
    content: (
      <div>
        <div class="mt-2 text-center text-md italic">
          สินค้าคุณภาพใช้แล้วเห็นผล 97% ลูกค้าซื้อซ้ำ ขายง่าย
        </div>
        <div class="mt-4 text-center">
          <h3>สิทธิพิเศษสำหรับตัวแทนใหม่ !!</h3>
        </div>
        <div class="mt-4 text-baseColor">
          สำหรับผู้ที่สนใจอยากเป็นตัวแทนกลอรี่ ท่านสามารถสมัครเป็นตัวแทนได้โดย
          “ไม่ต้องเสียค่าสมัคร” พร้อมรับบัตรตัวแทน และเว็ปไซต์เป็นของตัวเอง
          “ฟรี” และสิทธิพิเศษอื่นๆอีกมากมาย
        </div>
        <div class="mt-4 text-center">
          <h3>สิทธิพิเศษเฉพาะตัวแทนกลอรี่</h3>
        </div>
        <ul class="mt-4 ml-6 list-disc text-baseColor">
          <li>
            มีเรทราคาสินค้าที่สามารถสร้างกำไรสูงสุด
            ทั้งแบบการค้าปลีกและการค้าส่ง
          </li>
          <li>
            มีระบบสร้างทีม สามารถรับตัวใต้สายงานได้
            เพื่อการเติบโตอย่างมีประสิทธิภาพ
          </li>
          <li>
            มีคอร์สสอนทำการตลาดออนไลน์ฟรี เทรนโดย CEO และโค้ชชั้นนำระดับประเทศ
          </li>
          <li>มีผู้เชี่ยวชาญคอยให้คำปรึกษา จากเริ่มต้นไปจนถึงขายได้</li>
          <li>ไม่ต้องรักษายอดขาย</li>
        </ul>
        <div class="mt-4 text-center">
          <h3>Glory Reward</h3>
        </div>
        <div class="mt-4 text-baseColor">
          นอกจากกำไร และคะแนนสะสมสำหรับตัวแทน เพื่อแลกรางวัลต่างๆมากมาย ยังมี
          Bonus พิเศษประจำเดือนอีกด้วย
        </div>
        <div class="mt-4 text-center">
          <h3>Glory agent support</h3>
        </div>
        <div class="mt-4 text-baseColor">
          เพื่อให้ตัวแทนทุกคนสามารถจำหน่ายผลิตภัณฑ์ของเราผ่านช่องทางออนไลน์ได้อย่างมืออาชีพ
          ถูกวิธี และมีความยั่งยืน
          เราจึงให้ความสำคัญกับการสนับสนุนตัวแทนจำหน่ายอย่างเต็มที่ที่สุดในทุกๆ
          ด้าน ได้แก่
          <ul class="mt-4 ml-6 list-disc text-baseColor">
            <li>Glory Academy คลาสเรียนออนไลน์ ฟรี!</li>
            <li>
              Glory Family Group กรุ๊ปที่คอยซัพพอร์ทตัวแทนทุกคนอย่างใกล้ชิด
            </li>
            <li>
              Video คอร์สเรียนออนไลน์
              สามารถเรียนรู้การขายเบื้องต้นไปจนถึงขั้นสูง
            </li>
            <li>Media storage สื่อ คลังภาพ กราฟฟิก และ วิดีโอ content ต่างๆ</li>
          </ul>
        </div>
        <div class="mt-4 text-baseColor">
          ถ้าคุณอยากมีรายได้เสริมเดือนละ 5,000 - 20,000 บาท ก่อนเรียนจบ ต้องอ่าน
          !!!
          <a
            class="font-bold text-primary hover:text-primaryLight"
            href="https://glorydealer.com/agent/"
          >
            100,000 บาทแรก ของพลอยเกิดขึ้นที่นี่
          </a>
        </div>
        <p class="mt-4">
          พลอยทำได้ และพลอยเชื่อว่า <u>ทุกคนก็ทำได้</u>
        </p>
        <p class="mt-2">
          สนใจสร้างโอกาสทางธุรกิจกับกลอรี่
          แอดไลน์เพื่อสมัครตัวแทนจำหน่ายได้เลยค่ะ
        </p>
      </div>
    ),
  };
};
