import './scss/style.scss';
import App from './app';
import { initialTagManager, gtmDataLayer } from './lib/tag-manager';
import { initialUser } from './lib/user';
import { logWebsite } from './lib/log';

if (typeof window !== 'undefined') {
  initialTagManager();
  initialUser();
  logWebsite();
}

export default App;
