const SERVER_CDN_URL = process.env.PREACT_APP_SERVER_CDN_URL;

export const imgLqip = (imagePath) => `${imagePath}?w=400&q=80&blur=400&px=32`;
export const imgStandard = (imagePath) => `${imagePath}?w=600&q=80`;

export const imgSrcSet = (imagePath) => {
  const urls = [
    `${imagePath}?w=200&q=80 200w`,
    `${imagePath}?w=300&q=80 300w`,
    `${imagePath}?w=600&q=80 600w`,
  ];
  return urls.join(',');
};
