import { memo, PureComponent } from 'preact/compat';
import lazySizes from 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import { imgLqip, imgStandard, imgSrcSet } from '../lib/img-cdn';
import ReactPlayer from 'react-player';

lazySizes.cfg.preloadAfterLoad = true;
lazySizes.cfg.expand = 1000;
lazySizes.cfg.blurupMode = 'auto';

export const LazyImageComponent = (props) => (
  <img
    class={
      props.class !== undefined ? props.class : 'lazyload lazypreload w-full'
    }
    alt={props.alt}
    data-sizes="auto"
    data-srcset={imgSrcSet(props.src)}
    srcset="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
    src={imgStandard(props.src)}
  />
);

export const LazyImage = memo(LazyImageComponent);

export const LazyVideoComponent = (props) => (
  <a href={props.video} rel="noopener noreferrer nofollow" target="_blank">
    <img
      class={'lazyload lazypreload rounded-lg w-full shadow ' + props.class}
      alt={props.alt}
      data-sizes="auto"
      data-srcset={imgSrcSet(props.image)}
      srcset="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
      src={imgStandard(props.image)}
    />
  </a>
);

export const LazyVideo = memo(LazyVideoComponent);

export const Image = (props) => <img src={imgStandard(props.src)} />;

export const VideoCompoent = (props) => (
  <div class="react-player-wrapper mt-6">
    <ReactPlayer
      url={props.url}
      className="react-player"
      width="100%"
      height="100%"
      muted={true}
      controls={true}
      playing
      loop
    />
  </div>
);

export const Video = memo(VideoCompoent);
